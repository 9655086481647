:root {
  --principal: #E1EAE9;
  --secundary: #000000; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  scroll-behavior: smooth; }

body {
  width: 100%;
  font-family: 'adelle-sans', sans-serif;
  font-size: calc(0.12945vw + 15.51456px);
  font-weight: 400;
  line-height: 1.6;
  background-color: var(--principal);
  color: var(--secundary);
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  body h1, body h2, body h3, body h4, body h5, body h6 {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0; }
  body a {
    text-decoration: none;
    color: inherit; }
  body b {
    font-weight: 700; }
  body ul, body ol {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  body address {
    font-style: normal; }
  body button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    outline: 0;
    padding: 0; }
  body ul {
    margin: 0;
    padding: 0;
    list-style-type: none; }
  body figure {
    display: block;
    margin: 0;
    padding: 0; }
    body figure img {
      display: block; }
  body iframe {
    border: none;
    outline: none; }
  body input {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    outline: none; }

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px calc(3.23625vw + 7.86408px);
  min-height: calc(74px + 36px);
  border-bottom: 1px solid var(--secundary);
  position: relative; }
  @media screen and (max-width: 1024px) {
    .header {
      min-height: calc(40px + 36px); } }
  .header-wrap {
    z-index: 4;
    background-color: var(--principal);
    position: sticky;
    top: 0; }
  .header-btn {
    width: 18px;
    height: 17px;
    position: relative;
    transition: opacity .4s ease; }
    .header-btn:before, .header-btn:after, .header-btn span {
      content: '';
      position: absolute;
      left: 0;
      height: 3px;
      width: 100%;
      border-radius: 10px;
      background-color: var(--secundary); }
    .header-btn:before {
      top: 0; }
    .header-btn:after {
      bottom: 0; }
    .header-btn span {
      top: 50%;
      transform: translateY(-50%); }
    .header-btn:hover {
      opacity: .6; }
  .header-logo {
    width: calc(2.20065vw + 31.74757px);
    height: calc(2.20065vw + 31.74757px);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    border-radius: 100%;
    transition: opacity .4s ease; }
    .header-logo img, .header-logo svg {
      display: block;
      width: 52%; }
    .header-logo:hover {
      opacity: .6; }
  .header-menu {
    display: flex;
    align-items: center;
    gap: 30px; }
    .header-menu a {
      transition: font-weight .4s ease; }
      .header-menu a.support-btn {
        font-weight: 700;
        padding: 10px 20px;
        background-color: var(--secundary);
        border-radius: 50px;
        color: var(--principal); }
      .header-menu a:hover {
        font-weight: 700; }
    .header-menu-search {
      position: relative;
      display: flex;
      align-items: center; }
      .header-menu-search form {
        position: absolute;
        display: block;
        width: 0;
        background-color: #F0F5F4;
        top: 50%;
        right: calc( 100% + 20px);
        translate: 0% -50%;
        transition: width .4s ease;
        padding: 10px 0;
        overflow: hidden; }
        .header-menu-search form.open {
          border: 1px solid #000;
          width: calc(12.94498vw + 201.45631px);
          padding: 10px; }
      .header-menu-search input {
        width: 100%;
        background-color: transparent; }
        .header-menu-search input::placeholder {
          font-style: italic; }
      .header-menu-search .send-search {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        top: 50%;
        translate: 0% -50%;
        background-image: url("../assets/arrow-right-black.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        text-indent: -99999px; }
      .header-menu-search .open-search {
        width: 24px;
        height: 25px;
        overflow: hidden; }
    @media screen and (max-width: 1024px) {
      .header-menu a {
        display: none; } }
  .header-submenu {
    padding: 12px calc(3.23625vw + 7.86408px);
    border-bottom: 1px solid var(--secundary); }
    @media screen and (max-width: 1024px) {
      .header-submenu {
        padding: 15px calc(3.23625vw + 7.86408px); } }
    .header-submenu-category {
      display: none;
      justify-content: center; }
      .header-submenu-category a {
        font-size: 18px;
        display: block;
        margin: 0 25px;
        transition: font-weight .4s ease; }
        .header-submenu-category a:hover {
          font-weight: 700; }
    .header-submenu-share {
      position: relative;
      display: flex;
      justify-content: space-between; }
      .header-submenu-share a {
        gap: 10px;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        transition: opacity .4s ease; }
        .header-submenu-share a:hover {
          opacity: .6; }
        @media screen and (max-width: 700px) {
          .header-submenu-share a span {
            display: none; } }
      .header-submenu-share .collection-name {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: calc(0.19417vw + 16.27184px);
        font-weight: 700;
        line-height: 1.2; }
      .header-submenu-share .page-name {
        text-transform: uppercase; }
      .header-submenu-share ul li p {
        font-size: 14px; }
        @media screen and (max-width: 1024px) {
          .header-submenu-share ul li p {
            display: none; } }
      @media screen and (max-width: 700px) {
        .header-submenu-share ul {
          display: none; } }

.footer {
  --principal: #F0F5F4;
  padding: 80px calc(3.23625vw + 7.86408px) 68px;
  background-color: var(--secundary);
  display: flex;
  gap: 94px;
  justify-content: space-between; }
  @media screen and (max-width: 1024px) {
    .footer {
      flex-direction: column; } }
  .footer-social p {
    color: var(--principal);
    font-weight: 600; }
  .footer-social-about {
    max-width: 230px;
    margin: 0 0 20px; }
    .footer-social-about img {
      display: block;
      max-width: 100%;
      width: auto;
      margin: 0 0 32px; }
  .footer-social-logo {
    margin: 0 0 48px; }
    .footer-social-logo img {
      display: block;
      max-width: 100%;
      width: auto; }
  .footer-social-links {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 0 18px; }
    @media screen and (max-width: 1024px) {
      .footer-social-links {
        align-items: flex-start;
        flex-direction: column; } }
    .footer-social-links p {
      font-weight: 700;
      white-space: nowrap;
      margin: 0 10px 0 0; }
    .footer-social-links ul {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: space-between; }
      .footer-social-links ul li a {
        font-size: 11px;
        font-weight: 700;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: var(--principal);
        transition: opacity .4s ease; }
        .footer-social-links ul li a:hover {
          opacity: .6; }
  .footer-social-support {
    margin: 0 0 40px; }
    .footer-social-support p {
      font-weight: 700; }
    .footer-social-support a {
      font-style: italic;
      text-decoration: underline;
      color: var(--principal);
      transition: opacity .4s ease; }
      .footer-social-support a:hover {
        opacity: .6; }
  .footer-menu {
    color: var(--principal); }
    .footer-menu-header, .footer-menu-links {
      display: grid;
      gap: 28px;
      grid-template-columns: repeat(4, 1fr); }
      @media screen and (max-width: 700px) {
        .footer-menu-header, .footer-menu-links {
          grid-template-columns: repeat(1, 1fr); } }
    .footer-menu-links {
      position: relative;
      padding-top: 15px; }
      .footer-menu-links h3 {
        margin-bottom: 30px;
        font-weight: 800;
        text-transform: uppercase; }
        .footer-menu-links h3:before {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          top: calc(34px + 20px);
          left: 0;
          background-color: var(--principal);
          display: none; }
        .footer-menu-links h3:first-of-type:before {
          display: block; }
        @media screen and (max-width: 700px) {
          .footer-menu-links h3 {
            position: relative; }
            .footer-menu-links h3:before {
              display: block;
              top: auto;
              bottom: -15px; } }
      .footer-menu-links a {
        display: block;
        font-weight: 500;
        margin: 0 0 10px;
        transition: opacity .4s ease; }
        .footer-menu-links a:hover {
          opacity: .6; }

.newsletter {
  margin: 0 0 96px;
  position: relative;
  width: 80%; }
  .newsletter .container {
    width: calc(100% - 125px);
    padding: 28px 30px;
    border: 1px solid var(--secundary);
    background-color: #F0F5F4;
    position: relative; }
    .newsletter .container h2 {
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      font-size: 36px;
      font-weight: 700;
      margin: 0 0 12px; }
    .newsletter .container p {
      max-width: calc(100% - 125px); }
  .newsletter .btn {
    position: absolute;
    right: 0px;
    top: 50%;
    color: #F0F5F4;
    transform: translateY(-50%);
    border: 1px solid var(--secundary);
    background-color: var(--secundary); }
  @media screen and (max-width: 1024px) {
    .newsletter {
      width: 100%; } }
  @media screen and (max-width: 700px) {
    .newsletter {
      width: 100%; }
      .newsletter .container {
        width: 100%;
        padding: 28px 30px calc(28px + 35px); }
        .newsletter .container p {
          max-width: 100%; }
      .newsletter .btn {
        right: auto;
        top: auto;
        left: 50%;
        bottom: -35px;
        transform: translateX(-50%);
        white-space: nowrap; } }

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 12px 20px 32px;
  border: 1px solid var(--principal);
  color: var(--principal);
  width: fit-content;
  transition: opacity .4s ease; }
  .btn img {
    margin-left: 24px; }
  .btn:hover {
    opacity: .6; }
  .btn-black {
    background-color: #000; }
  .btn-padding {
    padding: 20px; }

.dots {
  display: block;
  width: 28px;
  height: 48px;
  position: relative;
  cursor: pointer; }
  .dots::after, .dots::before, .dots .dot {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 100%;
    left: 50%;
    translate: -50% 0; }
  .dots .dot {
    top: 50%;
    left: 50%;
    translate: -50% -50%; }
  .dots::after {
    top: 0; }
  .dots::before {
    bottom: 0; }

.share-content {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  z-index: 1; }
  .share-content .social {
    position: absolute;
    z-index: 2;
    top: 0;
    left: calc(100% + 20px);
    flex-direction: column; }
    .share-content .social li {
      opacity: 0;
      visibility: hidden;
      transition: opacity .4s ease; }
      .share-content .social li a {
        width: 52px;
        height: 52px;
        background-color: #B6CCC8; }
        .share-content .social li a .a2a_svg svg {
          height: auto; }
          .share-content .social li a .a2a_svg svg path {
            fill: #000; }
    .share-content .social li:nth-child(1) {
      transition-delay: calc(100ms * 1); }
    .share-content .social li:nth-child(2) {
      transition-delay: calc(100ms * 2); }
    .share-content .social li:nth-child(3) {
      transition-delay: calc(100ms * 3); }
    .share-content .social li:nth-child(4) {
      transition-delay: calc(100ms * 4); }
  .share-content.active .social li {
    opacity: 1;
    visibility: visible; }

.social {
  display: flex;
  align-items: center;
  gap: 8px; }
  .social li a {
    overflow: hidden;
    font-size: 11px;
    font-weight: 700;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: var(--principal);
    background-color: var(--secundary);
    transition: opacity .4s ease; }
    .social li a:hover {
      opacity: .6; }
    .social li a .a2a_svg {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent !important; }
      .social li a .a2a_svg svg {
        height: 20px; }
    .social li a img {
      display: block;
      margin: 0 !important;
      width: auto !important;
      max-width: 100%;
      height: auto !important;
      border-radius: 0 !important; }

.author-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .author-container img {
    border-radius: 100%; }
  .author-container p {
    line-height: 1.4;
    margin: 0 0 0 12px; }
  .author-container a {
    display: inline-block; }
  .author-container > div {
    display: flex;
    align-items: center;
    margin: 12px 12px 0 0; }

.date {
  text-transform: uppercase;
  margin: 0 0 20px;
  display: block; }

.icon {
  display: block;
  position: relative;
  width: 25px;
  height: 25px; }
  .icon::before, .icon::after {
    content: '';
    position: absolute;
    background-color: var(--secundary);
    width: 100%;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .icon::after {
    height: 100%;
    width: 8px; }

.scrollTop {
  position: absolute;
  right: calc(4.91909vw + 5.5534px);
  bottom: calc(90px + 36px);
  background-color: #9BC1BA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(2.589vw + 32.29126px);
  height: calc(2.589vw + 32.29126px);
  cursor: pointer;
  transition: opacity .4s ease; }
  .scrollTop:hover {
    opacity: .6; }
  .scrollTop img {
    width: 50%; }
  @media screen and (max-width: 1024px) {
    .scrollTop {
      position: unset; } }

.support-container {
  padding: calc(2.3301vw + 7.26214px) calc(2.97735vw + 4.83495px) 52px;
  position: relative;
  background-color: #F0F5F4;
  margin: 0 0 70px; }
  .support-container h2 {
    line-height: 1.4;
    font-size: calc(0.38835vw + 18.54369px);
    font-weight: 800;
    margin: 0 0 20px; }
  .support-container p {
    margin: 0 0 10px; }
  .support-container .btn {
    display: flex;
    gap: 60px;
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    background-color: var(--secundary);
    color: #B6CCC8;
    border: none;
    padding: 20px 26px 20px 32px; }

.tag {
  display: block;
  color: #fff;
  font-size: calc(0.2589vw + 9.02913px);
  font-weight: 700;
  padding: 8px 28px;
  background-color: var(--secundary);
  width: fit-content;
  text-transform: uppercase;
  transition: opacity .4s ease;
  cursor: pointer; }
  .tag:hover {
    opacity: .8; }

.home {
  --principal: #B6CCC8; }
  .home .header-submenu-category {
    display: flex; }
    @media screen and (max-width: 1024px) {
      .home .header-submenu-category {
        display: none; } }
  .home .header-submenu-share {
    display: none; }

.page-home {
  margin: calc(4.14239vw + 4.46602px) 0 0; }
  .page-home .title {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
    .page-home .title a {
      display: inline-block;
      transition: opacity .4s ease;
      padding: 0 0 2px;
      border-bottom: 2px solid #000; }
      .page-home .title a:hover {
        opacity: .4; }
  .page-home-hero {
    display: flex;
    justify-content: space-between;
    gap: 28px;
    padding: 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px; }
    .page-home-hero-highlight {
      padding: calc(1.0356vw + 12.1165px);
      border: 1px solid var(--secundary);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      transition: background-color .4s ease; }
      .page-home-hero-highlight:hover {
        background-color: #fff; }
      @media screen and (max-width: 1024px) {
        .page-home-hero-highlight {
          width: 100%; } }
      .page-home-hero-highlight figure {
        position: relative; }
        .page-home-hero-highlight figure img {
          display: block;
          width: 100%;
          min-height: 200px;
          object-fit: cover;
          max-height: 50vh; }
          @media screen and (max-width: 1024px) {
            .page-home-hero-highlight figure img {
              height: 30vh; } }
        .page-home-hero-highlight figure figcaption {
          position: absolute;
          z-index: 2;
          top: 20px;
          right: 20px;
          cursor: pointer;
          transition: opacity .4s ease; }
          .page-home-hero-highlight figure figcaption:hover {
            opacity: .6; }
      .page-home-hero-highlight h2 {
        font-size: calc(1.0356vw + 28.1165px);
        font-weight: 700;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif;
        margin: 38px 0 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between; }
        .page-home-hero-highlight h2 span {
          display: block;
          max-width: 930px;
          width: 90%; }
        .page-home-hero-highlight h2 a {
          display: block; }
          .page-home-hero-highlight h2 a img {
            width: 28px; }
      .page-home-hero-highlight p {
        max-width: 930px;
        width: 90%; }
        .page-home-hero-highlight p.legend {
          text-transform: uppercase;
          margin: calc(1.5534vw + 10.17476px) 0 0;
          font-size: 12px; }
        @media screen and (max-width: 700px) {
          .page-home-hero-highlight p {
            display: none; }
            .page-home-hero-highlight p.legend {
              display: block; } }
    .page-home-hero-statistic {
      width: 30%;
      min-width: 30%;
      display: flex;
      gap: 32px;
      flex-direction: column;
      justify-content: space-between; }
      @media screen and (max-width: 1024px) {
        .page-home-hero-statistic {
          width: 100%; } }
      .page-home-hero-statistic .happening {
        border: 1px solid var(--secundary);
        height: 100%; }
        .page-home-hero-statistic .happening-header {
          padding: 14px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .page-home-hero-statistic .happening-header h2 {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase; }
          .page-home-hero-statistic .happening-header time {
            font-size: 16px;
            text-transform: uppercase; }
          .page-home-hero-statistic .happening-header .link-date {
            display: flex;
            align-items: center;
            gap: 20px; }
            .page-home-hero-statistic .happening-header .link-date a {
              display: flex;
              align-items: center; }
        .page-home-hero-statistic .happening .code {
          overflow: hidden;
          padding: 12px 20px;
          border-top: 1px solid var(--secundary);
          display: flex;
          flex-direction: column;
          justify-content: space-between; }
      .page-home-hero-statistic .link {
        padding: 20px;
        border: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease; }
        .page-home-hero-statistic .link:hover {
          background-color: #fff; }
        .page-home-hero-statistic .link h2 {
          font-size: 36px;
          font-weight: 700;
          line-height: 1;
          margin: 0 0 10px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between; }
        .page-home-hero-statistic .link p {
          font-weight: 600; }
    @media screen and (max-width: 1024px) {
      .page-home-hero {
        flex-direction: column; } }
  .page-home-carousel {
    padding: 0 0 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px;
    position: relative; }
    @media screen and (max-width: 1024px) {
      .page-home-carousel {
        padding: 0 calc(3.23625vw + 7.86408px); } }
    .page-home-carousel .slider li {
      padding: 0 28px 0 0; }
      @media screen and (max-width: 1000px) {
        .page-home-carousel .slider li {
          padding: 0 0 0 0; } }
      .page-home-carousel .slider li .content {
        position: relative;
        display: flex !important;
        flex-direction: column;
        gap: 20px;
        padding: calc(1.0356vw + 12.1165px);
        background-color: #F0F5F4;
        flex: 0 0 auto;
        width: calc(268px + 40px + 40px) !important; }
        @media screen and (max-width: 1000px) {
          .page-home-carousel .slider li .content {
            width: 100% !important; } }
      .page-home-carousel .slider li figure {
        display: block;
        width: 100%; }
        .page-home-carousel .slider li figure img {
          display: block;
          width: 100%;
          height: 276px;
          object-fit: cover; }
      .page-home-carousel .slider li h3 {
        font-weight: 700; }
      .page-home-carousel .slider li div {
        width: 100%; }
        .page-home-carousel .slider li div .tag {
          display: none;
          margin: 0 0  24px;
          position: relative;
          z-index: 1; }
        .page-home-carousel .slider li div p {
          margin: 14px 0 0;
          display: none; }
    .page-home-carousel .slider .slick-current li .content {
      width: 60vw !important;
      gap: calc(1.94175vw + 12.71845px);
      flex-direction: row; }
    .page-home-carousel .slider .slick-current li figure {
      width: 55%;
      height: 60vh;
      overflow: hidden; }
      .page-home-carousel .slider .slick-current li figure img {
        height: 100%; }
    .page-home-carousel .slider .slick-current li div {
      width: 45%; }
      .page-home-carousel .slider .slick-current li div .tag, .page-home-carousel .slider .slick-current li div p {
        display: block; }
      .page-home-carousel .slider .slick-current li div h3 {
        font-size: calc(1.0356vw + 28.1165px);
        font-weight: 700;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif; }
      .page-home-carousel .slider .slick-current li div p {
        margin: 20px 0 0; }
    @media screen and (max-width: 1024px) {
      .page-home-carousel .slider .slick-current li .content {
        width: calc(268px + 40px + 40px) !important;
        flex-direction: column; }
      .page-home-carousel .slider .slick-current li figure {
        width: 100%;
        height: auto;
        overflow: hidden; }
        .page-home-carousel .slider .slick-current li figure img {
          height: 276px; }
      .page-home-carousel .slider .slick-current li div {
        width: 100%; }
        .page-home-carousel .slider .slick-current li div .tag, .page-home-carousel .slider .slick-current li div p {
          display: none; } }
    @media screen and (max-width: 1000px) {
      .page-home-carousel .slider .slick-current li .content {
        width: 100% !important; } }
    .page-home-carousel .slider-nav {
      width: 60vw;
      display: flex;
      gap: 28px;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      bottom: 40px;
      left: calc(64px + 28px + 16px + 70px); }
      .page-home-carousel .slider-nav-arrow {
        background-color: #000;
        width: 64px;
        height: 64px;
        transition: opacity .4s ease; }
        .page-home-carousel .slider-nav-arrow img {
          width: 50%; }
        .page-home-carousel .slider-nav-arrow:hover {
          opacity: .6; }
      @media screen and (max-width: 1024px) {
        .page-home-carousel .slider-nav {
          width: 100%;
          position: relative;
          left: 0;
          bottom: 0;
          margin: 0 0 24px;
          justify-content: flex-start; } }
    .page-home-carousel .carousel-content {
      display: flex;
      flex-direction: column; }
      @media screen and (max-width: 1024px) {
        .page-home-carousel .carousel-content {
          flex-direction: column-reverse; } }
  .page-home-media {
    padding: 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px; }
    .page-home-media-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 28px; }
      .page-home-media-list li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: calc(0.5178vw + 14.05825px);
        border: 1px solid var(--secundary);
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease; }
        .page-home-media-list li:hover {
          background-color: #fff; }
        .page-home-media-list li figure {
          position: relative;
          height: 300px; }
          .page-home-media-list li figure img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .page-home-media-list li .tag {
          position: absolute;
          top: 10px;
          right: 10px;
          color: var(--secundary);
          border: 1px solid var(--secundary);
          background-color: var(--principal);
          z-index: 1; }
        .page-home-media-list li div {
          height: 100%;
          margin: 15px 0 0;
          display: flex;
          gap: 20px;
          justify-content: space-between; }
          .page-home-media-list li div h3 {
            font-size: calc(0.64725vw + 21.57282px);
            font-weight: 700;
            line-height: 1.1;
            font-family: 'freight-display-pro', serif; }
          .page-home-media-list li div a {
            align-self: flex-end;
            display: block; }
      @media screen and (max-width: 1024px) {
        .page-home-media-list {
          grid-template-columns: repeat(1, 1fr); }
          .page-home-media-list li figure {
            display: none; }
          .page-home-media-list li:first-of-type figure {
            display: block; } }
  .page-home-news {
    padding: 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px; }
    .page-home-news .columns {
      display: grid;
      gap: 34px;
      grid-template-columns: repeat(3, 1fr); }
      @media screen and (max-width: 1024px) {
        .page-home-news .columns {
          grid-template-columns: repeat(1, 1fr); } }
    .page-home-news-interviews {
      display: grid;
      gap: 20px; }
      .page-home-news-interviews article {
        padding: 14px;
        border: 1px solid var(--secundary);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease; }
        .page-home-news-interviews article:hover {
          background-color: #fff; }
        .page-home-news-interviews article figure {
          width: 100%; }
          .page-home-news-interviews article figure img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }
        .page-home-news-interviews article .content .aspas {
          width: calc(0.38835vw + 18.54369px); }
        .page-home-news-interviews article .content h3 {
          font-size: calc(0.38835vw + 18.54369px);
          font-family: 'freight-display-pro', serif;
          line-height: 1.1;
          font-weight: 700;
          margin: 0 0 16px; }
        .page-home-news-interviews article .content p {
          font-size: calc(0.12945vw + 13.51456px); }
      @media screen and (max-width: 700px) {
        .page-home-news-interviews {
          grid-template-columns: repeat(1, 1fr); }
          .page-home-news-interviews article img {
            display: none; }
          .page-home-news-interviews article:first-of-type img {
            display: block; } }
    .page-home-news-highlight article {
      min-width: 440px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px;
      background-color: #E1EAE9;
      border: 1px solid var(--secundary);
      position: relative;
      cursor: pointer;
      background-color: transparent;
      transition: background-color .4s ease; }
      .page-home-news-highlight article:hover {
        background-color: #fff; }
      .page-home-news-highlight article img {
        width: 40px;
        margin: 0 0 16px; }
      .page-home-news-highlight article h3 {
        margin: 0 0 46px;
        font-size: calc(1.0356vw + 28.1165px);
        font-weight: 700;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif; }
      @media screen and (max-width: 1024px) {
        .page-home-news-highlight article {
          padding: 40px 40px 40px 30px;
          min-width: 100%; } }
    .page-home-news-articles {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid var(--secundary);
      background-color: #E1EAE9; }
      .page-home-news-articles h2 {
        padding: 14px 24px;
        background-color: #000;
        color: #F0F5F4;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700; }
      .page-home-news-articles article {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 12px 24px;
        height: 100%;
        border-top: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease; }
        .page-home-news-articles article:hover {
          background-color: #fff; }
        .page-home-news-articles article h3 {
          font-size: 36px;
          font-weight: 900;
          line-height: 1; }
        .page-home-news-articles article p {
          line-height: 1.4; }
  .page-home-moreread {
    padding: 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px; }
    .page-home-moreread-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr); }
      .page-home-moreread-grid ol {
        display: grid;
        gap: 28px;
        grid-template-columns: repeat(3, 1fr);
        grid-column: span 3; }
        .page-home-moreread-grid ol li {
          border: 1px solid var(--secundary);
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          padding: 14px calc(0.2589vw + 15.02913px);
          position: relative;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .4s ease; }
          .page-home-moreread-grid ol li:hover {
            background-color: #fff; }
          .page-home-moreread-grid ol li .infos {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 100%; }
            @media screen and (max-width: 1024px) {
              .page-home-moreread-grid ol li .infos {
                align-items: flex-start;
                flex-direction: column; } }
            @media screen and (max-width: 700px) {
              .page-home-moreread-grid ol li .infos {
                flex-direction: row; } }
            .page-home-moreread-grid ol li .infos .avatar {
              display: block;
              border-radius: 100%;
              width: 86px;
              min-width: 86px;
              height: 86px;
              overflow: hidden; }
              .page-home-moreread-grid ol li .infos .avatar img {
                width: 100%;
                height: 100%;
                object-fit: cover; }
            .page-home-moreread-grid ol li .infos p {
              line-height: 1.4; }
              .page-home-moreread-grid ol li .infos p b {
                font-size: calc(0.12945vw + 11.51456px); }
          .page-home-moreread-grid ol li .links {
            display: flex;
            gap: 24px;
            flex-direction: column;
            align-items: flex-end; }
            .page-home-moreread-grid ol li .links .tag {
              font-size: 10px;
              padding: 8px 10px;
              z-index: 1; }
          @media screen and (max-width: 1024px) {
            .page-home-moreread-grid ol li {
              flex-direction: column; }
              .page-home-moreread-grid ol li .links {
                width: 100%;
                flex-direction: row;
                align-items: flex-start; }
                .page-home-moreread-grid ol li .links .tag {
                  margin: 0; } }
          @media screen and (max-width: 700px) {
            .page-home-moreread-grid ol li {
              flex-direction: row; }
              .page-home-moreread-grid ol li .links {
                width: fit-content;
                flex-direction: column;
                align-items: flex-end; } }
        @media screen and (max-width: 700px) {
          .page-home-moreread-grid ol {
            grid-template-columns: repeat(1, 1fr); } }
  .page-home-webstories {
    padding: 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px; }
    .page-home-webstories-list {
      position: relative;
      padding: 0 calc(3.23625vw + 37.86408px) 0 0; }
      .page-home-webstories-list .list {
        display: flex;
        gap: 28px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; }
        .page-home-webstories-list .list::-webkit-scrollbar {
          display: none; }
        .page-home-webstories-list .list li {
          position: relative;
          border: 1px solid var(--secundary);
          min-width: 224px;
          min-height: 485px;
          width: 100%;
          cursor: pointer; }
          .page-home-webstories-list .list li:hover .infos {
            background-color: #fff; }
          .page-home-webstories-list .list li img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; }
          .page-home-webstories-list .list li .tag {
            position: absolute;
            top: 10px;
            right: 10px;
            color: var(--secundary);
            border: 1px solid var(--secundary);
            background-color: var(--principal);
            margin: 0 0 24px; }
          .page-home-webstories-list .list li .infos {
            width: 100%;
            padding: calc(0.90615vw + 10.60194px) 14px;
            bottom: 0;
            position: absolute;
            background-color: var(--principal);
            border-top: 1px solid var(--secundary);
            display: flex;
            flex-direction: column;
            transition: background-color .4s ease;
            cursor: pointer; }
            .page-home-webstories-list .list li .infos h3 {
              font-weight: 600; }
      .page-home-webstories-list .btn {
        position: absolute;
        top: 55%;
        right: 0;
        background-color: var(--secundary);
        border: none;
        padding: 20px 26px;
        transition: opacity .4s ease; }
        .page-home-webstories-list .btn:hover {
          opacity: .8; }
    @media screen and (max-width: 1024px) {
      .page-home-webstories {
        padding: 0 0 0 calc(3.23625vw + 7.86408px); }
        .page-home-webstories-list {
          padding: 0; }
          .page-home-webstories-list .btn {
            position: unset;
            top: 0;
            margin: 40px 0 0; } }
  .page-home-projects {
    padding: 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px; }
    .page-home-projects-accordion {
      padding: calc(0.5178vw + 14.05825px) calc(1.5534vw + 10.17476px);
      border: 1px solid var(--secundary);
      border-top: none;
      background-color: transparent;
      transition: background-color .4s ease; }
      .page-home-projects-accordion:hover {
        background-color: #fff; }
      .page-home-projects-accordion:first-of-type {
        border-top: 1px solid var(--secundary); }
      .page-home-projects-accordion summary {
        font-family: 'freight-display-pro', serif;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: opacity .4s ease;
        cursor: pointer; }
      .page-home-projects-accordion .item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: calc(1.94175vw + 22.71845px);
        margin: 30px 0 0;
        position: relative; }
        .page-home-projects-accordion .item-image {
          position: relative;
          width: 60%; }
          .page-home-projects-accordion .item-image img {
            display: block;
            width: 100%;
            height: auto; }
          .page-home-projects-accordion .item-image .btn {
            position: absolute;
            right: -90px;
            bottom: 40px;
            background-color: var(--secundary);
            border: none;
            padding: 20px 24px; }
            .page-home-projects-accordion .item-image .btn img {
              width: auto; }
        .page-home-projects-accordion .item-text {
          margin: calc(3.8835vw + -14.56311px) 0 0;
          padding-right: 40px;
          width: 40%; }
          .page-home-projects-accordion .item-text h3 {
            font-size: calc(1.0356vw + 28.1165px);
            font-weight: 700;
            line-height: 1.1;
            font-family: 'freight-display-pro', serif;
            margin: 0 0 20px; }
        @media screen and (max-width: 1024px) {
          .page-home-projects-accordion .item {
            flex-direction: column; }
            .page-home-projects-accordion .item-image {
              width: 100%; }
              .page-home-projects-accordion .item-image .btn {
                position: unset;
                margin: 40px 0 0; }
                .page-home-projects-accordion .item-image .btn img {
                  width: auto; }
            .page-home-projects-accordion .item-text {
              width: 100%; } }
      .page-home-projects-accordion[open] summary {
        font-size: 24px; }
        .page-home-projects-accordion[open] summary .text {
          text-indent: -99999px;
          height: 0; }
        .page-home-projects-accordion[open] summary .icon::after {
          opacity: 0; }
  .page-home-videos {
    padding: 0 calc(3.23625vw + 7.86408px);
    margin: 0 0 70px; }
    .page-home-videos-container {
      position: relative;
      margin: 0 100px 0 0; }
      .page-home-videos-container-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 28px; }
        .page-home-videos-container-grid article {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 1px solid var(--secundary);
          position: relative;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .4s ease; }
          .page-home-videos-container-grid article:hover {
            background-color: #fff; }
          .page-home-videos-container-grid article .thumb {
            display: block;
            height: 100%; }
          .page-home-videos-container-grid article img {
            display: block;
            width: 100%;
            object-fit: cover;
            height: 100%;
            min-height: 200px; }
          .page-home-videos-container-grid article h3 {
            font-weight: 600; }
          .page-home-videos-container-grid article .content {
            display: flex;
            justify-content: space-between;
            padding: 15px 12px; }
          .page-home-videos-container-grid article:first-of-type {
            grid-column: span 2;
            grid-row: span 2; }
            .page-home-videos-container-grid article:first-of-type img {
              object-fit: cover;
              height: 100%; }
            .page-home-videos-container-grid article:first-of-type .content {
              padding: calc(1.2945vw + 15.14563px) calc(1.5534vw + 10.17476px) calc(0.90615vw + 12.60194px); }
            @media screen and (max-width: 700px) {
              .page-home-videos-container-grid article:first-of-type {
                grid-column: span 3;
                display: flex; } }
          @media screen and (max-width: 700px) {
            .page-home-videos-container-grid article {
              display: none; } }
      .page-home-videos-container .btn {
        position: absolute;
        right: -100px;
        top: 60%;
        background-color: var(--secundary);
        border: none;
        padding: 20px 24px;
        transition: opacity .4s ease; }
        .page-home-videos-container .btn:hover {
          opacity: .8; }
      @media screen and (max-width: 1024px) {
        .page-home-videos-container {
          margin: 0; }
          .page-home-videos-container .btn {
            position: unset;
            margin: 40px 0 0; } }
  .page-home .newsletter-wrap {
    padding: 0 calc(3.23625vw + 7.86408px); }

.page-archive-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 0 calc(3.75405vw + 17.92233px); }
  .page-archive-header .title {
    font-size: calc(3.23625vw + 23.86408px);
    font-weight: 900;
    line-height: 1.2;
    font-family: 'freight-display-pro', serif; }
  .page-archive-header .filter {
    min-width: 255px;
    padding: 14px 28px;
    background-color: var(--secundary);
    color: var(--principal);
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .page-archive-header .filter span, .page-archive-header .filter img {
      display: block; }
  @media screen and (max-width: 1000px) {
    .page-archive-header {
      flex-direction: column;
      align-items: flex-start; }
      .page-archive-header .title {
        margin: 0 0 32px; } }

.page-archive-container .column {
  margin: 0 0 36px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr); }
  .page-archive-container .column article {
    border: 1px solid var(--secundary);
    position: relative;
    cursor: pointer;
    background-color: transparent;
    transition: background-color .4s ease; }
    .page-archive-container .column article:hover {
      background-color: #fff; }
    .page-archive-container .column article .thumb {
      position: relative; }
      .page-archive-container .column article .thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .page-archive-container .column article .thumb .tag {
        position: absolute;
        bottom: 16px;
        left: 16px;
        background-color: var(--principal);
        color: var(--secundary);
        border: 1px solid var(--secundary);
        z-index: 1; }
    .page-archive-container .column article .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between; }
    .page-archive-container .column article .inner {
      width: 100%; }
    .page-archive-container .column article .date {
      font-size: 14px; }
    .page-archive-container .column article .title {
      font-family: 'freight-display-pro', serif;
      font-size: calc(1.0356vw + 28.1165px);
      font-weight: 600;
      line-height: 1.1;
      margin: 26px 0 20px; }
    .page-archive-container .column article .resume {
      margin: 0 0 30px; }
    .page-archive-container .column article .author-container {
      position: relative; }
  .page-archive-container .column-banner article {
    padding: calc(1.5534vw + 10.17476px) calc(3.1068vw + 4.34951px) calc(1.5534vw + 10.17476px) calc(1.16505vw + 11.63107px);
    grid-column: span 4;
    display: flex; }
    .page-archive-container .column-banner article .thumb {
      width: 40%; }
      .page-archive-container .column-banner article .thumb img {
        object-fit: cover;
        width: 100%;
        height: 100%; }
      .page-archive-container .column-banner article .thumb .tag {
        left: 50%;
        transform: translateX(-50%); }
    .page-archive-container .column-banner article .content {
      width: 60%;
      border-left: 1px solid var(--secundary);
      padding-left: calc(1.0356vw + 12.1165px);
      margin-left: calc(1.0356vw + 12.1165px); }
    .page-archive-container .column-banner article .author-container img {
      width: 40px;
      height: 40px; }
    @media screen and (max-width: 1000px) {
      .page-archive-container .column-banner article {
        flex-direction: column; }
        .page-archive-container .column-banner article .thumb {
          width: 100%; }
        .page-archive-container .column-banner article .content {
          width: 100%;
          border-top: 1px solid var(--secundary);
          padding-top: calc(1.0356vw + 12.1165px);
          margin-top: calc(1.0356vw + 12.1165px);
          border-left: none;
          padding-left: 0;
          margin-left: 0; } }
  .page-archive-container .column-highlight article {
    padding: calc(1.5534vw + 10.17476px) calc(1.16505vw + 11.63107px);
    grid-column: span 2; }
    .page-archive-container .column-highlight article .thumb img {
      width: 100%; }
    .page-archive-container .column-highlight article .date {
      margin: 28px 0 12px; }
    .page-archive-container .column-highlight article .title {
      font-size: calc(0.64725vw + 29.57282px);
      margin: 0; }
    @media screen and (max-width: 1000px) {
      .page-archive-container .column-highlight article {
        grid-column: span 4; }
        .page-archive-container .column-highlight article .thumb {
          display: none; }
        .page-archive-container .column-highlight article .date {
          margin: 0; } }
  .page-archive-container .column-testimonial {
    grid-template-columns: repeat(3, 1fr); }
    .page-archive-container .column-testimonial article {
      display: flex;
      flex-direction: column;
      padding: calc(0.5178vw + 14.05825px) calc(0.5178vw + 14.05825px) calc(1.8123vw + 9.20388px); }
      .page-archive-container .column-testimonial article .title {
        font-size: calc(0.7767vw + 17.08738px);
        line-height: 1.4;
        margin: 0 0 16px; }
      .page-archive-container .column-testimonial article .thumb {
        border-radius: 100%;
        overflow: hidden;
        min-width: calc(3.1068vw + 132.34951px);
        min-height: calc(3.1068vw + 132.34951px);
        width: calc(3.1068vw + 132.34951px);
        height: calc(3.1068vw + 132.34951px);
        margin: 0 auto; }
      .page-archive-container .column-testimonial article .content {
        height: 100%; }
        .page-archive-container .column-testimonial article .content h2 {
          line-height: 1.2; }
        .page-archive-container .column-testimonial article .content img {
          margin: 25px 0 0;
          width: 26px; }
        .page-archive-container .column-testimonial article .content .tag {
          position: relative; }
        .page-archive-container .column-testimonial article .content .author-container span {
          font-size: 14px; }
    @media screen and (max-width: 1000px) {
      .page-archive-container .column-testimonial {
        grid-template-columns: repeat(1, 1fr); }
        .page-archive-container .column-testimonial article .thumb {
          display: none; }
        .page-archive-container .column-testimonial article .content img {
          margin: 0 0 18px; }
        .page-archive-container .column-testimonial article:first-of-type .thumb {
          display: block; }
        .page-archive-container .column-testimonial article:first-of-type .content img {
          margin: 25px 0 18px; } }
  .page-archive-container .column-grid {
    gap: 10px; }
    .page-archive-container .column-grid article {
      padding: calc(-0.38835vw + 21.45631px); }
      .page-archive-container .column-grid article:nth-child(1), .page-archive-container .column-grid article:nth-child(2), .page-archive-container .column-grid article:nth-child(3) {
        display: none; }
      .page-archive-container .column-grid article a {
        height: 100%;
        display: flex;
        flex-direction: column; }
      .page-archive-container .column-grid article .title {
        font-size: 16px;
        line-height: 1.4;
        font-family: 'adelle-sans', sans-serif;
        margin: 14px 0 16px; }
      .page-archive-container .column-grid article .date {
        font-size: 12px;
        text-transform: none;
        margin: 0; }
      .page-archive-container .column-grid article .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .page-archive-container .column-grid article .name {
        font-size: 14px; }
      @media screen and (max-width: 1000px) {
        .page-archive-container .column-grid article {
          grid-column: span 2; }
          .page-archive-container .column-grid article .thumb {
            display: none; }
          .page-archive-container .column-grid article .title {
            margin: 0 0 16px; } }
      @media screen and (max-width: 700px) {
        .page-archive-container .column-grid article {
          grid-column: span 4; } }

.post-type-archive-entrevistas .page-archive-container .column-grid article:nth-child(1), .post-type-archive-entrevistas .page-archive-container .column-grid article:nth-child(2), .post-type-archive-entrevistas .page-archive-container .column-grid article:nth-child(3), .post-type-archive-entrevistas .page-archive-container .column-grid article:nth-child(4) {
  display: none; }

.post .progress {
  width: 0%;
  height: 8px;
  background-color: #000;
  transition: all linear 0.1s;
  position: sticky;
  top: 110px;
  left: 0;
  z-index: 1; }
  @media screen and (max-width: 1024px) {
    .post .progress {
      top: 76px; } }

.post .hero {
  margin: 0 0 46px; }
  .post .hero .title {
    margin: 0 0 50px;
    font-size: calc(2.0712vw + 24.23301px);
    font-weight: 900;
    line-height: 1.1;
    font-family: 'freight-display-pro', serif; }
  .post .hero .date {
    margin: 0 0 18px;
    font-size: 14px;
    text-transform: uppercase; }
  .post .hero .subtitle {
    font-size: 18px; }
  .post .hero-text {
    max-width: 1200px;
    padding: calc(3.75405vw + 17.92233px) calc(10.35599vw + -18.83495px) 0; }
    .post .hero-text .subtitle {
      max-width: 800px; }
  .post .hero-full figcaption {
    padding: 0 calc(10.35599vw + -18.83495px) 0; }

.post .box {
  background-color: #B6CCC8;
  border: 1px solid var(--secundary);
  position: relative;
  font-style: italic; }
  .post .box-content {
    display: block;
    padding: calc(0.5178vw + 14.05825px) calc(1.8123vw + 37.20388px) calc(0.5178vw + 14.05825px) calc(1.8123vw + 9.20388px); }
  .post .box-btn {
    position: absolute;
    right: calc(-1.16505vw + -5.63107px);
    top: 20px;
    background-color: var(--secundary);
    width: 56px;
    height: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .post .box-btn img {
      display: block;
      width: 50%; }
  .post .box.nobox {
    background-color: transparent;
    border: none; }
    .post .box.nobox .box-content {
      padding: 0; }

.post .author {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 20px;
  justify-content: space-between;
  margin: 0 0 42px; }
  .post .author > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 14px; }
    .post .author > div > div {
      display: flex;
      gap: 6px;
      align-items: center;
      margin: 0; }
    .post .author > div img {
      display: block;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      object-fit: cover;
      margin: 0; }
    .post .author > div p {
      font-weight: 700;
      margin: 0;
      font-size: 16px; }
  .post .author .placeholder {
    border: 1px solid #000;
    border-radius: 100%;
    width: 35px;
    height: 35px; }
    .post .author .placeholder img {
      scale: 0.7;
      position: relative;
      top: 1px;
      left: -1px; }
  .post .author .tags {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end; }
    .post .author .tags li {
      white-space: nowrap; }
  @media screen and (max-width: 1024px) {
    .post .author {
      align-items: flex-start;
      grid-template-columns: 1fr; }
      .post .author > div {
        margin: 0 0 20px; }
      .post .author .tags {
        width: 100%;
        justify-content: flex-start; } }

.post-banner {
  margin: 0 0 calc(2.589vw + 30.29126px);
  display: grid;
  gap: 0 calc(2.589vw + 30.29126px); }
  .post-banner .photo {
    display: block;
    width: 100%; }
  .post-banner figcaption {
    font-size: 14px;
    color: #615C57;
    margin: 8px 0 0; }
  .post-banner figure img {
    width: 100%;
    height: 100%;
    min-height: 270px;
    object-fit: cover; }
  .post-banner figure > div {
    height: auto !important;
    max-height: 512px;
    overflow: hidden;
    display: flex;
    align-items: center; }
  .post-banner .credit {
    font-size: 12px;
    transform: scale(-1);
    writing-mode: vertical-rl; }
  .post-banner.column-01 {
    margin: 0 0 32px; }
  .post-banner .hero-interview {
    padding: calc(3.75405vw + 17.92233px) calc(10.35599vw + -18.83495px) 0;
    display: grid;
    gap: 26px calc(2.589vw + 30.29126px);
    grid-template-columns: repeat(3, 1fr); }
    .post-banner .hero-interview h1 {
      font-size: calc(1.5534vw + 42.17476px);
      font-weight: 900;
      line-height: 1.1;
      font-family: 'freight-display-pro', serif;
      grid-column: span 3; }
    .post-banner .hero-interview .date-container {
      display: block;
      text-transform: uppercase;
      margin: 0 0 26px; }
    .post-banner .hero-interview .box {
      margin: 0 0 26px; }
    .post-banner .hero-interview .author {
      margin: 0 0 26px; }
    .post-banner .hero-interview-content {
      grid-column: span 2; }
      @media screen and (max-width: 1024px) {
        .post-banner .hero-interview-content {
          grid-column: span 3; } }
  .post-banner .hero-new {
    padding: calc(3.75405vw + 17.92233px) calc(10.35599vw + -18.83495px) 0; }
    .post-banner .hero-new div {
      height: 100%; }
      .post-banner .hero-new div img {
        height: 100%;
        object-fit: cover; }

.post-wrapper {
  display: grid;
  gap: calc(2.589vw + 30.29126px);
  grid-template-columns: repeat(3, 1fr);
  padding: 0 calc(10.35599vw + -18.83495px) 0; }
  .post-wrapper .content {
    width: 100%;
    grid-column: span 2; }
    @media screen and (max-width: 1024px) {
      .post-wrapper .content {
        grid-column: span 3; } }
    .post-wrapper .content .image-container {
      display: flex;
      gap: 26px;
      align-items: flex-start;
      margin: 0 0 32px; }
      .post-wrapper .content .image-container figure {
        width: 100%; }
        .post-wrapper .content .image-container figure img {
          display: block;
          width: 100%;
          height: auto; }
        .post-wrapper .content .image-container figure figcaption {
          font-size: 14px;
          color: #615C57;
          margin: 8px 0 0; }
      @media screen and (max-width: 1000px) {
        .post-wrapper .content .image-container {
          flex-direction: column; }
          .post-wrapper .content .image-container figure {
            width: 100%; } }
    .post-wrapper .content .box {
      width: fit-content;
      margin: 0 0 66px;
      cursor: pointer;
      transition: background-color .4s ease; }
      .post-wrapper .content .box button {
        top: 50%;
        transform: translateY(-50%); }
        .post-wrapper .content .box button img {
          width: 18px; }
      .post-wrapper .content .box:hover {
        background-color: #fff; }
      .post-wrapper .content .box.box-wire {
        background: none;
        position: relative; }
        .post-wrapper .content .box.box-wire:hover {
          background-color: #fff; }
        .post-wrapper .content .box.box-wire .box-btn {
          top: 50%;
          transform: translateY(-50%);
          width: 40px;
          height: 40px; }
    .post-wrapper .content .list {
      width: fit-content;
      margin: 0 0 52px; }
      .post-wrapper .content .list li {
        gap: 80px;
        display: flex;
        justify-content: space-between;
        padding: 12px 26px;
        border-bottom: 1px solid var(--secundary);
        border-left: 1px solid var(--secundary);
        border-right: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease; }
        .post-wrapper .content .list li:hover {
          background-color: #fff; }
        .post-wrapper .content .list li a {
          display: flex;
          flex-direction: column;
          justify-content: center; }
        .post-wrapper .content .list li:first-of-type {
          border-top: 1px solid var(--secundary); }
    .post-wrapper .content .text {
      margin: 0 0 40px; }
      .post-wrapper .content .text p {
        margin: 0 0 20px;
        font-size: calc(0.19417vw + 17.27184px);
        line-height: 1.8;
        clear: both; }
        .post-wrapper .content .text p u {
          font-weight: 700; }
        .post-wrapper .content .text p code {
          clear: both;
          margin: 5px 20px 0 0;
          padding: 0 0 4px;
          float: left;
          font-family: inherit;
          font-size: 600%;
          line-height: .8;
          font-weight: bold;
          text-transform: uppercase;
          border-bottom: 10px solid #B6CCC8; }
      .post-wrapper .content .text img {
        display: block;
        max-width: 100%;
        height: auto; }
      .post-wrapper .content .text a {
        text-decoration: underline;
        transition: font-weight .4s ease; }
        .post-wrapper .content .text a:hover {
          font-weight: 700; }
      .post-wrapper .content .text h1, .post-wrapper .content .text h2, .post-wrapper .content .text h3, .post-wrapper .content .text h4, .post-wrapper .content .text h5, .post-wrapper .content .text h6 {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 20px;
        text-transform: uppercase; }
      .post-wrapper .content .text .wp-caption-text {
        font-size: 14px;
        color: #615C57;
        margin: 8px 0 0; }
      .post-wrapper .content .text div {
        max-width: 100%; }
      .post-wrapper .content .text-box {
        background-color: #B6CCC8;
        padding: calc(0.64725vw + 37.57282px) calc(3.23625vw + 27.86408px) calc(1.2945vw + 15.14563px);
        border: 1px solid #000; }
    .post-wrapper .content .parallax {
      position: relative;
      width: 100vw;
      margin: 0 0 80px;
      left: calc(-10.35599vw + 18.83495px);
      overflow: hidden; }
      .post-wrapper .content .parallax-image {
        transition: all .4s ease;
        position: absolute;
        height: 100%;
        width: 100vw;
        background: 50% 50% / contain;
        background-attachment: fixed;
        background-repeat: no-repeat; }
    .post-wrapper .content .carousel {
      margin: 0 0 60px;
      position: relative; }
      .post-wrapper .content .carousel img {
        display: block;
        width: 100%;
        height: 100%; }
      .post-wrapper .content .carousel figcaption {
        font-size: 14px;
        color: #615C57;
        margin: 8px 0 0; }
      .post-wrapper .content .carousel-prev, .post-wrapper .content .carousel-next {
        width: 30px;
        height: 30px;
        background-color: #000;
        display: flex;
        justify-content: center;
        align-items: center; }
        .post-wrapper .content .carousel-prev img, .post-wrapper .content .carousel-next img {
          display: block;
          width: 20px; }
      .post-wrapper .content .carousel-arrows {
        position: absolute;
        width: calc(100% + 20px);
        display: flex;
        justify-content: space-between;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .post-wrapper .content .card {
      display: flex;
      margin: 0 0 80px;
      border: 1px solid var(--secundary); }
      .post-wrapper .content .card img {
        object-fit: cover;
        display: block;
        height: 100%;
        border-right: 1px solid #000; }
      .post-wrapper .content .card .info {
        padding: calc(1.0356vw + 12.1165px) calc(3.6246vw + 2.40777px) calc(1.0356vw + 12.1165px) calc(1.0356vw + 12.1165px);
        display: flex;
        flex-direction: column;
        justify-content: center; }
        .post-wrapper .content .card .info h3 {
          margin: 0 0 18px;
          font-size: calc(1.68285vw + 73.68932px);
          font-weight: 900;
          line-height: 1;
          font-family: 'freight-display-pro', serif; }
        .post-wrapper .content .card .info .text, .post-wrapper .content .card .info .quote {
          font-size: calc(0.5178vw + 22.05825px);
          font-weight: 500;
          line-height: 1.1;
          font-family: 'freight-display-pro', serif; }
          .post-wrapper .content .card .info .text p, .post-wrapper .content .card .info .quote p {
            margin: 20px 0 0; }
          .post-wrapper .content .card .info .text a, .post-wrapper .content .card .info .quote a {
            text-decoration: underline; }
          .post-wrapper .content .card .info .text strong, .post-wrapper .content .card .info .quote strong {
            font-weight: 700; }
        .post-wrapper .content .card .info .legend {
          margin: 28px 0 0; }
          .post-wrapper .content .card .info .legend b {
            display: block; }
      @media screen and (max-width: 700px) {
        .post-wrapper .content .card {
          flex-direction: column; }
          .post-wrapper .content .card img {
            width: 100%;
            border-right: none; } }
    .post-wrapper .content .columns {
      margin: 0 0 80px;
      display: flex;
      gap: 20px; }
      .post-wrapper .content .columns figure {
        display: flex;
        flex-direction: column;
        width: 50%; }
        .post-wrapper .content .columns figure img {
          display: block;
          width: 100%;
          object-fit: cover;
          height: 100%; }
        .post-wrapper .content .columns figure figcaption {
          margin: 12px 0 0;
          color: #615C57;
          font-size: 14px; }
        .post-wrapper .content .columns figure:nth-child(2) {
          flex-direction: column-reverse; }
          .post-wrapper .content .columns figure:nth-child(2) figcaption {
            margin: 0 0 12px;
            color: #615C57;
            font-size: 14px; }
    .post-wrapper .content .order {
      margin: 80px 0; }
      .post-wrapper .content .order li {
        margin: 0 0 40px; }
        .post-wrapper .content .order li .index {
          width: fit-content;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1;
          font-size: calc(1.16505vw + 19.63107px);
          font-weight: 800;
          padding: 14px;
          margin: 0 0 20px; }
        .post-wrapper .content .order li h3 {
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 800;
          margin: 0 0 20px; }
        .post-wrapper .content .order li p {
          margin: 0 0 20px; }
          .post-wrapper .content .order li p a {
            text-decoration: underline; }
    .post-wrapper .content .section-title {
      margin: 0 0 20px;
      font-size: calc(1.0356vw + 28.1165px);
      font-weight: 900;
      line-height: 1.1;
      font-family: 'freight-display-pro', serif; }
    .post-wrapper .content .link {
      font-weight: 700;
      display: flex;
      gap: 10px;
      margin: 0 0 40px; }
      .post-wrapper .content .link img {
        width: 16px; }
      .post-wrapper .content .link span {
        display: block;
        text-decoration: underline; }
    .post-wrapper .content .code {
      margin: 0 0 80px; }
    .post-wrapper .content .question {
      font-size: 20px;
      line-height: 1.4;
      font-weight: 700;
      margin: 0 0 20px; }
    .post-wrapper .content .video {
      aspect-ratio: 16/9;
      position: relative;
      padding-top: 56.25%;
      margin: 0 0 80px; }
      .post-wrapper .content .video iframe {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%; }
    .post-wrapper .content .answer {
      margin: 0 0 80px;
      position: relative; }
      .post-wrapper .content .answer p {
        margin: 0 0 20px;
        font-size: calc(0.19417vw + 17.27184px);
        line-height: 1.8; }
      .post-wrapper .content .answer a {
        text-decoration: underline;
        font-weight: 700; }
    .post-wrapper .content .accordion {
      margin: 0 0 80px; }
      .post-wrapper .content .accordion details {
        padding: calc(0.5178vw + 14.05825px) calc(2.0712vw + 16.23301px);
        background-color: #B6CCC8;
        border: 1px solid var(--secundary);
        border-top: none;
        cursor: pointer;
        transition: background-color .4s ease; }
        .post-wrapper .content .accordion details:hover {
          background-color: #fff; }
        .post-wrapper .content .accordion details:first-of-type {
          border-top: 1px solid var(--secundary); }
        .post-wrapper .content .accordion details summary {
          font-size: 24px;
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          gap: 20px; }
          .post-wrapper .content .accordion details summary .title {
            max-width: 80%; }
        .post-wrapper .content .accordion details p {
          margin: 0 0 20px; }
        .post-wrapper .content .accordion details[open] summary {
          margin: 0 0 24px; }
          .post-wrapper .content .accordion details[open] summary .icon::after {
            opacity: 0; }
    .post-wrapper .content .image-banner {
      margin: 0 0 80px; }
      .post-wrapper .content .image-banner img {
        display: block;
        width: auto;
        max-width: 100%;
        height: auto; }
      .post-wrapper .content .image-banner figcaption {
        font-size: 14px;
        color: #615C57;
        margin: 8px 0 0; }
    .post-wrapper .content blockquote {
      display: flex;
      gap: 12px;
      align-items: flex-start;
      margin: 0 0 80px;
      font-size: calc(1.16505vw + 19.63107px);
      font-weight: 900;
      line-height: 1.1;
      font-family: 'freight-display-pro', serif;
      padding: 0 calc(5.17799vw + 0.58252px); }
      .post-wrapper .content blockquote img {
        width: calc(1.8123vw + 33.20388px); }
      .post-wrapper .content blockquote.highlight {
        margin: 0 calc(6.47249vw + -4.27184px) 80px;
        border-top: 1px solid var(--secundary);
        border-bottom: 1px solid var(--secundary);
        padding: 44px 0; }
      .post-wrapper .content blockquote.card {
        border: 1px solid var(--secundary);
        padding: calc(1.16505vw + 15.63107px) calc(4.85437vw + 56.79612px) calc(1.16505vw + 15.63107px) calc(1.2945vw + 35.14563px);
        margin: 0 0 80px;
        flex-direction: column; }
        .post-wrapper .content blockquote.card h3 {
          font-size: calc(4.53074vw + 53.00971px);
          line-height: .7; }
        .post-wrapper .content blockquote.card p {
          font-weight: 600; }
          .post-wrapper .content blockquote.card p:first-of-type {
            margin: 40px 0 0; }
    .post-wrapper .content .dot {
      margin: 0 0 80px;
      width: 20px;
      height: 20px;
      background: #000;
      border-radius: 100%; }
  .post-wrapper .sidebar {
    grid-column: span 1;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end; }
    .post-wrapper .sidebar ul {
      width: 100%;
      border: 1px solid var(--secundary);
      margin: 0 0 32px; }
      .post-wrapper .sidebar ul li {
        padding: calc(0.12945vw + 15.51456px) calc(4.91909vw + -2.4466px) calc(0.12945vw + 15.51456px) calc(0.2589vw + 15.02913px);
        border-top: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease; }
        .post-wrapper .sidebar ul li:hover {
          background-color: #fff; }
        .post-wrapper .sidebar ul li h2 {
          font-size: 18px;
          font-weight: 800; }
        .post-wrapper .sidebar ul li a {
          display: block; }
        .post-wrapper .sidebar ul li span {
          display: block;
          opacity: .4;
          font-weight: 700; }
        .post-wrapper .sidebar ul li p {
          line-height: 1.4; }
        .post-wrapper .sidebar ul li:first-of-type {
          padding: 14px 20px;
          background-color: #B6CCC8;
          border-top: none; }
      .post-wrapper .sidebar ul.list {
        border: none;
        margin: 0 0 60px; }
        .post-wrapper .sidebar ul.list li {
          padding: 18px 0;
          border-top: none;
          border-bottom: 1px dashed var(--secundary); }
          .post-wrapper .sidebar ul.list li a {
            display: flex;
            gap: 52px;
            justify-content: space-between; }
          .post-wrapper .sidebar ul.list li h2 {
            font-size: 32px;
            font-weight: 700;
            line-height: 1.2;
            font-family: 'freight-display-pro', serif; }
          .post-wrapper .sidebar ul.list li:first-of-type {
            padding: 0 0 18px;
            background-color: transparent;
            border-top: none;
            border-bottom: 1px solid var(--secundary); }
          .post-wrapper .sidebar ul.list li:last-of-type {
            border-bottom: 1px solid var(--secundary); }
    .post-wrapper .sidebar .related h2 {
      font-size: 32px;
      font-weight: 700;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      margin: 0 0 18px; }
    .post-wrapper .sidebar .related article {
      padding: calc(0.5178vw + 14.05825px) calc(0.2589vw + 15.02913px);
      margin: 0 0 24px;
      border: 1px solid var(--secundary);
      position: relative;
      cursor: pointer;
      background-color: transparent;
      transition: background-color .4s ease; }
      .post-wrapper .sidebar .related article:hover {
        background-color: #fff; }
      .post-wrapper .sidebar .related article a {
        display: block; }
      .post-wrapper .sidebar .related article figure {
        border-bottom: 1px solid var(--secundary);
        display: block;
        padding: 0 0 20px;
        margin: 0 0 20px; }
        .post-wrapper .sidebar .related article figure img {
          display: block;
          width: 100%;
          object-fit: cover; }
      .post-wrapper .sidebar .related article .tag {
        margin: 0 0 20px;
        position: relative;
        z-index: 1; }
      .post-wrapper .sidebar .related article h3 {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        margin: 0 0 44px; }
      .post-wrapper .sidebar .related article .author-container img {
        width: 50px;
        height: auto; }
    .post-wrapper .sidebar .support-card {
      margin: 0 0 40px;
      padding: 40px 24px;
      border: 1px solid var(--secundary);
      background-color: #B6CCC8;
      position: relative;
      cursor: pointer;
      transition: background-color .4s ease; }
      .post-wrapper .sidebar .support-card:hover {
        background-color: #fff; }
        .post-wrapper .sidebar .support-card:hover .btn {
          opacity: 1; }
      .post-wrapper .sidebar .support-card img {
        display: block;
        max-width: 100%;
        margin: 0 0 24px; }
      .post-wrapper .sidebar .support-card h2 {
        font-size: 32px;
        font-weight: 900;
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        margin: 0 0 12px; }
      .post-wrapper .sidebar .support-card p {
        margin: 0 0 24px; }
      .post-wrapper .sidebar .support-card a {
        padding: 20px 60px;
        background-color: var(--secundary);
        color: #fff;
        width: 100%; }
    .post-wrapper .sidebar .news-box {
      width: 100%;
      margin: 0 0 40px; }
      .post-wrapper .sidebar .news-box h2 {
        display: block;
        font-size: 32px;
        font-weight: 900;
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        margin: 0 0 24px; }
      .post-wrapper .sidebar .news-box form {
        border-bottom: 1px solid var(--secundary);
        padding: 0 0 12px; }
        .post-wrapper .sidebar .news-box form .label-name {
          display: none; }
        .post-wrapper .sidebar .news-box form label {
          display: block; }
        .post-wrapper .sidebar .news-box form p {
          position: relative;
          height: 50px; }
        .post-wrapper .sidebar .news-box form input {
          display: block;
          width: 100%;
          height: 50px; }
          .post-wrapper .sidebar .news-box form input::placeholder {
            font-style: italic; }
          .post-wrapper .sidebar .news-box form input[type="submit"] {
            text-indent: -9999999px;
            background-image: url("../assets/arrow-black.svg");
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            width: 28px;
            height: 28px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%); }
    @media screen and (max-width: 1024px) {
      .post-wrapper .sidebar {
        grid-column: span 3; } }

.post-footer {
  padding: 0 calc(10.35599vw + -18.83495px) 0;
  margin: calc(5.17799vw + 20.58252px) 0 0; }
  .post-footer h3 {
    font-size: calc(0.5178vw + 14.05825px);
    font-weight: 800;
    text-transform: uppercase;
    margin: 0 0 32px;
    grid-column: span 2; }
    @media screen and (max-width: 1024px) {
      .post-footer h3 {
        grid-column: span 3; } }
  .post-footer .list {
    grid-column: span 2;
    gap: calc(1.42395vw + 16.66019px);
    display: flex;
    justify-content: space-between;
    padding: 0 0 56px;
    border-bottom: 1px solid var(--secundary); }
    @media screen and (max-width: 1024px) {
      .post-footer .list {
        grid-column: span 3; } }
    .post-footer .list li {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: calc(1.16505vw + 11.63107px) calc(0.7767vw + 13.08738px);
      border: 1px solid var(--secundary);
      width: 100%;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      transition: background-color .4s ease; }
      .post-footer .list li:hover {
        background-color: #fff; }
      .post-footer .list li figure {
        position: relative; }
        .post-footer .list li figure img {
          display: block;
          width: 100%;
          height: auto;
          border: 1px solid var(--secundary);
          object-fit: cover; }
        .post-footer .list li figure .tag {
          position: absolute;
          padding: 8px 10px;
          top: 16px;
          left: 16px;
          font-size: 10px;
          z-index: 1; }
      .post-footer .list li h4 {
        font-size: 18px;
        font-weight: 700;
        margin: 16px 0 32px; }
      .post-footer .list li .info {
        gap: 10px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between; }
    @media screen and (max-width: 700px) {
      .post-footer .list {
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; }
        .post-footer .list::-webkit-scrollbar {
          display: none; }
        .post-footer .list li {
          min-width: 260px; } }
  .post-footer .authors {
    margin: 46px 0; }
    .post-footer .authors .author {
      grid-column: span 2;
      margin: 32px 0 0;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start; }
      .post-footer .authors .author > img {
        min-width: calc(2.71845vw + 31.80583px);
        width: calc(2.71845vw + 31.80583px);
        height: calc(2.71845vw + 31.80583px);
        object-fit: cover;
        margin: 0 26px 0 0;
        border-radius: 100%; }
      .post-footer .authors .author .description {
        width: 80%; }
        .post-footer .authors .author .description p {
          font-weight: 500; }
      .post-footer .authors .author .placeholder {
        margin: 0 26px 0 0;
        width: calc(2.71845vw + 31.80583px);
        height: calc(2.71845vw + 31.80583px);
        border: calc(0.06472vw + 0.75728px) solid #000;
        border-radius: 100%;
        position: relative; }
        .post-footer .authors .author .placeholder > img {
          min-width: calc(2.71845vw + 31.80583px);
          width: calc(2.71845vw + 31.80583px);
          height: calc(2.71845vw + 31.80583px);
          object-fit: cover;
          border-radius: 100%;
          scale: 0.8;
          position: absolute;
          top: 2px;
          left: -1px; }
      .post-footer .authors .author h3 {
        margin: 6px 0 12px; }
      .post-footer .authors .author p {
        margin: 0 0 20px; }
      .post-footer .authors .author .social-container {
        display: flex;
        align-items: center; }
        .post-footer .authors .author .social-container h4 {
          font-weight: 700;
          margin: 0 12px 0 0; }
      .post-footer .authors .author:first-of-type {
        margin: 0; }
      @media screen and (max-width: 1024px) {
        .post-footer .authors .author {
          grid-column: span 3; } }
  .post-footer .support-container {
    grid-column: span 2; }
    @media screen and (max-width: 1024px) {
      .post-footer .support-container {
        grid-column: span 3; } }

.page-towatch .title {
  font-size: calc(1.0356vw + 28.1165px);
  font-weight: 700;
  line-height: 1.2;
  margin: 0 0 40px;
  font-family: 'freight-display-pro', serif; }

.page-towatch .thumb a {
  display: block;
  height: 100%; }

.page-towatch .grid {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, 1fr); }
  .page-towatch .grid article {
    border: 1px solid var(--secundary); }
    .page-towatch .grid article .thumb {
      height: 220px;
      overflow: hidden; }
      .page-towatch .grid article .thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  @media screen and (max-width: 1024px) {
    .page-towatch .grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 700px) {
    .page-towatch .grid {
      grid-template-columns: repeat(1, 1fr); } }

.page-towatch-hero {
  margin: 0 0 80px;
  display: flex;
  gap: 48px;
  justify-content: space-between;
  position: relative; }
  .page-towatch-hero figure {
    width: 60%; }
    .page-towatch-hero figure img {
      display: block;
      width: 100%;
      height: auto; }
    .page-towatch-hero figure figcaption {
      font-size: 14px;
      margin: 12px 0 0;
      color: #615C57;
      font-style: italic; }
  .page-towatch-hero .content {
    width: 40%; }
    .page-towatch-hero .content p {
      line-height: 1.8; }
  @media screen and (max-width: 1024px) {
    .page-towatch-hero {
      flex-direction: column; }
      .page-towatch-hero figure {
        width: 100%; }
      .page-towatch-hero .content {
        width: 100%; } }

.page-towatch-gallery {
  margin: 0 0 80px; }
  .page-towatch-gallery .grid article {
    position: relative; }
  .page-towatch-gallery .grid .content {
    padding: 20px 12px; }
    .page-towatch-gallery .grid .content h3 {
      max-width: 230px; }
  @media screen and (max-width: 700px) {
    .page-towatch-gallery .grid {
      grid-template-columns: repeat(1, 1fr); }
      .page-towatch-gallery .grid article {
        display: none; }
      .page-towatch-gallery .grid article:first-of-type {
        display: block; } }

.page-towatch-news {
  margin: 0 0 80px; }
  .page-towatch-news .title {
    margin: 0 0 6px; }
  .page-towatch-news .subtitle {
    margin: 0 0 40px; }
  .page-towatch-news .grid {
    gap: 20px;
    grid-template-columns: repeat(4, 1fr); }
    .page-towatch-news .grid article {
      padding: 0 14px 18px;
      position: relative; }
      .page-towatch-news .grid article h3 {
        font-weight: 600;
        margin: 14px 0 16px; }
    @media screen and (max-width: 1024px) {
      .page-towatch-news .grid {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 700px) {
      .page-towatch-news .grid {
        grid-template-columns: repeat(1, 1fr); }
        .page-towatch-news .grid .thumb {
          display: none; } }

.page-contact {
  display: flex;
  gap: calc(2.3301vw + 51.26214px);
  justify-content: space-between; }
  .page-contact-content {
    max-width: 377px;
    width: 100%; }
    .page-contact-content .thumb {
      margin: 0 0 36px; }
      .page-contact-content .thumb img {
        width: 100%; }
    .page-contact-content .title {
      margin: 0 0 40px;
      font-family: 'freight-display-pro', serif;
      font-size: calc(1.0356vw + 28.1165px);
      font-weight: 900;
      line-height: 1.1; }
    .page-contact-content .text {
      margin: 0 0 40px; }
  .page-contact-form {
    width: 100%;
    border: 1px solid var(--secundary);
    padding: 32px 60px 100px;
    position: relative; }
    .page-contact-form form p {
      font-weight: 700; }
    .page-contact-form form label {
      display: block; }
    .page-contact-form form span {
      display: block;
      margin: 10px 0 0; }
    .page-contact-form form input, .page-contact-form form textarea {
      padding: 12px 20px;
      border: 1px solid var(--secundary);
      background-color: #B6CCC8;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      font-family: 'adelle-sans', sans-serif;
      color: var(--secundary); }
      .page-contact-form form input::-webkit-input-placeholder, .page-contact-form form textarea::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 16px;
        font-family: 'adelle-sans', sans-serif;
        color: var(--secundary);
        font-style: italic; }
      .page-contact-form form input::-moz-placeholder, .page-contact-form form textarea::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 16px;
        font-family: 'adelle-sans', sans-serif;
        color: var(--secundary);
        font-style: italic; }
      .page-contact-form form input:-ms-input-placeholder, .page-contact-form form textarea:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 16px;
        font-family: 'adelle-sans', sans-serif;
        color: var(--secundary);
        font-style: italic; }
      .page-contact-form form input:-moz-placeholder, .page-contact-form form textarea:-moz-placeholder {
        /* Firefox 18- */
        font-size: 16px;
        font-family: 'adelle-sans', sans-serif;
        color: var(--secundary);
        font-style: italic; }
    .page-contact-form form textarea {
      min-height: 168px; }
    .page-contact-form form .btn {
      width: fit-content;
      text-align: center;
      padding: 20px 70px;
      position: absolute;
      right: -50px;
      bottom: 40px;
      color: #F0F5F4;
      border: 1px solid var(--secundary);
      background-color: var(--secundary);
      background-image: url("../assets/arrow-beige.svg");
      background-repeat: no-repeat;
      background-position: center right 16px;
      cursor: pointer; }
  @media screen and (max-width: 1000px) {
    .page-contact {
      flex-direction: column; }
      .page-contact-content {
        max-width: 100%; }
      .page-contact-form {
        border: none;
        padding: 0; }
        .page-contact-form form .btn {
          width: 100%;
          margin: 30px 0 0;
          position: relative;
          right: 0;
          bottom: 0;
          padding: 20px 16px; }
          .page-contact-form form .btn img {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%); } }

.page-authors {
  position: relative;
  padding: calc(3.75405vw + 17.92233px) 0 calc(1.94175vw + 52.71845px); }
  .page-authors .title {
    text-align: center;
    margin: 0 auto calc(1.94175vw + 52.71845px);
    font-size: calc(3.23625vw + 23.86408px);
    font-weight: 900;
    line-height: 1.1;
    font-family: 'freight-display-pro', serif; }
  .page-authors-carousel {
    position: relative;
    padding: 0 0 0 calc(10.35599vw + -18.83495px);
    margin: 0 0 calc(1.94175vw + 52.71845px); }
    .page-authors-carousel .slider .slick-track {
      display: flex !important; }
    .page-authors-carousel .slider .slick-slide {
      height: inherit !important; }
      .page-authors-carousel .slider .slick-slide > div {
        height: 100% !important; }
    .page-authors-carousel .slider-item {
      padding: 0 20px 0 0;
      height: 100% !important; }
      .page-authors-carousel .slider-item .content {
        height: 100% !important;
        padding: calc(1.0356vw + 12.1165px) calc(1.42395vw + 10.66019px);
        background-color: #F0F5F4;
        border: 1px solid var(--secundary);
        position: relative; }
        .page-authors-carousel .slider-item .content img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 0 14px; }
        .page-authors-carousel .slider-item .content h3 {
          margin: 0 0 10px;
          font-size: 20px;
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif; }
    .page-authors-carousel .slider-nav {
      display: flex;
      gap: 28px;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      bottom: -42px;
      right: 200px; }
      .page-authors-carousel .slider-nav-arrow {
        background-color: #000;
        width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .page-authors-carousel .slider-nav-arrow img {
          width: 50%; }
      .page-authors-carousel .slider-nav .slick-disabled {
        background-color: #8A999A; }
    @media screen and (max-width: 1000px) {
      .page-authors-carousel .slider-nav {
        justify-content: flex-start;
        position: relative;
        right: auto;
        left: 0;
        bottom: auto;
        margin: 32px 0 0; } }
  .page-authors-grid {
    padding: 0 calc(10.35599vw + -18.83495px);
    margin: 0 0 calc(1.94175vw + 52.71845px); }
    .page-authors-grid .grid {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr); }
      .page-authors-grid .grid-item {
        padding: 10px 14px 20px;
        border: 1px solid var(--secundary);
        position: relative; }
        .page-authors-grid .grid-item img {
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
          margin: 0 0 calc(1.94175vw + 14.71845px); }
        .page-authors-grid .grid-item h3 {
          font-weight: 700;
          margin: 0 0 10px; }
        @media screen and (max-width: 1000px) {
          .page-authors-grid .grid-item {
            grid-column: span 4;
            padding: 16px; }
            .page-authors-grid .grid-item img {
              display: none; } }
  .page-authors-list {
    padding: 0 calc(10.35599vw + -18.83495px);
    margin: 0 0 calc(1.5534vw + 74.17476px); }
    .page-authors-list .list summary {
      font-size: 32px;
      font-weight: 900;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      padding: 0 0 14px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid var(--secundary); }
    .page-authors-list .list[open] summary .icon::after {
      opacity: 0; }
    .page-authors-list .list-item {
      padding: 24px 0;
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px dashed var(--secundary);
      position: relative; }
      .page-authors-list .list-item:last-of-type {
        border-bottom: 1px solid var(--secundary); }
      .page-authors-list .list-item .content {
        display: flex;
        gap: 12px;
        align-items: center; }
        .page-authors-list .list-item .content h3 {
          font-size: 18px;
          font-weight: 700; }
        .page-authors-list .list-item .content img {
          display: block;
          width: 64px;
          height: 64px;
          object-fit: cover;
          border-radius: 100%;
          margin: 0 12px 0 0; }
        .page-authors-list .list-item .content .avatar {
          width: 64px;
          height: 64px;
          border-radius: 100%;
          margin: 0 12px 0 0; }
          .page-authors-list .list-item .content .avatar img {
            margin: 0; }
  .page-authors .newsletter-wrap {
    padding: 0 calc(10.35599vw + -18.83495px); }

.page-author .title {
  font-family: 'freight-display-pro', serif;
  font-size: calc(1.0356vw + 28.1165px);
  font-weight: 900;
  line-height: 1.1;
  margin: 0 0 32px; }

.page-author-profile {
  display: flex;
  gap: calc(1.94175vw + 16.71845px);
  margin: 0 0 44px;
  padding: 0 0 44px;
  border-bottom: 1px solid var(--secundary); }
  .page-author-profile .thumb {
    width: 50%;
    border: 1px solid var(--secundary); }
    .page-author-profile .thumb img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .page-author-profile .content {
    width: 80%; }
    .page-author-profile .content .container {
      display: flex;
      align-items: center;
      margin: 0 0 12px; }
      .page-author-profile .content .container .title {
        margin: 0 22px 0 0; }
    .page-author-profile .content .infos {
      font-size: calc(0.12945vw + 13.51456px);
      margin: 0 0 calc(0.7767vw + 17.08738px); }
      .page-author-profile .content .infos p {
        margin: 0 0 4px; }
      .page-author-profile .content .infos .email {
        background-image: url("../assets/mail_black.svg");
        background-repeat: no-repeat;
        background-position: left center;
        padding: 0 0 0 22px; }
    .page-author-profile .content .text {
      line-height: 1.8; }
  @media screen and (max-width: 1024px) {
    .page-author-profile {
      flex-direction: column; }
      .page-author-profile .thumb {
        width: 100%; }
        .page-author-profile .thumb img {
          aspect-ratio: 4/4; }
      .page-author-profile .content {
        width: 100%; } }

.page-author-posts {
  margin: 0 0 calc(2.8479vw + 31.32039px); }
  .page-author-posts .highlight {
    display: flex;
    gap: calc(1.8123vw + 21.20388px);
    border: 1px solid var(--secundary);
    margin: 0 0 44px;
    padding: calc(2.45955vw + 6.7767px) calc(1.68285vw + 9.68932px);
    background-color: #F0F5F4;
    position: relative; }
    .page-author-posts .highlight .thumb {
      position: relative; }
      .page-author-posts .highlight .thumb .tag {
        left: 50%;
        transform: translateX(-50%);
        bottom: calc(0.90615vw + 10.60194px);
        position: absolute;
        border: 1px solid var(--secundary);
        background-color: #B6CCC8;
        color: var(--secundary);
        z-index: 1; }
    .page-author-posts .highlight .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .page-author-posts .highlight .content h3 {
        font-size: calc(0.64725vw + 29.57282px);
        font-weight: 700;
        margin: 0 0 12px; }
      .page-author-posts .highlight .content > div {
        margin: 0 0 24px; }
      .page-author-posts .highlight .content .author-container {
        position: relative; }
        .page-author-posts .highlight .content .author-container img {
          width: 40px;
          height: 40px; }
    @media screen and (max-width: 1024px) {
      .page-author-posts .highlight {
        flex-direction: column; }
        .page-author-posts .highlight .thumb {
          width: 100%; }
          .page-author-posts .highlight .thumb img {
            width: 100%; }
        .page-author-posts .highlight .content {
          width: 100%; } }
  .page-author-posts .grid {
    display: grid;
    gap: 14px;
    grid-template-columns: repeat(4, 1fr);
    margin: 0 0 calc(1.8123vw + 21.20388px); }
    .page-author-posts .grid article {
      display: flex;
      flex-direction: column;
      padding: calc(0.2589vw + 15.02913px) 16px;
      border: 1px solid var(--secundary);
      position: relative; }
      .page-author-posts .grid article .thumb img {
        width: 100%;
        height: 220px;
        object-fit: cover; }
      .page-author-posts .grid article h3 {
        font-weight: 600;
        margin: 20px 0 40px; }
      .page-author-posts .grid article .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .page-author-posts .grid article .date {
        text-transform: none;
        font-size: 12px;
        margin: 0; }
        .page-author-posts .grid article .date span {
          text-transform: capitalize; }
      @media screen and (max-width: 1024px) {
        .page-author-posts .grid article {
          grid-column: span 2; } }
      @media screen and (max-width: 700px) {
        .page-author-posts .grid article {
          grid-column: span 4; }
          .page-author-posts .grid article .thumb {
            display: none; }
          .page-author-posts .grid article h3 {
            margin: 0 0 20px; } }
  .page-author-posts .seemore {
    font-size: 18px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 12px 80px;
    background-color: var(--secundary);
    color: var(--principal); }
    .page-author-posts .seemore img {
      margin-left: 12px; }

.page-author-writers {
  margin: 0 0 86px; }
  .page-author-writers .cards {
    display: grid;
    gap: calc(0.64725vw + 17.57282px) calc(1.42395vw + 14.66019px);
    grid-template-columns: repeat(3, 1fr); }
    .page-author-writers .cards article {
      position: relative;
      padding: calc(0.5178vw + 14.05825px) calc(0.2589vw + 15.02913px);
      border: 1px solid var(--secundary);
      background-color: #F0F5F4;
      flex-wrap: nowrap; }
      .page-author-writers .cards article a {
        display: block; }
      .page-author-writers .cards article figure img {
        min-width: calc(2.91262vw + 34.07767px);
        min-height: calc(2.91262vw + 34.07767px);
        object-fit: cover; }
      .page-author-writers .cards article img {
        width: calc(2.91262vw + 34.07767px);
        height: calc(2.91262vw + 34.07767px); }
        .page-author-writers .cards article img.arrow {
          border-radius: 0;
          position: absolute;
          right: 16px;
          bottom: 16px;
          width: calc(0.5178vw + 18.05825px);
          height: calc(0.5178vw + 18.05825px);
          margin: 0; }
      .page-author-writers .cards article .avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(2.91262vw + 34.07767px);
        height: calc(2.91262vw + 34.07767px);
        border-radius: 100%;
        margin: 0 12px 0 0; }
        .page-author-writers .cards article .avatar img {
          margin: 0; }
      @media screen and (max-width: 1024px) {
        .page-author-writers .cards article img:first-of-type {
          display: none; } }
      @media screen and (max-width: 700px) {
        .page-author-writers .cards article {
          grid-column: span 3; } }

.page-category h1 {
  font-family: 'freight-display-pro', serif;
  font-size: calc(2.97735vw + 28.83495px);
  font-weight: 900;
  line-height: 1.2;
  margin: 0 0 calc(1.5534vw + 34.17476px);
  text-align: center; }

.page-category .container {
  margin: 0 0 70px; }

.page-category-search form {
  position: relative; }
  .page-category-search form input {
    width: 100%;
    color: var(--secundary);
    background-color: #F0F5F4;
    border: 1px solid var(--secundary);
    padding: 20px 32px; }
    .page-category-search form input::-webkit-input-placeholder {
      font-style: italic;
      color: #8A999A; }
    .page-category-search form input::-moz-placeholder {
      font-style: italic;
      color: #8A999A; }
    .page-category-search form input:-ms-input-placeholder {
      font-style: italic;
      color: #8A999A; }
    .page-category-search form input:-moz-placeholder {
      font-style: italic;
      color: #8A999A; }
  .page-category-search form button {
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
    width: 34px; }

.page-category-highlight {
  display: flex;
  gap: 34px; }
  .page-category-highlight .box {
    width: 100%;
    border: 1px solid var(--secundary);
    padding: calc(1.5534vw + 10.17476px) calc(1.0356vw + 12.1165px);
    display: flex;
    position: relative; }
    .page-category-highlight .box .thumb {
      position: relative;
      width: 40%; }
      .page-category-highlight .box .thumb img {
        width: 100%;
        object-fit: cover;
        height: 100%; }
      .page-category-highlight .box .thumb .tag {
        white-space: nowrap;
        position: absolute;
        bottom: 16px;
        left: 50%;
        transform: translateX(-50%);
        border: 1px solid var(--secundary);
        background-color: #B6CCC8;
        color: var(--secundary);
        z-index: 1; }
    .page-category-highlight .box .content {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      border-left: 1px solid var(--secundary);
      padding-left: 36px;
      margin-left: 24px; }
      .page-category-highlight .box .content .title {
        font-family: 'freight-display-pro', serif;
        font-size: calc(1.0356vw + 28.1165px);
        font-weight: 600;
        line-height: 1.1;
        margin: 0 0 20px; }
      .page-category-highlight .box .content .resume {
        margin: 0 0 30px; }
      .page-category-highlight .box .content .author-container {
        position: relative; }
        .page-category-highlight .box .content .author-container img {
          width: 40px;
          height: 40px; }
    @media screen and (max-width: 700px) {
      .page-category-highlight .box {
        flex-direction: column; }
        .page-category-highlight .box .thumb {
          width: 100%; }
        .page-category-highlight .box .content {
          border: none;
          width: 100%;
          padding-left: 0;
          margin: 20px 0 0; } }
  .page-category-highlight .list {
    min-width: 330px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .page-category-highlight .list-title {
      padding: 0 0 12px;
      font-size: 32px;
      font-weight: 700;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      border-bottom: 1px solid var(--secundary); }
    .page-category-highlight .list-item {
      display: flex;
      gap: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 16px 10px;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.4;
      height: 100%;
      border-bottom: 1px dashed var(--secundary); }
    @media screen and (max-width: 1000px) {
      .page-category-highlight .list {
        max-width: 100%; } }
  @media screen and (max-width: 1024px) {
    .page-category-highlight {
      flex-direction: column; } }

.page-category-article {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px; }
  .page-category-article article {
    border: 1px solid var(--secundary);
    display: flex;
    flex-direction: column;
    position: relative; }
    .page-category-article article:first-of-type {
      display: none; }
    .page-category-article article .thumb {
      position: relative;
      min-width: 300px; }
      .page-category-article article .thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .page-category-article article .thumb .tag {
        position: absolute;
        bottom: 16px;
        left: 16px;
        border: 1px solid var(--secundary);
        background-color: #B6CCC8;
        color: var(--secundary);
        z-index: 1; }
    .page-category-article article .content {
      padding: calc(0.7767vw + 13.08738px) calc(0.5178vw + 14.05825px);
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: content-box;
      justify-content: space-between; }
      .page-category-article article .content > div {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        margin: 0 0 26px; }
      .page-category-article article .content h2 {
        font-family: 'freight-display-pro', serif;
        font-size: calc(0.5178vw + 22.05825px);
        font-weight: 900;
        line-height: 1.1; }
      .page-category-article article .content .link {
        align-self: flex-end; }
        .page-category-article article .content .link img {
          display: block; }
      .page-category-article article .content .author-container {
        margin: 0;
        position: relative; }
        .page-category-article article .content .author-container img {
          object-fit: cover;
          width: 40px;
          height: 40px; }
  @media screen and (max-width: 1024px) {
    .page-category-article {
      display: flex;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
      .page-category-article::-webkit-scrollbar {
        display: none; } }

.page-category-news-interviews {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 1fr); }
  .page-category-news-interviews article {
    padding: 14px;
    border: 1px solid var(--secundary);
    position: relative; }
    .page-category-news-interviews article a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%; }
    .page-category-news-interviews article img {
      display: block;
      width: 100%;
      height: 200px;
      object-fit: cover; }
      .page-category-news-interviews article img.aspas {
        display: none; }
    .page-category-news-interviews article h3 {
      line-height: 1.4;
      font-weight: 600;
      margin: 14px 0 16px; }
    .page-category-news-interviews article time {
      font-size: 12px; }
    .page-category-news-interviews article p {
      display: none;
      font-size: 12px; }
    .page-category-news-interviews article:first-of-type {
      padding: 40px 40px 15px 30px;
      border: 1px solid var(--secundary);
      grid-column: span 2;
      grid-row: span 2; }
      .page-category-news-interviews article:first-of-type a {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .page-category-news-interviews article:first-of-type time {
        display: none; }
      .page-category-news-interviews article:first-of-type img {
        width: 40px;
        margin: 0 0 16px;
        display: none; }
        .page-category-news-interviews article:first-of-type img.aspas {
          display: block;
          width: 50px;
          height: auto; }
      .page-category-news-interviews article:first-of-type h3 {
        margin: 0 0 46px;
        font-size: calc(2.0712vw + 24.23301px);
        font-weight: 700;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif; }
      .page-category-news-interviews article:first-of-type p {
        display: block;
        font-size: calc(0.12945vw + 15.51456px); }
      @media screen and (max-width: 1024px) {
        .page-category-news-interviews article:first-of-type {
          padding: 40px 40px 40px 30px; } }
  @media screen and (max-width: 1024px) {
    .page-category-news-interviews {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 700px) {
    .page-category-news-interviews {
      grid-template-columns: repeat(1, 1fr); }
      .page-category-news-interviews article {
        grid-column: span 1; }
        .page-category-news-interviews article img {
          display: none; }
        .page-category-news-interviews article:first-of-type {
          grid-column: span 1; }
        .page-category-news-interviews article:nth-child(2) img {
          display: block; }
          .page-category-news-interviews article:nth-child(2) img.aspas {
            display: none; } }

.page-category-news .btn {
  display: flex;
  gap: 14px;
  border: none;
  margin: 64px auto 0;
  background-color: var(--secundary);
  padding: 12px 88px; }
  .page-category-news .btn .icon::before, .page-category-news .btn .icon::after {
    background-color: var(--principal); }

.page-category-webstories-list {
  position: relative; }
  .page-category-webstories-list .list .item {
    position: relative;
    border: 1px solid var(--secundary);
    min-width: 224px;
    max-width: 224px;
    width: 100%;
    height: 485px; }
    .page-category-webstories-list .list .item a {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between; }
    .page-category-webstories-list .list .item img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .page-category-webstories-list .list .item .tag {
      position: absolute;
      top: 10px;
      right: 12px;
      color: var(--secundary);
      border: 1px solid var(--secundary);
      background-color: #B6CCC8; }
    .page-category-webstories-list .list .item .infos {
      background-color: var(--principal);
      color: var(--secundary);
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      padding: 10px 20px;
      width: 100%;
      border-top: 1px solid var(--secundary);
      min-height: 77px;
      font-weight: 600; }
  .page-category-webstories-list .btn {
    position: absolute;
    top: 55%;
    right: calc(-5.17799vw + -0.58252px);
    background-color: var(--secundary);
    color: #B6CCC8;
    border: none;
    padding: 20px 26px; }
  .page-category-webstories-list .list-nav {
    margin: 10px 0 0;
    width: 100%;
    display: flex;
    gap: 28px;
    align-items: center;
    justify-content: flex-end; }
    .page-category-webstories-list .list-nav-arrow {
      background-color: #000;
      width: 64px;
      height: 64px;
      transition: opacity .4s ease; }
      .page-category-webstories-list .list-nav-arrow img {
        width: 50%; }
      .page-category-webstories-list .list-nav-arrow:hover {
        opacity: .6; }
  @media screen and (max-width: 1024px) {
    .page-category-webstories-list {
      display: flex;
      flex-direction: column; }
      .page-category-webstories-list .btn {
        position: unset;
        margin: 40px 0 0;
        order: 3; }
      .page-category-webstories-list .list-nav {
        justify-content: flex-start; } }

.page-category-carousel {
  margin: 0 0 44px;
  position: relative; }
  .page-category-carousel .highlight {
    background-color: #F0F5F4; }
    .page-category-carousel .highlight-container {
      width: calc(100% - 1px);
      display: flex;
      gap: calc(1.8123vw + 21.20388px);
      border: 1px solid var(--secundary);
      padding: calc(2.45955vw + 6.7767px) calc(1.68285vw + 9.68932px); }
    .page-category-carousel .highlight .thumb {
      position: relative;
      width: 40%;
      height: 430px; }
      .page-category-carousel .highlight .thumb img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: cover; }
      .page-category-carousel .highlight .thumb .tag {
        left: 28px;
        bottom: 28px;
        position: absolute;
        color: var(--secundary);
        border: 1px solid var(--secundary);
        background-color: #B6CCC8; }
      @media screen and (max-width: 700px) {
        .page-category-carousel .highlight .thumb {
          width: 100%; } }
    .page-category-carousel .highlight .content {
      width: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .page-category-carousel .highlight .content h3 {
        font-size: calc(0.64725vw + 29.57282px);
        line-height: 1.2;
        font-weight: 700;
        margin: 0 0 calc(0.7767vw + 9.08738px); }
      @media screen and (max-width: 700px) {
        .page-category-carousel .highlight .content {
          width: 100%; } }
    .page-category-carousel .highlight .bottom {
      margin: 20px 0 0;
      display: flex;
      justify-content: space-between; }
      .page-category-carousel .highlight .bottom .author-container img {
        display: block;
        object-fit: cover;
        width: 40px;
        height: 40px; }
      @media screen and (max-width: 700px) {
        .page-category-carousel .highlight .bottom {
          flex-direction: column; } }
    @media screen and (max-width: 700px) {
      .page-category-carousel .highlight {
        flex-direction: column; } }
  .page-category-carousel .carousel-nav {
    display: flex;
    gap: 28px;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    bottom: calc(2.45955vw + 6.7767px);
    right: calc(1.68285vw + 9.68932px);
    z-index: 3; }
    .page-category-carousel .carousel-nav-arrow {
      background-color: #000;
      width: 64px;
      height: 64px; }
      .page-category-carousel .carousel-nav-arrow img {
        width: 50%; }
    .page-category-carousel .carousel-nav-prev {
      background-color: #8A999A; }
    @media screen and (max-width: 700px) {
      .page-category-carousel .carousel-nav {
        margin: 20px 0 0;
        justify-content: flex-start; } }

.page-about .title {
  font-size: calc(1.0356vw + 28.1165px);
  font-weight: 900;
  line-height: 1.2;
  font-family: 'freight-display-pro', serif; }

.page-about-banner {
  margin: 0 0 56px; }
  .page-about-banner img {
    display: block;
    width: 100%;
    height: auto; }

.page-about-row {
  margin: 0 0 36px;
  padding: calc(0.90615vw + 12.60194px) calc(1.94175vw + 8.71845px);
  border: 1px solid var(--secundary);
  display: grid;
  gap: calc(3.8835vw + 5.43689px);
  grid-template-columns: repeat(3, 1fr);
  align-items: center; }
  .page-about-row .title {
    font-size: 40px;
    min-width: 320px; }
  .page-about-row p {
    grid-column: span 2;
    position: relative; }
    .page-about-row p:before {
      content: '';
      position: absolute;
      height: calc(100% + 20px);
      width: 3px;
      background-color: var(--secundary);
      left: calc(-1.94175vw + -2.71845px);
      top: 50%;
      transform: translateY(-50%); }
  @media screen and (max-width: 1024px) {
    .page-about-row {
      grid-template-columns: repeat(1, 1fr); }
      .page-about-row p::before {
        display: none; } }

.page-about-list {
  margin: 0 0 64px; }
  .page-about-list ul {
    display: grid;
    gap: 28px;
    grid-template-columns: repeat(2, 1fr); }
    .page-about-list ul li {
      padding: calc(1.0356vw + 12.1165px) calc(0.90615vw + 12.60194px);
      border: 1px solid var(--secundary);
      background-color: #F0F5F4; }
      .page-about-list ul li h2 {
        margin: 0 0 20px;
        padding: 0 0 12px;
        border-bottom: 1px solid var(--secundary); }
      .page-about-list ul li:nth-child(3n) {
        grid-column: span 2; }
      @media screen and (max-width: 700px) {
        .page-about-list ul li {
          grid-column: span 2; } }

.page-about-columns {
  margin: 0 0 80px;
  padding: 0 calc(1.94175vw + -7.28155px); }
  .page-about-columns h2 {
    margin: 0 0 20px; }
  .page-about-columns p {
    margin: 0 0 40px; }
  .page-about-columns ul {
    display: grid;
    gap: 0 32px;
    grid-template-columns: repeat(3, 1fr); }
    .page-about-columns ul li {
      display: flex;
      align-items: center;
      padding: 28px 28px 28px 0;
      border-bottom: 1px solid var(--secundary); }
      .page-about-columns ul li:nth-child(1), .page-about-columns ul li:nth-child(2), .page-about-columns ul li:nth-child(3) {
        border-top: 1px solid var(--secundary); }
    @media screen and (max-width: 1024px) {
      .page-about-columns ul {
        grid-template-columns: repeat(1, 1fr); }
        .page-about-columns ul li:nth-child(1), .page-about-columns ul li:nth-child(2), .page-about-columns ul li:nth-child(3) {
          border-top: none; } }

.page-about-team {
  margin: 0 0 80px; }
  .page-about-team h2 {
    margin: 0 0 20px; }
  .page-about-team .list {
    display: flex;
    gap: 38px calc(1.2945vw + 13.14563px);
    margin: 0 0 80px;
    flex-wrap: wrap;
    justify-content: space-between; }
    .page-about-team .list li {
      width: 30%; }
    .page-about-team .list img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 0 50px; }
    .page-about-team .list h3 {
      font-weight: 700; }
    .page-about-team .list h3, .page-about-team .list h4 {
      font-size: 22px; }
    .page-about-team .list p {
      font-size: 18px;
      margin: 24px 0 0; }
    @media screen and (max-width: 1024px) {
      .page-about-team .list li {
        width: 45%; } }
    @media screen and (max-width: 700px) {
      .page-about-team .list {
        flex-direction: column; }
        .page-about-team .list li {
          width: 100%; } }
  .page-about-team .grid {
    display: grid;
    gap: 24px calc(2.0712vw + 16.23301px);
    grid-template-columns: repeat(4, 1fr); }
    .page-about-team .grid li {
      padding: 10px 14px;
      border: 1px solid var(--secundary);
      background-color: #F0F5F4; }
      .page-about-team .grid li img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 14px; }
      .page-about-team .grid li h3 {
        font-weight: 700; }
    @media screen and (max-width: 1024px) {
      .page-about-team .grid {
        grid-template-columns: repeat(3, 1fr); } }
    @media screen and (max-width: 700px) {
      .page-about-team .grid {
        grid-template-columns: repeat(2, 1fr); } }

.page-about-area {
  margin: 0 0 calc(3.8835vw + 45.43689px); }
  .page-about-area h2 {
    margin: 0 0 20px;
    max-width: 80%; }
    @media screen and (max-width: 1024px) {
      .page-about-area h2 {
        grid-template-columns: repeat(3, 1fr);
        max-width: 100%; } }
  .page-about-area .subtitle {
    margin: 0 0 28px;
    max-width: 80%; }
    @media screen and (max-width: 1024px) {
      .page-about-area .subtitle {
        grid-template-columns: repeat(3, 1fr);
        max-width: 100%; } }
  .page-about-area .support {
    font-size: 18px;
    font-weight: 700;
    padding: calc(0.90615vw + 12.60194px) calc(2.20065vw + 25.74757px) calc(0.90615vw + 12.60194px) calc(1.8123vw + 9.20388px);
    border: 1px solid var(--secundary);
    display: flex;
    position: relative;
    width: fit-content; }
    .page-about-area .support p {
      border-bottom: 2px solid var(--secundary); }
    .page-about-area .support .arrow {
      width: 44px;
      height: 44px;
      display: block;
      background-color: var(--secundary);
      padding: 10px;
      position: absolute;
      top: 50%;
      right: -24px;
      transform: translateY(-50%); }
      .page-about-area .support .arrow img {
        max-width: 24px; }
    @media screen and (max-width: 1024px) {
      .page-about-area .support {
        max-width: 90%; } }
  .page-about-area .cards {
    margin: 64px 0 0;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between; }
    .page-about-area .cards li {
      background-color: #B6CCC8;
      border: 1px solid var(--secundary);
      padding: calc(0.7767vw + 13.08738px) calc(0.90615vw + 12.60194px) calc(2.20065vw + 7.74757px) calc(0.7767vw + 13.08738px); }
      .page-about-area .cards li img {
        margin: 0 0 24px;
        display: block;
        max-width: 100%; }
      .page-about-area .cards li h2 {
        margin: 0 0 20px; }
    @media screen and (max-width: 700px) {
      .page-about-area .cards {
        grid-template-columns: repeat(1, 1fr); } }

.page-about-box {
  margin: 0 0 80px; }
  .page-about-box h2 {
    margin: 0 0 20px; }
  .page-about-box p {
    margin: 0 0 20px; }
  .page-about-box img {
    display: block;
    width: 100%;
    margin: 40px 0 0; }
  .page-about-box .content {
    max-width: 80%; }
    @media screen and (max-width: 1024px) {
      .page-about-box .content {
        max-width: 100%; } }

.page-opendata .title {
  text-align: center;
  font-size: calc(3.23625vw + 23.86408px);
  font-weight: 900;
  line-height: 1.1;
  font-family: 'freight-display-pro', serif;
  margin: 0 0 34px; }

.page-opendata .subtitle {
  text-align: center;
  font-size: calc(0.38835vw + 16.54369px);
  margin: 0 0 90px;
  padding: 0 calc(3.8835vw + 5.43689px); }

.page-opendata section {
  margin: 0 0 calc(3.75405vw + 43.92233px); }
  .page-opendata section h2 {
    font-size: calc(0.5178vw + 18.05825px);
    font-weight: 900;
    line-height: 1.2;
    font-family: 'freight-display-pro', serif;
    margin: 0 0 34px; }
  .page-opendata section ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 34px 12px; }
    .page-opendata section ul li {
      display: flex;
      flex-direction: column; }
      .page-opendata section ul li .resume {
        font-size: calc(0.2589vw + 15.02913px);
        padding: 24px;
        border: 1px solid var(--secundary);
        margin: 0 0 14px;
        height: 100%;
        position: relative; }
      .page-opendata section ul li .bases {
        display: flex;
        flex-wrap: wrap;
        gap: 10px; }
      .page-opendata section ul li .tag {
        padding: 8px 20px; }
    @media screen and (max-width: 1024px) {
      .page-opendata section ul {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 700px) {
      .page-opendata section ul {
        grid-template-columns: repeat(1, 1fr); } }

.page-webstories-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 calc(2.91262vw + 34.07767px); }
  .page-webstories-header .title {
    font-size: calc(3.23625vw + 23.86408px);
    font-weight: 900;
    line-height: 1.2;
    font-family: 'freight-display-pro', serif; }
    @media screen and (max-width: 700px) {
      .page-webstories-header .title {
        margin: 0 0 20px; } }
  .page-webstories-header .filter {
    min-width: 255px;
    padding: 14px 28px;
    background-color: var(--secundary);
    color: var(--principal);
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .page-webstories-header .filter span, .page-webstories-header .filter img {
      display: block; }
  @media screen and (max-width: 700px) {
    .page-webstories-header {
      align-items: flex-start;
      flex-direction: column; } }

.page-webstories-container .grid {
  display: grid;
  gap: calc(1.2945vw + 5.14563px) calc(3.23625vw + -2.13592px);
  grid-template-columns: repeat(4, 1fr); }
  .page-webstories-container .grid article {
    border: 1px solid var(--secundary); }
    .page-webstories-container .grid article a {
      display: block;
      transition: opacity .4s ease;
      height: 100%; }
      .page-webstories-container .grid article a:hover {
        opacity: .6; }
    .page-webstories-container .grid article .thumb img {
      width: 100%;
      height: 480px;
      object-fit: cover; }
    .page-webstories-container .grid article .title {
      padding: calc(0.90615vw + 10.60194px) 14px;
      line-height: 1.4;
      font-weight: 600; }
  @media screen and (max-width: 1024px) {
    .page-webstories-container .grid {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 700px) {
    .page-webstories-container .grid {
      grid-template-columns: repeat(1, 1fr); }
      .page-webstories-container .grid article .thumb img {
        height: 320px; } }

.single-colecoes {
  --bg: #F5A38A;
  --text: #000000;
  --text-light: #ffffff;
  --box: #C75936;
  --highlight: #ED8A57;
  --highlight-text: #913838;
  --highlight-details: #E3E382;
  --highlight-bg: #DE8C8C;
  background-color: #B6CCC8; }
  .single-colecoes .i4ewOd-pzNkMb-haAclf {
    display: none !important; }
  .single-colecoes .header {
    background-color: #B6CCC8; }
  .single-colecoes .container {
    margin: 0 0 80px;
    padding: 0 calc(10.35599vw + -18.83495px); }
  .single-colecoes .text-container h1, .single-colecoes .text-container h2, .single-colecoes .text-container h3, .single-colecoes .text-container h4, .single-colecoes .text-container h5, .single-colecoes .text-container h6 {
    margin: 0 0 10px;
    font-weight: 700;
    line-height: 1.2; }
  .single-colecoes .text-container ul {
    list-style-type: disc;
    margin: 10px 0;
    padding-left: 20px; }
  .single-colecoes .text-container ol {
    list-style-type: decimal;
    margin: 10px 0;
    padding-left: 20px; }
  .single-colecoes .text-container a {
    text-decoration: underline; }
  .single-colecoes .text-container blockquote {
    border-left: 6px solid #000;
    padding-left: 20px;
    font-size: 120%;
    margin: 10px 0; }
  .single-colecoes .text-container p {
    margin: 10px 0; }
  .single-colecoes .collection-wrap {
    padding: 0 0 calc(1.94175vw + 52.71845px); }
  .single-colecoes .collection-banner {
    position: relative;
    min-height: 50vh;
    padding: 0;
    margin: 0; }
    .single-colecoes .collection-banner img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    .single-colecoes .collection-banner .title-container {
      width: 100%;
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 90%;
      transform: translate(-50%, -50%); }
    .single-colecoes .collection-banner h1 {
      width: 100%;
      font-size: calc(1.0356vw + 28.1165px);
      font-weight: 800;
      line-height: 1.6;
      position: absolute;
      top: 100%;
      transform: translateY(-100%);
      width: fit-content; }
      .single-colecoes .collection-banner h1.big {
        line-height: 1.4;
        font-size: calc(7.76699vw + 90.87379px); }
        .single-colecoes .collection-banner h1.big.lineHeight {
          line-height: 0.9; }
      .single-colecoes .collection-banner h1.freight {
        font-family: 'freight-display-pro', serif; }
      .single-colecoes .collection-banner h1.adelle {
        font-family: 'adelle-sans', sans-serif; }
      .single-colecoes .collection-banner h1.top {
        top: 0%;
        transform: translateY(0%); }
      .single-colecoes .collection-banner h1.bottom {
        transform: translateY(-100%); }
        .single-colecoes .collection-banner h1.bottom.center {
          transform: translate(-50%, -100%); }
      .single-colecoes .collection-banner h1.center_vertical {
        top: 50%;
        transform: translateY(-50%); }
        .single-colecoes .collection-banner h1.center_vertical.center {
          transform: translate(-50%, -50%); }
      .single-colecoes .collection-banner h1.center {
        left: 50%;
        transform: translateX(-50%);
        text-align: center; }
      .single-colecoes .collection-banner h1.right {
        right: 0%;
        text-align: right; }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-banner {
        min-height: 50vh; }
        .single-colecoes .collection-banner figure {
          height: 50vh; }
        .single-colecoes .collection-banner img {
          height: 100%;
          object-fit: cover; } }
  .single-colecoes .collection-text.bg {
    padding: 0; }
    .single-colecoes .collection-text.bg .collection-text-content {
      padding: calc(1.94175vw + 22.71845px) calc(10.35599vw + -18.83495px);
      background-color: #fff; }
  .single-colecoes .collection-text.box .collection-text-content {
    padding: calc(2.589vw + 30.29126px);
    background-color: #fff; }
  .single-colecoes .collection-text h2 {
    font-weight: 900;
    line-height: 1.2;
    font-family: 'freight-display-pro', serif;
    font-size: calc(1.0356vw + 28.1165px);
    margin: 0 0 32px; }
    .single-colecoes .collection-text h2.freight {
      font-family: 'freight-display-pro', serif; }
    .single-colecoes .collection-text h2.adelle {
      font-family: 'adelle-sans', sans-serif; }
    .single-colecoes .collection-text h2.big {
      font-size: 32px; }
    .single-colecoes .collection-text h2.small {
      font-size: 28px; }
  .single-colecoes .collection-text .text.xlarge p, .single-colecoes .collection-text .text.xlarge a, .single-colecoes .collection-text .text.xlarge ul li, .single-colecoes .collection-text .text.xlarge ol li, .single-colecoes .collection-text .text.xlarge h1, .single-colecoes .collection-text .text.xlarge h2, .single-colecoes .collection-text .text.xlarge h3, .single-colecoes .collection-text .text.xlarge h4, .single-colecoes .collection-text .text.xlarge h5, .single-colecoes .collection-text .text.xlarge h6, .single-colecoes .collection-text .text.xlarge strong {
    font-size: calc(7.76699vw + 90.87379px);
    line-height: 0.9;
    font-weight: 900; }
  .single-colecoes .collection-text .text.big p, .single-colecoes .collection-text .text.big a, .single-colecoes .collection-text .text.big ul li, .single-colecoes .collection-text .text.big ol li, .single-colecoes .collection-text .text.big h1, .single-colecoes .collection-text .text.big h2, .single-colecoes .collection-text .text.big h3, .single-colecoes .collection-text .text.big h4, .single-colecoes .collection-text .text.big h5, .single-colecoes .collection-text .text.big h6 {
    font-size: 26px; }
  .single-colecoes .collection-text .text.small p, .single-colecoes .collection-text .text.small a, .single-colecoes .collection-text .text.small ul li, .single-colecoes .collection-text .text.small ol li, .single-colecoes .collection-text .text.small h1, .single-colecoes .collection-text .text.small h2, .single-colecoes .collection-text .text.small h3, .single-colecoes .collection-text .text.small h4, .single-colecoes .collection-text .text.small h5, .single-colecoes .collection-text .text.small h6 {
    font-size: 18px; }
  .single-colecoes .collection-text .text code {
    clear: both;
    margin: 5px 20px 0 0;
    padding: 0 0 4px;
    float: left;
    font-family: inherit;
    font-size: 600%;
    line-height: .8;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: 10px solid #B6CCC8; }
  .single-colecoes .collection-text .text h1, .single-colecoes .collection-text .text h2, .single-colecoes .collection-text .text h3, .single-colecoes .collection-text .text h4, .single-colecoes .collection-text .text h5, .single-colecoes .collection-text .text h6, .single-colecoes .collection-text .text p, .single-colecoes .collection-text .text ul, .single-colecoes .collection-text .text ol, .single-colecoes .collection-text .text a {
    line-height: 1.3;
    font-weight: 300;
    margin: 0 0 20px;
    font-size: calc(0.5178vw + 22.05825px); }
  .single-colecoes .collection-text .text h1, .single-colecoes .collection-text .text h2, .single-colecoes .collection-text .text h3, .single-colecoes .collection-text .text h4, .single-colecoes .collection-text .text h5, .single-colecoes .collection-text .text h6 {
    margin: 0 0 10px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
  .single-colecoes .collection-text .text a {
    text-decoration: underline; }
  .single-colecoes .collection-text .text ul {
    list-style-type: disc;
    padding: 0 0 0 20px; }
  .single-colecoes .collection-text .text ol {
    list-style-type: decimal;
    padding: 0 0 0 20px; }
  .single-colecoes .collection-text .text p {
    line-height: 1.5; }
  .single-colecoes .collection-text .text.freight {
    font-family: 'freight-display-pro', serif; }
  .single-colecoes .collection-text .text.adelle {
    font-family: 'adelle-sans', sans-serif; }
  .single-colecoes .collection-tabs-title {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
  .single-colecoes .collection-tabs-wrapper {
    display: flex;
    gap: 30px; }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-tabs-wrapper {
        flex-direction: column; } }
  .single-colecoes .collection-tabs-navigation {
    display: flex;
    gap: 12px;
    flex-direction: column; }
    .single-colecoes .collection-tabs-navigation button {
      padding: 16px;
      width: 200px;
      display: block;
      background-color: #000;
      color: #fff;
      text-align: left;
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 800;
      border-bottom-left-radius: 8px; }
      .single-colecoes .collection-tabs-navigation button:nth-child(even) {
        border-top-right-radius: 8px;
        border-bottom-left-radius: 0; }
      .single-colecoes .collection-tabs-navigation button.active {
        cursor: default;
        pointer-events: none;
        background-color: #fff;
        color: var(--text); }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-tabs-navigation {
        justify-content: flex-start;
        flex-direction: row; }
        .single-colecoes .collection-tabs-navigation button {
          background-color: transparent;
          border-bottom: 8px solid var(--text-light);
          border-radius: 0;
          text-indent: -999999999px;
          max-width: 20px; }
          .single-colecoes .collection-tabs-navigation button.active {
            text-indent: 0;
            max-width: 200px;
            background-color: transparent;
            border-bottom: 8px solid var(--text); } }
  .single-colecoes .collection-tabs-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    background-color: #fff;
    border-bottom-right-radius: 20px;
    overflow: hidden; }
    .single-colecoes .collection-tabs-content-item {
      display: none;
      color: var(--text);
      padding: calc(1.35922vw + 15.90291px) calc(2.65372vw + 31.04854px); }
      .single-colecoes .collection-tabs-content-item img {
        display: block;
        width: 100%;
        height: auto; }
      .single-colecoes .collection-tabs-content-item h1, .single-colecoes .collection-tabs-content-item h2, .single-colecoes .collection-tabs-content-item h3, .single-colecoes .collection-tabs-content-item h4, .single-colecoes .collection-tabs-content-item h5, .single-colecoes .collection-tabs-content-item h6 {
        font-size: 22px;
        margin: 0 0 10px; }
      .single-colecoes .collection-tabs-content-item p {
        margin: 0 0 10px; }
      .single-colecoes .collection-tabs-content-item ul {
        list-style-type: disc;
        padding-left: 20px;
        margin: 0 0 10px; }
      .single-colecoes .collection-tabs-content-item ol {
        list-style-type: decimal;
        padding-left: 20px;
        margin: 0 0 10px; }
      .single-colecoes .collection-tabs-content-item a {
        text-decoration: underline; }
      .single-colecoes .collection-tabs-content-item.active {
        display: block; }
  .single-colecoes .collection-tabs.horizontal .collection-tabs-wrapper {
    flex-direction: column; }
    .single-colecoes .collection-tabs.horizontal .collection-tabs-wrapper .collection-tabs-navigation {
      flex-direction: row;
      justify-content: flex-start; }
      .single-colecoes .collection-tabs.horizontal .collection-tabs-wrapper .collection-tabs-navigation button {
        border-radius: 0;
        background-color: transparent;
        color: #000;
        border-bottom: 8px solid #000; }
        .single-colecoes .collection-tabs.horizontal .collection-tabs-wrapper .collection-tabs-navigation button.active {
          color: #fff;
          border-bottom: 8px solid #fff; }
  .single-colecoes .collection-highlight-row {
    padding: calc(1.42395vw + 14.66019px);
    display: grid;
    grid-template-columns: 35% 25% 25%;
    gap: 34px;
    color: var(--text);
    background-color: var(--text-light); }
    .single-colecoes .collection-highlight-row figure a {
      display: block;
      width: 100%;
      height: 100%; }
    .single-colecoes .collection-highlight-row figure img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0 0 20px 0; }
    .single-colecoes .collection-highlight-row h3 {
      margin: 0 0 10px;
      font-size: calc(0.5178vw + 22.05825px);
      font-weight: 900;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif; }
    .single-colecoes .collection-highlight-row .resume p b {
      font-weight: 800; }
    @media screen and (max-width: 1024px) {
      .single-colecoes .collection-highlight-row {
        grid-template-columns: 100%; } }
  .single-colecoes .collection-quote blockquote {
    padding: 50px calc(1.2945vw + 43.14563px);
    background-color: var(--text-light);
    color: var(--text);
    display: flex;
    align-items: flex-start;
    gap: 24px; }
    .single-colecoes .collection-quote blockquote img {
      display: block;
      width: calc(2.589vw + 50.29126px); }
    .single-colecoes .collection-quote blockquote .highlight {
      font-family: 'freight-display-pro', serif;
      margin: 0 0 20px; }
      .single-colecoes .collection-quote blockquote .highlight p {
        width: 100%;
        font-size: calc(0.5178vw + 30.05825px);
        font-weight: 900;
        line-height: 1.1; }
    @media screen and (max-width: 700px) {
      .single-colecoes .collection-quote blockquote {
        flex-direction: column; } }
  .single-colecoes .collection-tag div {
    background-color: #fff;
    border-radius: 0 8px 0 8px;
    padding: 18px 28px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    font-size: calc(0.12945vw + 13.51456px); }
    .single-colecoes .collection-tag div p {
      font-weight: 700; }
    .single-colecoes .collection-tag div a {
      display: flex;
      gap: 10px;
      align-items: center;
      text-decoration: underline; }
      .single-colecoes .collection-tag div a img {
        width: 20px; }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-tag div {
        flex-direction: column;
        align-items: flex-start; } }
  .single-colecoes .collection-accordion-content {
    display: flex;
    gap: 28px;
    justify-content: space-between;
    color: var(--text); }
    .single-colecoes .collection-accordion-content .title {
      padding: calc(0.7767vw + 25.08738px) calc(0.2589vw + 27.02913px);
      color: var(--text-light);
      background-color: var(--text);
      font-size: calc(0.2589vw + 27.02913px);
      font-weight: 900;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      border-top-left-radius: 20px;
      max-width: 304px; }
    .single-colecoes .collection-accordion-content .accordion {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%; }
      .single-colecoes .collection-accordion-content .accordion details {
        color: var(--text-light);
        background-color: var(--text);
        padding: 10px 28px; }
        .single-colecoes .collection-accordion-content .accordion details summary {
          font-size: 20px;
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between; }
          .single-colecoes .collection-accordion-content .accordion details summary::-webkit-details-marker {
            display: none; }
          .single-colecoes .collection-accordion-content .accordion details summary .arrow {
            display: block;
            width: 16px;
            transform: scaleY(-1); }
            .single-colecoes .collection-accordion-content .accordion details summary .arrow path {
              fill: var(--text-light); }
        .single-colecoes .collection-accordion-content .accordion details .text {
          margin: 12px 0 0;
          padding-right: 82px; }
          .single-colecoes .collection-accordion-content .accordion details .text img {
            display: block;
            width: 100%;
            height: auto; }
          .single-colecoes .collection-accordion-content .accordion details .text h1, .single-colecoes .collection-accordion-content .accordion details .text h2, .single-colecoes .collection-accordion-content .accordion details .text h3, .single-colecoes .collection-accordion-content .accordion details .text h4, .single-colecoes .collection-accordion-content .accordion details .text h5, .single-colecoes .collection-accordion-content .accordion details .text h6 {
            font-size: 22px;
            margin: 0 0 10px; }
          .single-colecoes .collection-accordion-content .accordion details .text p {
            margin: 0 0 10px; }
          .single-colecoes .collection-accordion-content .accordion details .text ul {
            list-style-type: disc;
            padding-left: 20px;
            margin: 0 0 10px; }
          .single-colecoes .collection-accordion-content .accordion details .text ol {
            list-style-type: decimal;
            padding-left: 20px;
            margin: 0 0 10px; }
          .single-colecoes .collection-accordion-content .accordion details .text a {
            text-decoration: underline; }
        .single-colecoes .collection-accordion-content .accordion details[open] {
          padding: 18px 28px;
          color: var(--text);
          background-color: var(--text-light); }
          .single-colecoes .collection-accordion-content .accordion details[open] .arrow {
            transform: scaleY(1); }
            .single-colecoes .collection-accordion-content .accordion details[open] .arrow path {
              fill: var(--text); }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-accordion-content {
        flex-direction: column; }
        .single-colecoes .collection-accordion-content .title {
          max-width: 100%; } }
  .single-colecoes .collection-posts .list {
    display: grid;
    gap: calc(1.2945vw + 15.14563px);
    grid-template-columns: repeat(3, 1fr); }
    .single-colecoes .collection-posts .list .item {
      padding: 34px 28px;
      background-color: var(--text-light);
      color: var(--text);
      border-radius: 0 20px 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .single-colecoes .collection-posts .list .item figure {
        aspect-ratio: 1/1;
        width: 100%;
        position: relative;
        margin: 0 0 16px; }
        .single-colecoes .collection-posts .list .item figure img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover; }
        .single-colecoes .collection-posts .list .item figure figcaption {
          position: absolute;
          padding: 8px 18px;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          top: 16px;
          left: 18px;
          background-color: var(--text);
          color: var(--text-light); }
      .single-colecoes .collection-posts .list .item .text {
        margin: 0 0 38px;
        font-size: calc(0.38835vw + 16.54369px);
        font-weight: 900;
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        padding: 0 40px 0 0; }
      .single-colecoes .collection-posts .list .item .author {
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .single-colecoes .collection-posts .list .item .author p {
          margin: 0; }
        .single-colecoes .collection-posts .list .item .author a {
          display: block; }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-posts .list {
        grid-template-columns: repeat(2, 1fr); } }
    @media screen and (max-width: 700px) {
      .single-colecoes .collection-posts .list {
        grid-template-columns: repeat(1, 1fr); } }
  .single-colecoes .collection-percent {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .single-colecoes .collection-percent .content {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px; }
      .single-colecoes .collection-percent .content h2 {
        font-size: calc(7.76699vw + 90.87379px);
        font-weight: 900;
        line-height: 1;
        font-family: 'freight-display-pro', serif; }
      .single-colecoes .collection-percent .content .text {
        max-width: 400px;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif;
        font-size: calc(0.2589vw + 27.02913px);
        padding: 40px 0;
        position: relative; }
        .single-colecoes .collection-percent .content .text strong, .single-colecoes .collection-percent .content .text b {
          font-weight: 700; }
        .single-colecoes .collection-percent .content .text:before, .single-colecoes .collection-percent .content .text:after {
          content: '';
          position: absolute;
          width: 100px;
          height: 8px;
          background-color: var(--text);
          left: 0; }
        .single-colecoes .collection-percent .content .text:before {
          top: 0; }
        .single-colecoes .collection-percent .content .text:after {
          bottom: 0; }
        @media screen and (max-width: 700px) {
          .single-colecoes .collection-percent .content .text {
            text-align: center; }
            .single-colecoes .collection-percent .content .text:before, .single-colecoes .collection-percent .content .text:after {
              left: 50%;
              transform: translateX(-50%); } }
      @media screen and (max-width: 700px) {
        .single-colecoes .collection-percent .content {
          flex-direction: column; }
          .single-colecoes .collection-percent .content h2 {
            margin: 0 0 20px; } }
    .single-colecoes .collection-percent a {
      display: block;
      margin: 42px 0 0;
      padding: 12px 40px;
      font-weight: 700;
      color: #fff;
      background-color: #000;
      width: fit-content; }
  .single-colecoes .collection-carouselimage .swiper-slide {
    width: fit-content !important; }
  .single-colecoes .collection-carouselimage-content {
    display: flex;
    gap: 8px;
    border-radius: 0 20px 0 0;
    overflow: hidden;
    position: relative; }
    .single-colecoes .collection-carouselimage-content .slide {
      height: 100%; }
      .single-colecoes .collection-carouselimage-content .slide div {
        height: 520px; }
      .single-colecoes .collection-carouselimage-content .slide-item {
        position: relative;
        height: 100%; }
        .single-colecoes .collection-carouselimage-content .slide-item img {
          display: block;
          max-width: 100%;
          height: 520px;
          object-fit: cover; }
        .single-colecoes .collection-carouselimage-content .slide-item .caption {
          position: absolute;
          color: #fff;
          font-size: 14px;
          bottom: 20px;
          left: 20px;
          max-width: 90%; }
          .single-colecoes .collection-carouselimage-content .slide-item .caption span {
            background-color: #000;
            padding: 0 5px; }
    .single-colecoes .collection-carouselimage-content .arrows {
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      background-color: var(--text);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      width: 40px;
      z-index: 1; }
      .single-colecoes .collection-carouselimage-content .arrows button {
        width: 26px;
        display: block; }
        .single-colecoes .collection-carouselimage-content .arrows button img {
          display: block;
          width: 100%; }
    @media screen and (max-width: 1024px) {
      .single-colecoes .collection-carouselimage-content {
        flex-direction: column; }
        .single-colecoes .collection-carouselimage-content .arrows {
          background-color: transparent;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          height: auto;
          width: 100%;
          top: 50%;
          transform: translateY(-50%); }
          .single-colecoes .collection-carouselimage-content .arrows button {
            background-color: #000;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center; }
            .single-colecoes .collection-carouselimage-content .arrows button img {
              max-width: 60%; } }
  .single-colecoes .collection-webstories-content {
    display: flex;
    gap: 28px;
    border-radius: 0 20px 0 0;
    overflow: hidden;
    justify-content: space-between;
    position: relative; }
    .single-colecoes .collection-webstories-content .slide {
      height: 100%;
      width: calc(100% - 80px); }
      .single-colecoes .collection-webstories-content .slide-item {
        height: 600px;
        position: relative;
        min-width: 300px;
        max-width: 300px;
        padding: 0 28px 0 0;
        overflow: hidden; }
        .single-colecoes .collection-webstories-content .slide-item .content {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          height: 100%;
          border-radius: 0 0 20px 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          overflow: hidden; }
          .single-colecoes .collection-webstories-content .slide-item .content h2 {
            padding: 24px;
            background-color: var(--text);
            color: var(--text-light);
            font-size: 24px;
            font-weight: 900;
            line-height: 1.2;
            font-family: 'freight-display-pro', serif;
            text-decoration: underline; }
        @media screen and (max-width: 1000px) {
          .single-colecoes .collection-webstories-content .slide-item {
            width: 40%; } }
        @media screen and (max-width: 700px) {
          .single-colecoes .collection-webstories-content .slide-item {
            width: 100%; } }
    .single-colecoes .collection-webstories-content .arrows {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: 1; }
      .single-colecoes .collection-webstories-content .arrows button {
        width: 26px;
        background-color: var(--text);
        width: calc(1.2945vw + 35.14563px);
        height: calc(1.2945vw + 35.14563px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .single-colecoes .collection-webstories-content .arrows button.slick-hidden {
          display: none; }
        .single-colecoes .collection-webstories-content .arrows button img {
          display: block;
          width: 20px; }
  .single-colecoes .collection-tag02-content {
    width: 80%;
    position: relative; }
    .single-colecoes .collection-tag02-content .container {
      display: flex;
      align-items: center;
      width: calc(100% - 125px);
      padding: 28px 80px 28px 30px;
      border: 1px solid var(--secundary);
      background-color: #F0F5F4; }
      .single-colecoes .collection-tag02-content .container h2 {
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        font-size: 36px;
        font-weight: 700; }
    .single-colecoes .collection-tag02-content .btn {
      position: absolute;
      right: 20%;
      top: 50%;
      color: #F0F5F4;
      transform: translate(100%, -50%);
      border: 1px solid var(--secundary);
      background-color: var(--secundary);
      transition: opacity .4s ease; }
      .single-colecoes .collection-tag02-content .btn:hover {
        opacity: .8; }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-tag02-content {
        width: 100%; }
        .single-colecoes .collection-tag02-content .container {
          width: 100%;
          padding: 28px 30px calc(28px + 35px); }
          .single-colecoes .collection-tag02-content .container p {
            max-width: 100%; }
        .single-colecoes .collection-tag02-content .btn {
          right: auto;
          top: auto;
          left: 50%;
          bottom: -45px;
          transform: translateX(-50%);
          width: calc(100% - 40px); } }
  .single-colecoes .collection-image h2 {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
  .single-colecoes .collection-image figure {
    position: relative; }
  .single-colecoes .collection-image img {
    display: block;
    width: 100%;
    height: auto; }
  .single-colecoes .collection-image .caption {
    position: absolute;
    color: #fff;
    font-size: 14px;
    bottom: 20px;
    left: 20px;
    max-width: 90%;
    background-color: #000;
    padding: 0 5px; }
  .single-colecoes .collection-video h2 {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
  .single-colecoes .collection-video .video {
    position: relative;
    padding-top: 56.25%; }
    .single-colecoes .collection-video .video iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .single-colecoes .collection-testimony-content {
    display: flex;
    gap: 30px;
    align-items: center;
    justify-content: center; }
    .single-colecoes .collection-testimony-content figure {
      min-width: calc(6.47249vw + 275.72816px);
      width: calc(6.47249vw + 275.72816px);
      height: calc(6.47249vw + 275.72816px);
      display: block;
      overflow: hidden;
      border-radius: 0 40px 0 40px; }
      .single-colecoes .collection-testimony-content figure img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
    .single-colecoes .collection-testimony-content h2 {
      font-weight: 900;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      font-size: calc(0.5178vw + 22.05825px);
      margin: 0 0 40px; }
    .single-colecoes .collection-testimony-content div {
      width: 50%; }
    @media screen and (max-width: 700px) {
      .single-colecoes .collection-testimony-content {
        flex-direction: column;
        align-items: flex-start; }
        .single-colecoes .collection-testimony-content div {
          width: 100%; } }
  .single-colecoes .collection-map h2 {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
  .single-colecoes .collection-map-content {
    width: 100%;
    height: 100%;
    overflow: hidden; }
    .single-colecoes .collection-map-content iframe {
      margin-top: -70px;
      width: 100%; }
  .single-colecoes .collection-callpost .calllist {
    display: flex;
    gap: 20px; }
    @media screen and (max-width: 1024px) {
      .single-colecoes .collection-callpost .calllist {
        flex-direction: column; } }
    .single-colecoes .collection-callpost .calllist-item {
      width: 100%;
      height: 60vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 40px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;
      position: relative; }
      .single-colecoes .collection-callpost .calllist-item h2 {
        width: calc(100% - 48px);
        font-size: calc(1.0356vw + 28.1165px);
        font-weight: 800;
        line-height: 1.5; }
        .single-colecoes .collection-callpost .calllist-item h2.small {
          font-size: calc(0.38835vw + 16.54369px); }
        .single-colecoes .collection-callpost .calllist-item h2.medium {
          font-size: calc(0.5178vw + 22.05825px); }
        .single-colecoes .collection-callpost .calllist-item h2.big {
          font-size: calc(1.0356vw + 28.1165px); }
      .single-colecoes .collection-callpost .calllist-item.center {
        align-items: center;
        text-align: center; }
      .single-colecoes .collection-callpost .calllist-item.centervertical h2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .single-colecoes .collection-callpost .calllist-item.right {
        align-items: flex-end;
        text-align: right; }
      .single-colecoes .collection-callpost .calllist-item.top h2 {
        position: absolute;
        top: 40px; }
      .single-colecoes .collection-callpost .calllist-item.freight .tag {
        font-family: 'freight-display-pro', serif;
        font-weight: 800; }
      .single-colecoes .collection-callpost .calllist-item.freight h2 a {
        font-family: 'freight-display-pro', serif; }
      .single-colecoes .collection-callpost .calllist-item.adelle .tag {
        font-family: 'adelle-sans', sans-serif; }
      .single-colecoes .collection-callpost .calllist-item.adelle h2 a {
        font-family: 'adelle-sans', sans-serif; }
  .single-colecoes .collection-parallax {
    position: relative;
    width: 100vw;
    margin: 0 0 80px;
    overflow: hidden; }
    .single-colecoes .collection-parallax .image {
      transition: all .4s ease;
      position: absolute;
      height: 100%;
      width: 100vw;
      background-size: cover;
      background-position: center;
      background-attachment: fixed;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center; }
    .single-colecoes .collection-parallax h2 {
      font-size: calc(1.0356vw + 28.1165px);
      color: #fff;
      text-align: center;
      font-weight: 800; }
      .single-colecoes .collection-parallax h2 span {
        background-color: #000; }
      .single-colecoes .collection-parallax h2.freight {
        font-family: 'freight-display-pro', serif; }
  .single-colecoes .collection-twoColumns .columns {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 32px; }
    .single-colecoes .collection-twoColumns .columns li .content {
      display: flex;
      flex-direction: column;
      padding: calc(3.23625vw + 37.86408px) 80px; }
      @media screen and (max-width: 1024px) {
        .single-colecoes .collection-twoColumns .columns li .content {
          padding: calc(3.23625vw + 37.86408px) calc(3.8835vw + 5.43689px); } }
      .single-colecoes .collection-twoColumns .columns li .content h2 {
        font-size: 48px;
        font-family: 'freight-display-pro', serif;
        line-height: 1.1;
        font-weight: 900;
        margin: 0 0 40px; }
      .single-colecoes .collection-twoColumns .columns li .content .text {
        font-size: 18px;
        line-height: 1.6; }
        .single-colecoes .collection-twoColumns .columns li .content .text code {
          clear: both;
          margin: 5px 20px 0 0;
          padding: 0 0 4px;
          float: left;
          font-family: inherit;
          font-size: 600%;
          line-height: .8;
          font-weight: bold;
          text-transform: uppercase;
          border-bottom: 10px solid #000; }
        .single-colecoes .collection-twoColumns .columns li .content .text h1, .single-colecoes .collection-twoColumns .columns li .content .text h2, .single-colecoes .collection-twoColumns .columns li .content .text h3, .single-colecoes .collection-twoColumns .columns li .content .text h4, .single-colecoes .collection-twoColumns .columns li .content .text h5, .single-colecoes .collection-twoColumns .columns li .content .text h6, .single-colecoes .collection-twoColumns .columns li .content .text p, .single-colecoes .collection-twoColumns .columns li .content .text ul, .single-colecoes .collection-twoColumns .columns li .content .text ol, .single-colecoes .collection-twoColumns .columns li .content .text a {
          line-height: 1.3;
          font-weight: 300;
          margin: 0 0 20px; }
        .single-colecoes .collection-twoColumns .columns li .content .text h1, .single-colecoes .collection-twoColumns .columns li .content .text h2, .single-colecoes .collection-twoColumns .columns li .content .text h3, .single-colecoes .collection-twoColumns .columns li .content .text h4, .single-colecoes .collection-twoColumns .columns li .content .text h5, .single-colecoes .collection-twoColumns .columns li .content .text h6 {
          font-size: calc(0.38835vw + 18.54369px);
          margin: 0 0 10px;
          font-weight: 700;
          line-height: 1.2;
          text-transform: uppercase; }
        .single-colecoes .collection-twoColumns .columns li .content .text a {
          text-decoration: underline; }
        .single-colecoes .collection-twoColumns .columns li .content .text ul {
          list-style-type: disc;
          padding: 0 0 0 20px; }
        .single-colecoes .collection-twoColumns .columns li .content .text ol {
          list-style-type: decimal;
          padding: 0 0 0 20px; }
        .single-colecoes .collection-twoColumns .columns li .content .text p {
          line-height: 1.6; }
      .single-colecoes .collection-twoColumns .columns li .content a {
        display: flex;
        margin: 40px 0 0; }
        .single-colecoes .collection-twoColumns .columns li .content a.btn {
          gap: 50px;
          padding: 16px 20px;
          border: none; }
        .single-colecoes .collection-twoColumns .columns li .content a.link {
          align-self: flex-end; }
    .single-colecoes .collection-twoColumns .columns li figure {
      position: relative;
      height: 100%; }
      .single-colecoes .collection-twoColumns .columns li figure .caption {
        position: absolute;
        color: #fff;
        font-size: 14px;
        bottom: 20px;
        left: 20px;
        max-width: 90%; }
        .single-colecoes .collection-twoColumns .columns li figure .caption span {
          background-color: #000;
          padding: 0 5px; }
    .single-colecoes .collection-twoColumns .columns li img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover; }
    @media screen and (max-width: 700px) {
      .single-colecoes .collection-twoColumns .columns {
        grid-template-columns: 100%; } }
  .single-colecoes .collection-divider {
    display: flex;
    width: 100%; }
    .single-colecoes .collection-divider[data-size="small"] .divider {
      width: calc(100% / 8); }
    .single-colecoes .collection-divider[data-size="big"] {
      padding: 0 0 80px; }
    .single-colecoes .collection-divider[data-position="center"] {
      justify-content: center; }
    .single-colecoes .collection-divider[data-position="left"] {
      justify-content: flex-start; }
    .single-colecoes .collection-divider[data-position="right"] {
      justify-content: flex-end; }
    .single-colecoes .collection-divider .divider {
      width: 100%;
      display: flex; }
      .single-colecoes .collection-divider .divider[data-thickness="super_small"] {
        height: 1px; }
      .single-colecoes .collection-divider .divider[data-thickness="small"] {
        height: 3px; }
      .single-colecoes .collection-divider .divider[data-thickness="medium"] {
        height: 5px; }
      .single-colecoes .collection-divider .divider[data-thickness="big"] {
        height: 10px; }
  .single-colecoes .collection-titleHat h2 {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
  .single-colecoes .collection-code h2 {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase; }
  .single-colecoes .collection-button a {
    display: flex;
    gap: 56px;
    align-items: center;
    justify-content: space-between;
    background-color: #000;
    padding: 20px 22px 20px 32px;
    width: fit-content;
    margin: 0 auto;
    color: #fff;
    font-weight: 700; }
  .single-colecoes .collection-author {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px; }
    .single-colecoes .collection-author div {
      display: flex;
      align-items: center;
      gap: 12px; }
      .single-colecoes .collection-author div p {
        font-family: 'freight-display-pro', serif;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        line-height: 1.2; }
        .single-colecoes .collection-author div p span {
          display: block;
          font-family: 'adelle-sans', sans-serif;
          font-style: normal; }
      .single-colecoes .collection-author div img {
        border-radius: 100%;
        width: 40px;
        height: 40px;
        display: block;
        object-fit: cover; }
      .single-colecoes .collection-author div.tags {
        justify-content: flex-end;
        flex-wrap: wrap; }
    @media screen and (max-width: 1000px) {
      .single-colecoes .collection-author {
        flex-direction: column;
        align-items: flex-start; } }
  .single-colecoes .collection-menu a {
    display: flex;
    align-items: center;
    border-left: 8px solid #000;
    margin: 0 0 24px;
    font-weight: 700; }
    .single-colecoes .collection-menu a span {
      display: block;
      padding: 0 20px; }

.page-republique section {
  max-width: 984px;
  margin: 0 0 80px; }
  .page-republique section .title {
    font-size: calc(1.0356vw + 28.1165px);
    font-weight: 900;
    line-height: 1.1;
    font-family: 'freight-display-pro', serif;
    margin: 0 0 32px; }
  .page-republique section .grid {
    display: grid; }
    .page-republique section .grid li .content {
      padding: 24px;
      border: 1px solid var(--secundary);
      margin: 0 0 20px; }
      .page-republique section .grid li .content p {
        text-transform: uppercase;
        font-size: 16px;
        margin: 0 0 14px; }
      .page-republique section .grid li .content h3 {
        font-size: calc(0.5178vw + 22.05825px);
        line-height: 1.2;
        font-weight: 800; }
    .page-republique section .grid li a {
      display: block;
      text-align: center; }
    .page-republique section .grid li img {
      display: block;
      max-width: 100%;
      height: auto; }
    .page-republique section .grid li .btn {
      background-color: var(--secundary);
      color: #ffff;
      border: none;
      padding: 12px;
      width: 100%; }

.page-republique-clipping .grid {
  grid-template-columns: repeat(3, 1fr);
  gap: 32px 20px; }
  @media screen and (max-width: 700px) {
    .page-republique-clipping .grid {
      grid-template-columns: repeat(1, 1fr); } }

.page-republique-partners .grid {
  grid-template-columns: repeat(4, 1fr);
  gap: 12px 20px; }
  @media screen and (max-width: 700px) {
    .page-republique-partners .grid {
      grid-template-columns: repeat(2, 1fr); } }

.page-apoie section {
  margin: 0 0 80px; }

.page-apoie .title {
  font-size: calc(1.0356vw + 28.1165px);
  font-weight: 900;
  line-height: 1.1;
  font-family: 'freight-display-pro', serif; }

.page-apoie-banner img {
  display: block;
  width: 100%;
  height: auto; }

.page-apoie-about {
  max-width: 860px; }
  .page-apoie-about .title {
    margin: 0 0 10px; }
  .page-apoie-about .subtitle {
    font-weight: 700;
    font-size: calc(0.64725vw + 17.57282px);
    margin: 0 0 20px; }
  .page-apoie-about .text p {
    margin: 0 0 20px; }
  .page-apoie-about .text a {
    display: inline-block;
    background-color: var(--secundary);
    color: #fff;
    font-weight: 700;
    padding: 2px 4px; }
  .page-apoie-about .text ul {
    margin: 0 0 20px; }
  .page-apoie-about .text span {
    font-size: 14px;
    text-decoration: none !important; }

.page-apoie-support .title {
  margin: 0 0 52px; }

.page-apoie-support .cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 28px; }
  @media screen and (max-width: 1000px) {
    .page-apoie-support .cards {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 700px) {
    .page-apoie-support .cards {
      grid-template-columns: repeat(1, 1fr); } }
  .page-apoie-support .cards li {
    padding: 40px 28px;
    background-color: #B6CCC8;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .page-apoie-support .cards li h3 {
      font-size: 24px;
      font-weight: 700;
      margin: 0 0 20px; }
    .page-apoie-support .cards li .text {
      margin: 0 0 20px; }
      .page-apoie-support .cards li .text a {
        font-weight: 700;
        text-decoration: underline; }
    .page-apoie-support .cards li .qrcode {
      display: block; }
      .page-apoie-support .cards li .qrcode img {
        display: block;
        width: auto;
        margin: 0 auto; }
    .page-apoie-support .cards li .price {
      font-size: 16px;
      font-weight: 900;
      margin: 0 0 20px;
      font-family: 'freight-display-pro', serif; }
    .page-apoie-support .cards li .btn {
      background-color: var(--secundary);
      color: #fff;
      border: none;
      padding: 16px 20px;
      width: 100%;
      justify-content: space-between; }

.page-apoie-rewards .title {
  margin: 0 0 32px; }

.page-apoie-rewards .list {
  display: flex;
  gap: 24px;
  margin: 0 0 20px; }
  .page-apoie-rewards .list li img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto; }

.page-apoie-rewards .legend {
  font-size: 12px;
  margin: 0 0 42px; }

.page-apoie-rewards .text {
  max-width: 960px; }

.page-apoie-graphic .title {
  margin: 0 0 32px; }

.page-apoie-graphic img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto; }

.page-apoie-calltoaction {
  max-width: 960px; }
  .page-apoie-calltoaction .title {
    margin: 0 0 40px; }
  .page-apoie-calltoaction img {
    display: block;
    width: auto;
    max-width: 100%;
    margin: 0 0 40px;
    height: auto; }
  .page-apoie-calltoaction .text {
    margin: 0 0 60px; }
  .page-apoie-calltoaction .btn-container {
    position: relative; }
    .page-apoie-calltoaction .btn-container p {
      line-height: 1.4;
      font-size: calc(0.2589vw + 19.02913px);
      font-weight: 700;
      padding: 28px calc(1.94175vw + 24.71845px);
      background: rgba(255, 255, 255, 0.39); }
    .page-apoie-calltoaction .btn-container .btn {
      background-color: var(--secundary);
      color: #fff;
      border: none;
      padding: 16px 20px;
      width: 100%;
      justify-content: space-between;
      position: absolute;
      right: 28px;
      top: calc(100% - 14px);
      max-width: 260px; }
      .page-apoie-calltoaction .btn-container .btn img {
        margin: 0; }

.page-apoie-list {
  max-width: 960px; }
  .page-apoie-list .content {
    padding: 48px 28px;
    background-color: #B6CCC8; }
  .page-apoie-list .title {
    margin: 0 0 52px; }
  .page-apoie-list .list {
    margin: 0 0 20px; }
  .page-apoie-list h5 {
    font-family: 'freight-display-pro', serif;
    font-weight: 700;
    font-style: italic;
    margin: 60px auto 0;
    max-width: 90%; }

.page-newsletter .content .title {
  font-size: calc(1.0356vw + 28.1165px);
  font-weight: 900;
  line-height: 1.1;
  font-family: 'freight-display-pro', serif;
  margin: 0 0 32px;
  max-width: 960px; }

.page-newsletter .content .text {
  margin: 0 0 52px;
  max-width: 960px; }

.page-newsletter .content form {
  background-color: var(--secundary);
  color: #fff;
  padding: 10px; }
  .page-newsletter .content form .label-name {
    display: none; }
  .page-newsletter .content form label {
    display: block; }
  .page-newsletter .content form p {
    position: relative;
    height: 50px; }
  .page-newsletter .content form input {
    height: 50px;
    display: block;
    width: 100%;
    padding: 12px 22px;
    border: 1px solid #B6CCC8;
    color: #B6CCC8; }
    .page-newsletter .content form input::placeholder {
      color: #B6CCC8;
      font-style: italic; }
  .page-newsletter .content form button, .page-newsletter .content form [type="submit"] {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 32px;
    width: 32px;
    height: 36px;
    text-indent: -999999px;
    background-image: url("../assets/arrow-right-blue.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0;
    border: none; }

[disabled] {
  opacity: .2;
  pointer-events: none;
  cursor: not-allowed; }

.wrapper {
  position: relative;
  padding: calc(3.75405vw + 17.92233px) calc(14.88673vw + -35.82524px) calc(1.94175vw + 52.71845px); }

.wrap {
  position: relative;
  padding: calc(3.75405vw + 17.92233px) calc(10.35599vw + -18.83495px) calc(1.94175vw + 52.71845px); }

.page-notfound .title {
  font-size: calc(15.53398vw + -18.25243px);
  font-weight: 900;
  line-height: 1;
  font-family: 'freight-display-pro', serif;
  margin: 0 0 70px; }

.page-notfound a {
  font-size: calc(0.38835vw + 16.54369px);
  font-weight: 600;
  background-color: black;
  color: #E1EAE9;
  display: block;
  width: fit-content;
  padding: 10px 20px; }

.menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .menu-container .menu-close {
    width: 18px;
    height: 17px;
    position: relative;
    text-indent: -9999999999px;
    transition: opacity .4s ease; }
    .menu-container .menu-close:before, .menu-container .menu-close:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 3px;
      width: 100%;
      border-radius: 10px;
      translate: 0 -50%;
      background-color: var(--secundary); }
    .menu-container .menu-close:before {
      rotate: 45deg; }
    .menu-container .menu-close:after {
      rotate: -45deg; }
    .menu-container .menu-close:hover {
      opacity: .6; }
  .menu-container .menu-modal {
    width: 100%;
    max-width: 530px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #F0F5F4;
    display: none;
    z-index: 2; }
    .menu-container .menu-modal.open {
      display: block; }
    .menu-container .menu-modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px calc(3.23625vw + 7.86408px);
      min-height: calc(74px + 35px); }
      .menu-container .menu-modal-header .support-btn {
        font-weight: 700;
        padding: 10px 20px;
        background-color: var(--secundary);
        border-radius: 50px;
        color: #E1EAE9;
        transition: opacity .4s ease;
        display: none; }
        .menu-container .menu-modal-header .support-btn:hover {
          opacity: .6; }
        @media screen and (max-width: 1024px) {
          .menu-container .menu-modal-header .support-btn {
            display: block; } }
      @media screen and (max-width: 1024px) {
        .menu-container .menu-modal-header {
          min-height: calc(40px + 36px); } }
    .menu-container .menu-modal-content {
      padding: 0 calc(3.23625vw + 7.86408px) 18px;
      border-top: 1px solid var(--secundary);
      overflow: scroll;
      max-height: 80vh; }
      .menu-container .menu-modal-content::-webkit-scrollbar {
        display: none; }
      .menu-container .menu-modal-content .menu > li > a {
        padding: 20px 0;
        border-bottom: 1px solid var(--secundary);
        font-weight: 700;
        display: block;
        transition: opacity .4s ease; }
        .menu-container .menu-modal-content .menu > li > a[href="#"] {
          position: relative; }
          .menu-container .menu-modal-content .menu > li > a[href="#"]:before, .menu-container .menu-modal-content .menu > li > a[href="#"]:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0%;
            translate: 0% -50%;
            background-color: var(--secundary); }
          .menu-container .menu-modal-content .menu > li > a[href="#"]:before {
            width: 24px;
            height: 8px; }
          .menu-container .menu-modal-content .menu > li > a[href="#"]:after {
            height: 24px;
            width: 8px;
            right: calc(12px - 4px); }
          .menu-container .menu-modal-content .menu > li > a[href="#"].open:before, .menu-container .menu-modal-content .menu > li > a[href="#"].open:after {
            rotate: 45deg; }
        .menu-container .menu-modal-content .menu > li > a:hover {
          opacity: .6; }
      .menu-container .menu-modal-content .menu > li .sub-menu {
        margin: 20px 0;
        display: none; }
        .menu-container .menu-modal-content .menu > li .sub-menu.open {
          display: block; }
        .menu-container .menu-modal-content .menu > li .sub-menu li {
          margin: 0 0 8px; }
          .menu-container .menu-modal-content .menu > li .sub-menu li a {
            font-size: calc(0.12945vw + 13.51456px);
            transition: opacity .4s ease; }
            .menu-container .menu-modal-content .menu > li .sub-menu li a:hover {
              opacity: .6; }

body.tag {
  background-color: var(--principal);
  color: var(--secundary);
  font-size: calc(0.12945vw + 15.51456px);
  text-transform: none;
  padding: 0;
  width: 100%;
  font-weight: normal; }
  body.tag h1 {
    text-transform: uppercase; }

.page-result-header h1 {
  font-size: calc(0.7767vw + 33.08738px);
  font-weight: 900;
  line-height: 1.2;
  font-family: 'freight-display-pro', serif;
  margin: 0 0 50px; }
  .page-result-header h1.nofound {
    text-align: center; }

.page-result-header form {
  margin: 0 0 50px;
  border: 1px solid #000;
  background-color: #F0F5F4;
  padding: 10px 16px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .page-result-header form input {
    width: 100%;
    padding: 10px 16px; }
    .page-result-header form input::placeholder {
      font-style: italic; }
  .page-result-header form button {
    width: 24px;
    height: 24px;
    background-image: url("../assets/arrow-right-black.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    text-indent: -99999px; }

.page-result-container {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(12, 1fr); }
  .page-result-container article {
    border: 1px solid var(--secundary);
    padding: 14px;
    grid-column: span 3; }
    .page-result-container article a {
      transition: opacity .4s ease; }
      .page-result-container article a:hover {
        opacity: .6; }
    .page-result-container article .thumb {
      position: relative; }
      .page-result-container article .thumb img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .page-result-container article .thumb figcaption {
        position: absolute;
        left: 24px;
        bottom: 24px;
        display: none;
        background-color: #B6CCC8;
        color: #000;
        border: 1px solid #000; }
    .page-result-container article .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between; }
    .page-result-container article .title {
      font-weight: 600;
      margin: 14px 0 20px; }
    .page-result-container article .date {
      text-transform: capitalize; }
    .page-result-container article .author-container {
      display: none; }
      .page-result-container article .author-container img {
        width: 40px;
        height: 40px; }
    .page-result-container article:first-of-type, .page-result-container article:nth-child(2), .page-result-container article:nth-child(3) {
      grid-column: span 4;
      padding: 0;
      background-color: #F0F5F4; }
      .page-result-container article:first-of-type .content, .page-result-container article:nth-child(2) .content, .page-result-container article:nth-child(3) .content {
        padding: 20px 24px; }
      .page-result-container article:first-of-type .thumb figcaption, .page-result-container article:nth-child(2) .thumb figcaption, .page-result-container article:nth-child(3) .thumb figcaption {
        display: block; }
      .page-result-container article:first-of-type .title, .page-result-container article:nth-child(2) .title, .page-result-container article:nth-child(3) .title {
        font-family: 'freight-display-pro', serif;
        line-height: 1.2;
        font-size: 24px;
        font-weight: 900;
        margin: 0 0 20px; }
      .page-result-container article:first-of-type .author-container, .page-result-container article:nth-child(2) .author-container, .page-result-container article:nth-child(3) .author-container {
        display: flex; }
      .page-result-container article:first-of-type time, .page-result-container article:nth-child(2) time, .page-result-container article:nth-child(3) time {
        display: none; }
      @media screen and (max-width: 1000px) {
        .page-result-container article:first-of-type, .page-result-container article:nth-child(2), .page-result-container article:nth-child(3) {
          grid-column: span 6; } }
      @media screen and (max-width: 700px) {
        .page-result-container article:first-of-type, .page-result-container article:nth-child(2), .page-result-container article:nth-child(3) {
          grid-column: span 12; } }
    @media screen and (max-width: 1000px) {
      .page-result-container article {
        grid-column: span 6; } }
    @media screen and (max-width: 700px) {
      .page-result-container article {
        grid-column: span 12; }
        .page-result-container article .thumb {
          display: none; }
        .page-result-container article .title {
          margin: 0 0 16px; } }
  .page-result-container .noresult {
    grid-column: span 12; }
    .page-result-container .noresult h2 {
      font-family: 'freight-display-pro', serif;
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 22px; }
    .page-result-container .noresult .tags {
      display: flex;
      flex-wrap: wrap;
      gap: 12px; }

.su-tooltip-inner {
  padding: 0 !important; }

.su-tooltip-content {
  font-size: 16px !important;
  color: #000000 !important;
  background-color: #B6CCC8 !important;
  border: 1px solid #000000 !important;
  padding: 20px !important;
  line-height: 1.4 !important; }

.su-tooltip-arrow::before {
  display: none !important; }

.su-tooltip-button {
  background-color: #B6CCC8;
  font-weight: 700;
  cursor: pointer;
  border: 1px solid var(--secundary);
  display: inline-block;
  padding: 0 2px; }

::-moz-selection {
  /* Code for Firefox */
  color: #000;
  background: #f3f3f3; }

::selection {
  color: #000;
  background: #f3f3f3; }

.card-link:before, .newsletter .container h2 .link:before, .page-home-hero-highlight h2 a:before, .page-home-hero-statistic .link a:before, .page-home-carousel .slider li h3 a:before, .page-home-media-list li div a:before, .page-home-news-interviews article .content h3 a:before, .page-home-news-highlight article a:before, .page-home-news-articles article a:before, .page-home-moreread-grid ol li .links .link:before, .page-home-projects-accordion .item-text h3 .link:before, .page-home-videos-container-grid article a:before, .page-archive-container .column article .link:before, .post-wrapper .content .box.box-wire .box-content:before, .post-wrapper .content .list li a:before, .post-wrapper .sidebar ul li a:before, .post-wrapper .sidebar .related article a:before, .post-wrapper .sidebar .support-card a:before, .post-footer .list li .info a:before, .page-towatch .title a:before, .page-towatch-gallery .grid .content h3 a:before, .page-towatch-news .grid article h3 a:before, .page-authors-carousel .slider-item .content h3 a:before, .page-authors-grid .grid-item h3 a:before, .page-authors-list .list-item a:before, .page-author-posts .highlight .content h3 a:before, .page-author-posts .grid article .thumb a:before, .page-author-writers .cards article a:before, .page-category-highlight .box .content .link-to-page:before, .page-category-article article .content .link-to-page:before, .page-category-news-interviews article .link-to-page:before, .page-about-area .support p a:before, .page-opendata section ul li .resume a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
