.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  padding: 18px responsive(20, 70);
  min-height: calc(74px + 36px);
  border-bottom: 1px solid var(--secundary);
  position: relative;
  @media screen and (max-width: $tablet) {
    min-height: calc(40px + 36px);
  }
  &-wrap {
    z-index: 4;
    background-color: var(--principal);
    position: sticky;
    top: 0;
  }
  &-btn {
    width: 18px;
    height: 17px;
    position: relative;
    &:before, &:after, span {
      content: '';
      position: absolute;
      left: 0;
      height: 3px;
      width: 100%;
      border-radius: 10px;
      background-color: var(--secundary);
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    span {
      top: 50%;
      transform: translateY(-50%);
    }
    transition: opacity .4s ease;
    &:hover {
      opacity: .6;
    }
  }
  &-logo {
    width: responsive(40, 74);
    height: responsive(40, 74);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #000;
    border-radius: 100%;
    img, svg {
      display: block;
      width: 52%;
    }
    transition: opacity .4s ease;
    &:hover {
      opacity: .6;
    }
  }
  &-menu {
    display: flex;
    align-items: center;
    gap: 30px;
    a {
      &.support-btn {
        font-weight: 700;
        padding: 10px 20px;
        background-color: var(--secundary);
        border-radius: 50px;
        color: var(--principal);
      }
      transition: font-weight .4s ease;
      &:hover {
        font-weight: 700;
      }
    }
    &-search {
      position: relative;
      display: flex;
      align-items: center;
      form {
        position: absolute;
        display: block;
        width: 0;
        background-color: #F0F5F4;
        top: 50%;
        right: calc( 100% + 20px );
        translate: 0% -50%;
        transition: width .4s ease;
        padding: 10px 0;
        overflow: hidden;
        &.open {
          border: 1px solid #000;
          width: responsive(250, 450);
          padding: 10px;
        }
      }
      input {
        width: 100%;
        background-color: transparent;
        &::placeholder { 
          font-style: italic;
        }
      }
      .send-search {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        top: 50%;
        translate: 0% -50%;
        background-image: url($img + 'arrow-right-black.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        text-indent: -99999px;
      }
      .open-search {
        width: 24px;
        height: 25px;
        overflow: hidden;
      }
    }
    @media screen and (max-width: $tablet) {
      a {
        display: none;
      }
    }
  }
  &-submenu {
    padding: 12px responsive(20, 70);
    border-bottom: 1px solid var(--secundary);
    @media screen and (max-width: $tablet) {
      padding: 15px responsive(20, 70);
    }
    &-category {
      display: none;
      justify-content: center;
      a {
        font-size: 18px;
        display: block;
        margin: 0 25px;
        transition: font-weight .4s ease;
        &:hover {
          font-weight: 700;
        } 
      }
    }
    &-share {
      position: relative;
      display: flex;
      justify-content: space-between;
      a {
        gap: 10px;
        font-size: 16px;
        font-weight: 700;
        display: flex;
        align-items: center;
        transition: opacity .4s ease;
        &:hover {
          opacity: .6;
        }
        @media screen and (max-width: $mobile) {
          span {
            display: none;
          }
        }
      }
      .collection-name {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: responsive(17, 20);
        font-weight: 700;
        line-height: 1.2;
      }
      .page-name {
        text-transform: uppercase;
      }
      ul {
        li {
          p {
            font-size: 14px;
            @media screen and (max-width: $tablet) {
              display: none;
            }
          }
        }
        @media screen and (max-width: $mobile) {
          display: none;
        }
      }
    }
  }
}
