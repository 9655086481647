.page-archive {
  &-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 responsive(32, 90);
    .title {
      font-size: responsive(36, 86);
      font-weight: 900;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
    }
    .filter {
      min-width: 255px;
      padding: 14px 28px;
      background-color: var(--secundary);
      color: var(--principal);
      display: flex;  
      align-items: center;
      justify-content: space-between;
      span, img {
        display: block;
      }
    }
    @media screen and (max-width: $tablet_vertical) {
      flex-direction: column;
      align-items: flex-start;
      .title {
        margin: 0 0 32px;
      }
    }
  }
  &-container {
    .column {
      margin: 0 0 36px;
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(4, 1fr);
      article {
        border: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        }
        .link {
          @extend .card-link;
        }
        .thumb {
          position: relative;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tag {
            position: absolute;
            bottom: 16px;
            left: 16px;
            background-color: var(--principal);
            color: var(--secundary);
            border: 1px solid var(--secundary);
            z-index: 1;
          }
        }
        .content {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
        }
        .inner {
          width: 100%;
        }
        .date {
          font-size: 14px;
        }
        .title {
          font-family: 'freight-display-pro', serif;
          font-size: responsive(32, 48);
          font-weight: 600;
          line-height: 1.1;
          margin: 26px 0 20px;
        }
        .resume {
          margin: 0 0 30px;
        }
        .author-container {
          position: relative;
        }
      }
      &-banner {
        article {
          padding: responsive(16, 40) responsive(16, 64) responsive(16, 40) responsive(16, 34);
          grid-column: span 4;
          display: flex;
          .thumb {
            width: 40%;
            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }
            .tag {
              left: 50%;
              transform: translateX(-50%);
            }
          }
          .content {
            width: 60%;
            border-left: 1px solid var(--secundary);
            padding-left: responsive(16, 32);
            margin-left: responsive(16, 32);
          }
          .author-container {
            img {
              width: 40px;
              height: 40px;
            }
          }
          @media screen and (max-width: $tablet_vertical) {
            flex-direction: column;
            .thumb {
              width: 100%;
            }
            .content {
              width: 100%;
              border-top: 1px solid var(--secundary);
              padding-top: responsive(16, 32);
              margin-top: responsive(16, 32);

              border-left: none;
              padding-left: 0;
              margin-left: 0;
            }
          }
        }
      }
      &-highlight {
        article {
          padding: responsive(16, 40) responsive(16, 34);
          grid-column: span 2;
          .thumb {
            img {
              width: 100%;
            }
          }
          .date {
            margin: 28px 0 12px;
          }
          .title {
            font-size: responsive(32, 42);
            margin: 0;
          }
          @media screen and (max-width: $tablet_vertical) {
            grid-column: span 4;
            .thumb {
              display: none;
            }
            .date {
              margin: 0;
            }
          }
        }
      }
      &-testimonial {
        grid-template-columns: repeat(3, 1fr);
        article {
          display: flex;
          flex-direction: column;
          padding: responsive(16, 24) responsive(16, 24) responsive(16, 44);
          .title {
            font-size: responsive(20, 32);
            line-height: 1.4;
            margin: 0 0 16px;
          }
          .thumb {
            border-radius: 100%;
            overflow: hidden;
            min-width: responsive(144, 192);
            min-height: responsive(144, 192);
            width: responsive(144, 192);
            height: responsive(144, 192);
            margin: 0 auto;
          }
          .content {
            height: 100%;
            h2 {
              line-height: 1.2;
            }
            img {
              margin: 25px 0 0;
              width: 26px;
            }
            .tag {
              position: relative;
            }
            .author-container {
              span {
                font-size: 14px;
              }
            }
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          grid-template-columns: repeat(1, 1fr);
          article {
            .thumb {
              display: none;
            }
            .content {
              img {
                margin: 0 0 18px;
              }
            }
            &:first-of-type {
              .thumb {
                display: block;
              }
              .content {
                img {
                  margin: 25px 0 18px;
                }
              }
            }
          }
        }
      }
      &-grid {
        gap: 10px;
        article {
          padding: responsive(20, 14);
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            display: none;
          }
          a {
            height: 100%;
            display: flex;
            flex-direction: column;
          }
          .title {
            font-size: 16px;
            line-height: 1.4;
            font-family: 'adelle-sans', sans-serif;
            margin: 14px 0 16px;
          }
          .date {
            font-size: 12px;
            text-transform: none;
            margin: 0;
          }
          .content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          .name {
            font-size: 14px;
          }
          @media screen and (max-width: $tablet_vertical) {
            grid-column: span 2;
            .thumb {
              display: none;
            }
            .title {
              margin: 0 0 16px;
            }
          }
          @media screen and (max-width: $mobile) {
            grid-column: span 4;
          }
        }
      }
    }
  }
}

.post-type-archive-entrevistas {
  .page-archive {
    &-container {
      .column {
        &-grid {
          article {
            &:nth-child(1), &:nth-child(2), &:nth-child(3), &:nth-child(4) {
              display: none;
            }
          }
        }
      }
    }
  }
}