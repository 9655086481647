.single-colecoes {
  // Colors
  --bg: #F5A38A;
  --text: #000000;
  --text-light: #ffffff;
  --box: #C75936;
  --highlight: #ED8A57;
  --highlight-text: #913838;
  --highlight-details: #E3E382;
  --highlight-bg: #DE8C8C;

  background-color: #B6CCC8;

  .i4ewOd-pzNkMb-haAclf {
    display: none !important;
  }

  .header {
    background-color: #B6CCC8;
  }

  .container {
    margin: 0 0 80px;
    padding: 0 responsive(20, 180);
  }

  .text-container {
    h1, h2, h3, h4, h5, h6 {
      margin: 0 0 10px;
      font-weight: 700;
      line-height: 1.2;
    }
    ul {
      list-style-type: disc;
      margin: 10px 0;
      padding-left: 20px;
    }
    ol {
      list-style-type: decimal;
      margin: 10px 0;
      padding-left: 20px;
    }
    a {
      text-decoration: underline;
    }
    blockquote {
      border-left: 6px solid #000;
      padding-left: 20px;
      font-size: 120%;
      margin: 10px 0;
    }
    p {
      margin: 10px 0;
    }
  }

  .collection {
    &-wrap {
      padding: 0 0 responsive(60, 90);
    }

    &-banner {
      position: relative;
      min-height: 50vh;
      padding: 0;
      margin: 0;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .title-container {
        width: 100%;
        height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 90%;
        transform: translate(-50%, -50%);
      }
      h1 {
        width: 100%;
        font-size: responsive(32, 48);
        font-weight: 800;
        line-height: 1.6;
        position: absolute;
        top: 100%;
        transform: translateY(-100%);
        width: fit-content;        
        &.big {
          line-height: 1.4;
          font-size: responsive(120, 240);
          &.lineHeight {
            line-height: 0.9;
          }
        }
        &.freight {
          font-family: 'freight-display-pro', serif;
        }
        &.adelle {
          font-family: 'adelle-sans', sans-serif;
        }
        &.top {
          top: 0%;
          transform: translateY(0%);
        }
        &.bottom {
          transform: translateY(-100%);
          &.center {
            transform: translate(-50%, -100%);
          }
        }
        &.center_vertical {
          top: 50%;
          transform: translateY(-50%);
          &.center {
            transform: translate(-50%, -50%);
          }
        }
        &.center {
          left: 50%;
          transform: translateX(-50%);
          // justify-content: center;
          text-align: center;
        }
        &.right {
          right: 0%;
          text-align: right;
        }
        // span {
        //   color: var(--text);
        //   background-color: #fff;
        // }
      }
      @media screen and (max-width: $tablet_vertical) {
        min-height: 50vh;
        figure {
          height: 50vh;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &-text {
      &.bg {
        padding: 0;
        .collection-text-content {
          padding: responsive(30, 60) responsive(20, 180);
          background-color: #fff;
        }
      }
      &.box {
        .collection-text-content {
          padding: responsive(40, 80);
          background-color: #fff;
        }
      }
      h2 {
        font-weight: 900;
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        font-size: responsive(32, 48);
        margin: 0 0 32px;
        &.freight {
          font-family: 'freight-display-pro', serif;
        }
        &.adelle {
          font-family: 'adelle-sans', sans-serif;
        }
        &.big {
          font-size: 32px;
        }
        &.small {
          font-size: 28px;
        }
      }
      .text {
        &.xlarge {
          p, a, ul li, ol li, h1, h2, h3, h4, h5, h6, strong {
            font-size: responsive(120, 240);
            line-height: 0.9;
            font-weight: 900;
          }
        }
        &.big {
          p, a, ul li, ol li, h1, h2, h3, h4, h5, h6 {
            font-size: 26px;
          }
        }
        &.small {
          p, a, ul li, ol li, h1, h2, h3, h4, h5, h6 {
            font-size: 18px;
          }
        }
        code {
          clear: both;
          margin: 5px 20px 0 0;
          padding: 0 0 4px;
          float: left;
          font-family: inherit;
          font-size: 600%;
          line-height: .8;
          font-weight: bold;
          text-transform: uppercase;
          border-bottom: 10px solid #B6CCC8;
        }
        h1, h2, h3, h4, h5, h6, p, ul, ol, a {
          line-height: 1.3;
          font-weight: 300;
          margin: 0 0 20px;
          font-size: responsive(24, 32);
        }
        h1, h2, h3, h4, h5, h6 {
          margin: 0 0 10px;
          font-weight: 700;
          line-height: 1.2;
          text-transform: uppercase;
        }
        a {
          text-decoration: underline;
        }
        ul {
          list-style-type: disc;
          padding: 0 0 0 20px;
        }
        ol {
          list-style-type: decimal;
          padding: 0 0 0 20px;
        }
        p {
          line-height: 1.5;
        }
        &.freight {
          font-family: 'freight-display-pro', serif;
        }
        &.adelle {
          font-family: 'adelle-sans', sans-serif;
        }
      }
    }

    &-tabs {
      &-title {
        margin: 0 0 32px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
      }
      &-wrapper {
        display: flex;
        gap: 30px;
        @media screen and (max-width: $tablet_vertical) {
          flex-direction: column;
        }
      }
      &-navigation {
        display: flex;
        gap: 12px;
        flex-direction: column;
        button {
          padding: 16px;
          width: 200px;
          display: block;
          background-color: #000;
          color: #fff;
          text-align: left;
          text-transform: uppercase;
          font-size: 18px;
          font-weight: 800;
          border-bottom-left-radius: 8px;
          &:nth-child(even) {
            border-top-right-radius: 8px;
            border-bottom-left-radius: 0;
          }
          &.active {
            cursor: default;
            pointer-events: none;
            background-color: #fff;
            color: var(--text);
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          justify-content: flex-start;
          flex-direction: row;
          button {
            background-color: transparent;
            border-bottom: 8px solid var(--text-light);
            border-radius: 0;
            text-indent: -999999999px;
            max-width: 20px;
            &.active {
              text-indent: 0;
              max-width: 200px;
              background-color: transparent;
              border-bottom: 8px solid var(--text);
            }
          }
        }
      }
      &-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        background-color: #fff;
        border-bottom-right-radius: 20px;
        overflow: hidden;
        &-item {
          display: none;
          color: var(--text);
          padding: responsive(21, 42) responsive(41, 82);
          img {
            display: block;
            width: 100%;
            height: auto;
          }
          h1, h2, h3, h4, h5, h6 {
            font-size: 22px;
            margin: 0 0 10px;
          }
          p {
            margin: 0 0 10px;
          }
          ul {
            list-style-type: disc;
            padding-left: 20px;
            margin: 0 0 10px;
          }
          ol {
            list-style-type: decimal;
            padding-left: 20px;
            margin: 0 0 10px;
          }
          a {
            text-decoration: underline;
          }
          &.active {
            display: block;
          }
        }
      }
      &.horizontal {
        .collection-tabs-wrapper {
          flex-direction: column;
          .collection-tabs-navigation {
            flex-direction: row;
            justify-content: flex-start;
            button {
              border-radius: 0;
              background-color: transparent;
              color: #000;
              border-bottom: 8px solid #000;
              &.active {
                color: #fff;
                border-bottom: 8px solid #fff;
              }
            }
          }
        }
      }
    }

    &-highlight {
      &-row {
        padding: responsive(20, 42);
        display: grid;
        grid-template-columns: 35% 25% 25%;
        gap: 34px;
        color: var(--text);
        background-color: var(--text-light);

        figure {
          a {
            display: block;
            width: 100%;
            height: 100%;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 0 0 20px 0;
          }
        }
        
        h3 {
          margin: 0 0 10px;
          font-size: responsive(24, 32);
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
        }

        .resume {
          p {
            b {
              font-weight: 800;
            }
          }
        }

        @media screen and (max-width: $tablet) {
          grid-template-columns: 100%;
        }
      }
    }

    &-quote {
      blockquote {
        padding: 50px responsive(48, 68);
        background-color: var(--text-light);
        color: var(--text);
        display: flex;
        align-items: flex-start;
        gap: 24px;
        img {
          display: block;
          width: responsive(60, 100);
        }
        .highlight {
          font-family: 'freight-display-pro', serif;
          margin: 0 0 20px;
          p {
            width: 100%;
            font-size: responsive(32, 40);
            font-weight: 900;
            line-height: 1.1;
          }
        }
        @media screen and (max-width: $mobile) {
          flex-direction: column;
        }
      }
    }

    &-tag {
      div {
        background-color: #fff;
        border-radius: 0 8px 0 8px;
        padding: 18px 28px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        font-size: responsive(14, 16);
        p {
          font-weight: 700;
        }
        a {
          display: flex;
          gap: 10px;
          align-items: center;
          text-decoration: underline;
          img {
            width: 20px;
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    &-accordion {
      &-content {
        display: flex;
        gap: 28px;
        justify-content: space-between;
        color: var(--text);
        .title {
          padding: responsive(28, 40) responsive(28, 32);
          color: var(--text-light);
          background-color: var(--text);
          font-size: responsive(28, 32);
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
          border-top-left-radius: 20px;
          max-width: 304px;
        }
        .accordion {
          display: flex;
          flex-direction: column;
          gap: 8px;
          width: 100%;
          details {
            color: var(--text-light);
            background-color: var(--text);
            padding: 10px 28px;
            summary {
              font-size: 20px;
              font-weight: 900; 
              line-height: 1.2;
              font-family: 'freight-display-pro', serif;
              cursor: pointer;
              &::-webkit-details-marker {
                display:none;
              }
              display: flex;
              align-items: center;
              justify-content: space-between;
              .arrow {
                display: block;
                width: 16px;
                transform: scaleY(-1);
                path {
                  fill: var(--text-light);
                }
              }
            }
            .text {
              margin: 12px 0 0;
              padding-right: 82px;
              img {
                display: block;
                width: 100%;
                height: auto;
              }
              h1, h2, h3, h4, h5, h6 {
                font-size: 22px;
                margin: 0 0 10px;
              }
              p {
                margin: 0 0 10px;
              }
              ul {
                list-style-type: disc;
                padding-left: 20px;
                margin: 0 0 10px;
              }
              ol {
                list-style-type: decimal;
                padding-left: 20px;
                margin: 0 0 10px;
              }
              a {
                text-decoration: underline;
              }
            }
            &[open] {
              padding: 18px 28px;
              color: var(--text);
              background-color: var(--text-light);
              .arrow {
                transform: scaleY(1);
                path {
                  fill: var(--text);
                }
              }
            }
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          flex-direction: column;
          .title {
            max-width: 100%;
          }
        }
      }
    }

    &-posts {
      .list {
        display: grid;
        gap: responsive(20, 40);
        grid-template-columns: repeat(3, 1fr);
        .item {
          padding: 34px 28px;
          background-color: var(--text-light);
          color: var(--text);
          border-radius: 0 20px 0 20px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          figure {
            aspect-ratio: 1/1;
            width: 100%;
            position: relative;
            margin: 0 0 16px;
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            figcaption {
              position: absolute;
              padding: 8px 18px;
              text-transform: uppercase;
              font-size: 10px;
              font-weight: 700;
              top: 16px;
              left: 18px;
              background-color: var(--text);
              color: var(--text-light);
            }
          }
          .text {
            margin: 0 0 38px;
            font-size: responsive(18, 24);
            font-weight: 900;
            line-height: 1.2;
            font-family: 'freight-display-pro', serif;
            padding: 0 40px 0 0;
          }
          .author {
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
              margin: 0;
            }
            a {
              display: block;
            }
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width: $mobile) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }

    &-percent {
      display: flex;
      flex-direction: column;
      align-items: center;
      .content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        
        h2 {
          font-size: responsive(120, 240);
          font-weight: 900; 
          line-height: 1;
          font-family: 'freight-display-pro', serif;
        }
        .text {
          max-width: 400px;
          line-height: 1.1;
          font-family: 'freight-display-pro', serif;
          font-size: responsive(28, 32);
          padding: 40px 0;
          position: relative;
          strong, b {
            font-weight: 700;
          }
          &:before, &:after {
            content: '';
            position: absolute;
            width: 100px;
            height: 8px;
            background-color: var(--text);
            left: 0;
          }
          &:before {
            top: 0;
          }
          &:after {
            bottom: 0;
          }
          @media screen and (max-width: $mobile) {
            text-align: center;
            &:before, &:after {
              left: 50%;
              transform: translateX(-50%)
            }
          }
        }
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          h2 {
            margin: 0 0 20px;
          }
        }
      }
      a {
        display: block;
        margin: 42px 0 0;
        padding: 12px 40px;
        font-weight: 700;
        color: #fff;
        background-color: #000;
        width: fit-content;
      }
    }

    &-carouselimage {
      .swiper-slide {
        width: fit-content !important;
      }
      &-content {
        display: flex;
        gap: 8px;
        border-radius: 0 20px 0 0;
        overflow: hidden;
        position: relative;
        .slide {
          height: 100%;
          div {
            height: 520px;
          }
          &-item {
            position: relative;
            height: 100%;
            img {
              display: block;
              max-width: 100%;
              height: 520px;
              object-fit: cover;
            }
            .caption {
              position: absolute;
              color: #fff;
              font-size: 14px;
              bottom: 20px;
              left: 20px;
              max-width: 90%;
              span {
                background-color: #000;
                padding: 0 5px;
              }
            }
          }
        }
        .arrows {
          position: absolute;
          height: 100%;
          top: 0;
          right: 0;
          background-color: var(--text);
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          gap: 40px;
          width: 40px;
          z-index: 1;
          button {
            width: 26px;
            display: block;
            img {
              display: block;
              width: 100%;
            }
          }
        }
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          .arrows {
            background-color: transparent;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: auto;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            button {
              background-color: #000;
              width: 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                max-width: 60%;
              }
            }
          }
        }
      }
    }

    &-webstories {
      &-content {
        display: flex;
        gap: 28px;
        border-radius: 0 20px 0 0;
        overflow: hidden;
        justify-content: space-between;
        position: relative;
        .slide {
          height: 100%;
          width: calc(100% - 80px);
          &-item {
            height: 600px;
            position: relative;
            min-width: 300px;
            max-width: 300px;
            padding: 0 28px 0 0;
            overflow: hidden;
            .content {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              height: 100%;
              border-radius: 0 0 20px 0;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              overflow: hidden;
              h2 {
                padding: 24px;
                background-color: var(--text);
                color: var(--text-light);
                font-size: 24px;
                font-weight: 900;
                line-height: 1.2;
                font-family: 'freight-display-pro', serif;
                text-decoration: underline;
              }
            }
            @media screen and (max-width: $tablet_vertical) {
              width: 40%;
            }
            @media screen and (max-width: $mobile) {
              width: 100%;
            }
          }
        }
        .arrows {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 40px;
          position: absolute;
          right: 0;
          top: 0;
          height: 100%;
          z-index: 1;
          button {
            width: 26px;
            background-color: var(--text);
            width: responsive(40, 60);
            height: responsive(40, 60);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            &.slick-hidden {
              display: none;
            }
            img {
              display: block;
              width: 20px;
            }
          }
        }
      }
    }

    &-tag02 {
      &-content {
        width: 80%;
        position: relative;
        .container {
          display: flex;
          align-items: center;
          width: calc(100% - 125px);
          padding: 28px 80px 28px 30px;
          border: 1px solid var(--secundary);
          background-color: #F0F5F4;
          h2 {
            line-height: 1.2;
            font-family: 'freight-display-pro', serif;
            font-size: 36px;
            font-weight: 700;
          }
        }
        .btn {
          position: absolute;
          right: 20%;
          top: 50%;
          color: #F0F5F4;
          transform: translate(100%, -50%);
          border: 1px solid var(--secundary);
          background-color: var(--secundary);
          transition: opacity .4s ease;
          &:hover {
            opacity: .8;
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          width: 100%;
          .container {
            width: 100%;
            padding: 28px 30px calc(28px + 35px);
            p {
              max-width: 100%;
            }
          }
          .btn {
            right: auto;
            top: auto;
            left: 50%;
            bottom: -45px;
            transform: translateX(-50%);
            width: calc(100% - 40px);
          }
        }
      }
    }

    &-image {
      h2 {
        margin: 0 0 32px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
      }
      figure {
        position: relative;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      .caption {
        position: absolute;
        color: #fff;
        font-size: 14px;
        bottom: 20px;
        left: 20px;
        max-width: 90%;
        background-color: #000;
        padding: 0 5px;
      }
    }

    &-video {
      h2 {
        margin: 0 0 32px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
      }
      .video {
        position: relative;
        padding-top: 56.25%;
        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }

    &-testimony {
      &-content {
        display: flex;
        gap: 30px;
        align-items: center;
        justify-content: center;
        figure {
          min-width: responsive(300, 400);
          width: responsive(300, 400);
          height: responsive(300, 400);
          display: block;
          overflow: hidden;
          border-radius: 0 40px 0 40px;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        h2 {
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
          font-size: responsive(24, 32);
          margin: 0 0 40px;
        }
        div {
          width: 50%;
        }
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          align-items: flex-start;
          div {
            width: 100%;
          }
        }
      }
    }

    &-map {
      h2 {
        margin: 0 0 32px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
      }
      &-content {
        width: 100%;
        height: 100%;
        overflow: hidden;
        iframe {
          margin-top: -70px;
          width: 100%;
        }
      }
    }

    &-callpost {
      .calllist {
        display: flex;
        gap: 20px;
        @media screen and (max-width: $tablet) {
          flex-direction: column;
        }
        &-item{
          width: 100%;
          height: 60vh;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          padding: 40px 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          overflow: hidden;
          position: relative;
          h2 {
            width: calc(100% - 48px);
            font-size: responsive(32, 48);
            font-weight: 800;
            line-height: 1.5;
            &.small {
              font-size: responsive(18, 24);
            }
            &.medium {
              font-size: responsive(24, 32);
            }
            &.big {
              font-size: responsive(32, 48);
            }
          }
          &.center {
            align-items: center;
            text-align: center;
          }
          &.centervertical {
            h2 {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          &.right {
            align-items: flex-end;
            text-align: right;
          }
          &.top {
            h2 {
              position: absolute;
              top: 40px;
            }
          }
          &.freight {
            .tag {
              font-family: 'freight-display-pro', serif;
              font-weight: 800;
            }
            h2 {
              a { 
                font-family: 'freight-display-pro', serif;
              }
            }
          }
          &.adelle {
            .tag {
              font-family: 'adelle-sans', sans-serif;
            }
            h2 {
              a {
                font-family: 'adelle-sans', sans-serif;
              }
            }
          }
        }
      }
    }

    &-parallax {
      position: relative;
      width: 100vw;
      margin: 0 0 80px;
      overflow: hidden;
      .image {
        transition: all .4s ease;
        position: absolute;
        height: 100%;
        width: 100vw;
        background-size: cover;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h2 {
        font-size: responsive(32, 48);
        color: #fff;
        text-align: center;
        font-weight: 800;
        span {
          background-color: #000;
        }
        &.freight {
          font-family: 'freight-display-pro', serif;
        }
      }
    }

    &-twoColumns {
      .columns {
        display: grid;
        grid-template-columns: 50% 50%;
        gap: 32px;
        li {
          .content {
            display: flex;
            flex-direction: column;
            padding: responsive(50, 100) 80px;
            @media screen and (max-width: $tablet) {
              padding: responsive(50, 100) responsive(20, 80);
            }
            h2 {
              font-size: 48px;
              font-family: 'freight-display-pro', serif;
              line-height: 1.1;
              font-weight: 900;
              margin: 0 0 40px;
            }
            .text {
              font-size: 18px;
              line-height: 1.6;
              code {
                clear: both;
                margin: 5px 20px 0 0;
                padding: 0 0 4px;
                float: left;
                font-family: inherit;
                font-size: 600%;
                line-height: .8;
                font-weight: bold;
                text-transform: uppercase;
                border-bottom: 10px solid #000;
              }
              h1, h2, h3, h4, h5, h6, p, ul, ol, a {
                line-height: 1.3;
                font-weight: 300;
                margin: 0 0 20px;
              }
              h1, h2, h3, h4, h5, h6 {
                font-size: responsive(20, 26);
                margin: 0 0 10px;
                font-weight: 700;
                line-height: 1.2;
                text-transform: uppercase;
              }
              a {
                text-decoration: underline;
              }
              ul {
                list-style-type: disc;
                padding: 0 0 0 20px;
              }
              ol {
                list-style-type: decimal;
                padding: 0 0 0 20px;
              }
              p {
                line-height: 1.6;
              }
            }
            a {
              display: flex;
              margin: 40px 0 0;
              &.btn {
                gap: 50px;
                padding: 16px 20px;
                border: none;
              }
              &.link {
                align-self: flex-end;
              }
            }
          }
          figure {
            position: relative;
            height: 100%;
            .caption {
              position: absolute;
              color: #fff;
              font-size: 14px;
              bottom: 20px;
              left: 20px;
              max-width: 90%;
              span {
                background-color: #000;
                padding: 0 5px;
              }
            }
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        @media screen and (max-width: $mobile) {
          grid-template-columns: 100%;
        }
      }
    }

    &-divider {
      display: flex;
      width: 100%;
      &[data-size="small"]{
        .divider {
          width: calc(100% / 8);
        }
      }
      &[data-size="big"]{
        padding: 0 0 80px;
      }
      &[data-position="center"]{
        justify-content: center;
      }
      &[data-position="left"]{
        justify-content: flex-start;
      }
      &[data-position="right"]{
        justify-content: flex-end;
      }
      .divider {
        width: 100%;
        display: flex;
        &[data-thickness="super_small"]{
          height: 1px;
        }
        &[data-thickness="small"]{
          height: 3px;
        }
        &[data-thickness="medium"]{
          height: 5px;
        }
        &[data-thickness="big"]{
          height: 10px;
        }
      }
    }

    &-titleHat {
      h2 {
        margin: 0 0 32px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
      }
    }

    &-code {
      h2 {
        margin: 0 0 32px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.2;
        text-transform: uppercase;
      }
    }

    &-button {
      a {
        display: flex;
        gap: 56px;
        align-items: center;
        justify-content: space-between;
        background-color: #000;
        padding: 20px 22px 20px 32px;
        width: fit-content;
        margin: 0 auto;
        color: #fff;
        font-weight: 700;
      }
    }

    // ============================= REVER ESSES ITEMS

    &-author {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      div {
        display: flex;
        align-items: center;
        gap: 12px;
        p {
          font-family: 'freight-display-pro', serif;
          font-size: 16px;
          font-style: italic;
          font-weight: 700;
          line-height: 1.2;
          span {
            display: block;
            font-family: 'adelle-sans', sans-serif;
            font-style: normal;
          }
        }
        img {
          border-radius: 100%;
          width: 40px;
          height: 40px;
          display: block;
          object-fit: cover;
        }
        &.tags {
          justify-content: flex-end;
          flex-wrap: wrap;
        }
      }
      @media screen and (max-width: $tablet_vertical) {
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &-menu {
      a {
        display: flex;
        align-items: center;
        border-left: 8px solid #000;
        margin: 0 0 24px;
        font-weight: 700;
        span {
          display: block;
          padding: 0 20px;
        }
      }
    }
    
    // ===================================================================================
  }
}