* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  font-family: 'adelle-sans', sans-serif;
  font-size: responsive(16, 18);
  font-weight: 400;
  line-height: 1.6;
  background-color: var(--principal);
  color: var(--secundary);
  overflow-x: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    margin: 0;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }

  b {
    font-weight: 700;
  }

  ul, ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  address {
    font-style: normal;
  }

  button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    outline: 0;
    padding: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  figure {
    display: block;
    margin: 0;
    padding: 0;
    img {
      display: block;
    }
  }

  iframe {
    border: none;
    outline: none;
  }

  input {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    outline: none;
  }
}
