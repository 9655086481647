.page-about {
  .title {
    font-size: responsive(32, 48);
    font-weight: 900;
    line-height: 1.2;
    font-family: 'freight-display-pro', serif;
  }
  &-banner {
    margin: 0 0 56px;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  &-row {
    margin: 0 0 36px;
    padding: responsive(16, 30) responsive(16, 46);
    border: 1px solid var(--secundary);
    display: grid;
    gap: responsive(20, 80);
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    .title {
      font-size: 40px;
      min-width: 320px;
    }
    p {
      grid-column: span 2;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        height: calc(100% + 20px);
        width: 3px;
        background-color: var(--secundary);
        left: responsive(-10, -40);
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media screen and (max-width: $tablet) {
      grid-template-columns: repeat(1, 1fr);
      p {
        &::before {
          display: none;
        }
      }
    }
  }
  &-list {
    margin: 0 0 64px;
    ul {
      display: grid;
      gap: 28px;
      grid-template-columns: repeat(2, 1fr);
      
      li {
        padding: responsive(16, 32) responsive(16, 30);
        border: 1px solid var(--secundary);
        background-color: #F0F5F4;
        h2 {
          margin: 0 0 20px;
          padding: 0 0 12px;
          border-bottom: 1px solid var(--secundary);
        }
        &:nth-child(3n) {
          grid-column: span 2;
        }
        @media screen and (max-width: $mobile) {
          grid-column: span 2;
        }
      }
    }
   
  }
  &-columns {
    margin: 0 0 80px;
    padding: 0 responsive(0, 30);
    h2 {
      margin: 0 0 20px;
    }
    p {
      margin: 0 0 40px;
    }
    ul {
      display: grid;
      gap: 0 32px;
      grid-template-columns: repeat(3, 1fr);
      li {
        display: flex;
        align-items: center;
        padding: 28px 28px 28px 0;
        border-bottom: 1px solid var(--secundary);
        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          border-top: 1px solid var(--secundary);
        }
      }
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(1, 1fr);
        li {
          &:nth-child(1), &:nth-child(2), &:nth-child(3) {
            border-top: none;
          }
        }
      }
    }
  }
  &-team {
    margin: 0 0 80px;
    h2 {
      margin: 0 0 20px;
    }
    .list {
      display: flex;
      gap: 38px responsive(18, 38);
      margin: 0 0 80px;
      flex-wrap: wrap;
      justify-content: space-between;
      li {
        width: 30%;
      }
      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 0 50px;
      }
      h3 {
        font-weight: 700;
      }
      h3, h4 {
        font-size: 22px;
      }
      p {
        font-size: 18px;
        margin: 24px 0 0;
      }
      @media screen and (max-width: $tablet) {
        li {
          width: 45%;
        }
      }
      @media screen and (max-width: $mobile) {
        flex-direction: column;
        li {
          width: 100%;
        }
      }
    }
    .grid {
      display: grid;
      gap: 24px responsive(24, 56);
      grid-template-columns: repeat(4, 1fr);
      li {
        padding: 10px 14px;
        border: 1px solid var(--secundary);
        background-color: #F0F5F4;
        img {
          display: block;
          width: 100%;
          height: auto;
          margin: 0 0 14px;
        }
        h3 {
          font-weight: 700;
        }
      }
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  &-area {
    margin: 0 0 responsive(60, 120);
    h2 {
      margin: 0 0 20px;
      max-width: 80%;
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(3, 1fr);
        max-width: 100%;
      }
    }
    .subtitle {
      margin: 0 0 28px;
      max-width: 80%;
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(3, 1fr);
        max-width: 100%;
      }
    }
    .support {
      font-size: 18px;
      font-weight: 700;
      padding: responsive(16, 30) responsive(34, 68) responsive(16, 30) responsive(16, 44);
      border: 1px solid var(--secundary);
      display: flex;
      position: relative;
      width: fit-content;
      p {
        border-bottom: 2px solid var(--secundary);
        a {
          @extend .card-link;
        }
      }
      .arrow {
        width: 44px;
        height: 44px;
        display: block;
        background-color: var(--secundary);
        padding: 10px;
        position: absolute;
        top: 50%;
        right: -24px;
        transform: translateY(-50%);
        img {
          max-width: 24px;
        }
      }
      @media screen and (max-width: $tablet) {
        max-width: 90%;
      }
    }
    .cards {
      margin: 64px 0 0;
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      li {
        background-color: #B6CCC8;
        border: 1px solid var(--secundary);
        padding: responsive(16, 28) responsive(16, 30) responsive(16, 50) responsive(16, 28);
        img {
          margin: 0 0 24px;
          display: block;
          max-width: 100%;
        }
        h2 {
          margin: 0 0 20px;
        }
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &-box {
    margin: 0 0 80px;
    h2 {
      margin: 0 0 20px;
    }
    p {
      margin: 0 0 20px;
    }
    img {
      display: block;
      width: 100%;
      margin: 40px 0 0;
    }
    .content {
      max-width: 80%;
      @media screen and (max-width: $tablet) {
        max-width: 100%;
      }
    }
  }
}