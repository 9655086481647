.page-webstories {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 responsive(45, 90);
    .title {
      font-size: responsive(36, 86);
      font-weight: 900;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      @media screen and (max-width: $mobile) {
        margin: 0 0 20px;
      }
    }
    .filter {
      min-width: 255px;
      padding: 14px 28px;
      background-color: var(--secundary);
      color: var(--principal);
      display: flex;
      align-items: center;
      justify-content: space-between;
      span, img {
        display: block;
      }
    }
    @media screen and (max-width: $mobile) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &-container {
    .grid {
      display: grid;
      gap: responsive(10, 30) responsive(10, 60);
      grid-template-columns: repeat(4, 1fr);
      article {
        border: 1px solid var(--secundary);
        a {
          display: block;
          transition: opacity .4s ease;
          height: 100%;
          &:hover {
            opacity: .6;
          }
        }
        .thumb {
          img {
            width: 100%;
            height: 480px;
            object-fit: cover;
          }
        }
        .title {
          padding: responsive(14, 28) 14px;
          line-height: 1.4;
          font-weight: 600;
        }
      }
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
        article{
          .thumb {
            img {
              height: 320px;
            }
          }
        }
      }
    }
  }
}