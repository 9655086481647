.post {
  .progress {
    width: 0%;
    height: 8px;
    background-color: #000;
    transition: all linear 0.1s; 
    position: sticky; 
    top: 110px; 
    left: 0;
    z-index: 1;
    @media screen and (max-width: $tablet) {
      top: 76px;
    }
  }

  .hero {
    margin: 0 0 46px;
    .title {
      margin: 0 0 50px;
      font-size: responsive(32, 64);
      font-weight: 900;
      line-height: 1.1;
      font-family: 'freight-display-pro', serif;
    }
    .date {
      margin: 0 0 18px;
      font-size: 14px;
      text-transform: uppercase;
    }
    .subtitle {
      font-size: 18px;
    }
    &-text {
      max-width: 1200px;
      padding: responsive(32, 90) responsive(20, 180) 0;
      .subtitle {
        max-width: 800px;
      }
    }
    &-full {
      figcaption {
        // max-width: 1200px;
        padding: 0 responsive(20, 180) 0;
      }
    }
  }

  .box {
    background-color: #B6CCC8;
    border: 1px solid var(--secundary);
    position: relative;
    font-style: italic;
    &-content {
      display: block;
      padding: responsive(16, 24) responsive(44, 72) responsive(16, 24) responsive(16, 44);
    }
    &-btn {
      position: absolute;
      right: responsive(-10, -28);
      top: 20px;
      background-color: var(--secundary);
      width: 56px;
      height: 56px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        display: block;
        width: 50%;
      }
    }
    &.nobox {
      background-color: transparent;
      border: none;
      .box-content {
        padding: 0;
      }
    }
  }

  .author {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    justify-content: space-between;
    margin: 0 0 42px;
    >div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 14px;
      >div {
        display: flex;
        gap: 6px;
        align-items: center;
        margin: 0;
      }
      img {
        display: block;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        object-fit: cover;
        margin: 0;
      }
      p {
        font-weight: 700;
        margin: 0;
        font-size: 16px;
      }
    }
    .placeholder {
      border: 1px solid #000;
      border-radius: 100%;
      width: 35px;
      height: 35px;
      img {
        scale: 0.7;
        position: relative;
        top: 1px;
        left: -1px;
      }
    }
    .tags {
      display: flex;
      gap: 12px;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      li {
        white-space: nowrap;
      }
    }
    @media screen and (max-width: $tablet) {
      align-items: flex-start;
      grid-template-columns: 1fr;
      >div {
        margin: 0 0 20px;
      }
      .tags {
        width: 100%;
        justify-content: flex-start;
      }
    }
  }

  &-banner {
    margin: 0 0 responsive(40, 80);
    display: grid;
    gap: 0 responsive(40, 80);
    .photo {
      display: block;
      width: 100%;
    }
    figcaption {
      font-size: 14px;
      color: #615C57;
      margin: 8px 0 0;
    }
    figure {
      img {
        width: 100%;
        height: 100%;
        min-height: 270px;
        object-fit: cover;
      }
      >div {
        height: auto !important;
        max-height: 512px;
        overflow: hidden;
        display: flex;
        align-items: center;
      }
    }
    .credit {
      font-size: 12px;
      transform: scale(-1);
      writing-mode: vertical-rl;
    }
    &.column-01 {
      margin: 0 0 32px;
    }
    .hero-interview {
      padding: responsive(32, 90) responsive(20, 180) 0;
      display: grid;
      gap: 26px responsive(40, 80);
      grid-template-columns: repeat(3, 1fr);
      h1 {
        font-size: responsive(48, 72);
        font-weight: 900;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif;
        grid-column: span 3;
      }
      .date-container {
        display: block;
        text-transform: uppercase;
        margin: 0 0 26px;
      }
      .box {
        margin: 0 0 26px;
      }
      .author {
        margin: 0 0 26px;
      }
      &-content {
        grid-column: span 2;
        @media screen and (max-width: $tablet) {
          grid-column: span 3;
        }
      }
    }
    .hero-new {
      padding: responsive(32, 90) responsive(20, 180) 0;
      div {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  &-wrapper {
    display: grid;
    gap: responsive(40, 80);
    grid-template-columns: repeat(3, 1fr);
    padding: 0 responsive(20, 180) 0;
    .content {
      width: 100%;
      grid-column: span 2;
      @media screen and (max-width: $tablet) {
        grid-column: span 3;
      }
      .image-container {
        display: flex;
        gap: 26px;
        align-items: flex-start;
        margin: 0 0 32px;
        figure {
          width: 100%;
          img {
            display: block;
            width: 100%;
            height: auto;
          }
          figcaption {
            font-size: 14px;
            color: #615C57;
            margin: 8px 0 0;
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          flex-direction: column;
          figure {
            width: 100%;
          }
        }
      }
      .box {
        width: fit-content;
        margin: 0 0 66px;
        button {
          top: 50%;
          transform: translateY(-50%);
          img {
            width: 18px;
          }
        }
        cursor: pointer;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        }
        &.box-wire {
          background: none;
          position: relative;
          &:hover {
            background-color: #fff;
          }
          .box-content {
            @extend .card-link;
          }
          .box-btn {
            top: 50%;
            transform: translateY(-50%);
            width: 40px;
            height: 40px;
          }
        }
      }
      .list {
        width: fit-content;
        margin: 0 0 52px;
        li {
          gap: 80px;
          display: flex;
          justify-content: space-between;
          padding: 12px 26px;
          border-bottom: 1px solid var(--secundary);
          border-left: 1px solid var(--secundary);
          border-right: 1px solid var(--secundary);
          position: relative;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .4s ease;
          &:hover {
            background-color: #fff;
          }
          a {
            display: flex;
            flex-direction: column;
            justify-content: center;
            @extend .card-link;
          }
          &:first-of-type {
            border-top: 1px solid var(--secundary);
          }
        }
      }
      .text {
        margin: 0 0 40px;
        p {
          margin: 0 0 20px;
          font-size: responsive(18, 21);
          line-height: 1.8;
          u {
            font-weight: 700;
          }
          clear: both;
          code {
            clear: both;
            margin: 5px 20px 0 0;
            padding: 0 0 4px;
            float: left;
            font-family: inherit;
            font-size: 600%;
            line-height: .8;
            font-weight: bold;
            text-transform: uppercase;
            border-bottom: 10px solid #B6CCC8;
          }
        }
        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
        a {
          text-decoration: underline;
          transition: font-weight .4s ease;
          &:hover {
            font-weight: 700;
          }
        }
        h1, h2, h3, h4, h5, h6 {
          font-size: 18px;
          font-weight: 700;
          margin: 0 0 20px;
          text-transform: uppercase;
        }
        .wp-caption-text {
          font-size: 14px;
          color: #615C57;
          margin: 8px 0 0;
        }
        div {
          max-width: 100%;
        }
        &-box {
          background-color: #B6CCC8;
          padding: responsive(40, 50) responsive(40, 90) responsive(20, 40);
          border: 1px solid #000;
          // img {
          //   display: block;
          //   max-width: 100%;
          // }
        }
      }
      .parallax {
        position: relative;
        width: 100vw;
        margin: 0 0 80px;
        left: responsive(-20, -180);
        overflow: hidden;
        &-image {
          transition: all .4s ease;
          position: absolute;
          height: 100%;
          width: 100vw;
          background: 50% 50% / contain;
          background-attachment: fixed;
          background-repeat: no-repeat;
        }
      }
      .carousel {
        margin: 0 0 60px;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
        figcaption {
          font-size: 14px;
          color: #615C57;
          margin: 8px 0 0;
        }
        &-prev, &-next {
          width: 30px;
          height: 30px;
          background-color: #000;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            display: block;
            width: 20px;
          }
        }
        &-arrows {
          position: absolute;
          width: calc(100% + 20px);
          display: flex;
          justify-content: space-between;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .card {
        display: flex;
        margin: 0 0 80px;
        border: 1px solid var(--secundary);
        img {
          object-fit: cover;
          display: block;
          height: 100%;
          border-right: 1px solid #000;
        }
        .info {
          padding: responsive(16, 32) responsive(16, 72) responsive(16, 32) responsive(16, 32);
          display: flex;
          flex-direction: column;
          justify-content: center;
          h3 {
            margin: 0 0 18px;
            font-size: responsive(80, 106);
            font-weight: 900;
            line-height: 1;
            font-family: 'freight-display-pro', serif;
          }
          .text, .quote {
            font-size: responsive(24, 32);
            font-weight: 500;
            line-height: 1.1;
            font-family: 'freight-display-pro', serif;
            p {
              margin: 20px 0 0;
            }
            a {
              text-decoration: underline;
            }
            strong {
              font-weight: 700;
            }
          }
          .legend {
            margin: 28px 0 0;
            b {
              display: block;
            }
          }
        }
        @media screen and (max-width: $mobile) {
          flex-direction: column;
          img {
            width: 100%;
            border-right: none;
          }
        }
      }
      .columns {
        margin: 0 0 80px;
        display: flex;
        gap: 20px;
        figure {
          display: flex;
          flex-direction: column;
          width: 50%;
          img {
            display: block;
            width: 100%;
            object-fit: cover;
            height: 100%;
          }
          figcaption {
            margin: 12px 0 0;
            color: #615C57;
            font-size: 14px;
          }
          &:nth-child(2) {
            flex-direction: column-reverse;
            figcaption {
              margin: 0 0 12px;
              color: #615C57;
              font-size: 14px;
            }
          }
        }
      }
      .order {
        margin: 80px 0;
        li {
          margin: 0 0 40px;
          .index {
            width: fit-content;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 1;
            font-size: responsive(24, 42);
            font-weight: 800;
            padding: 14px;
            margin: 0 0 20px;
          }
          h3 {
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 800;
            margin: 0 0 20px;
          }
          p {
            margin: 0 0 20px;
            a {
              text-decoration: underline;
            }
          }
        }
      }
      .section-title {
        margin: 0 0 20px;
        font-size: responsive(32, 48);
        font-weight: 900;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif;
      }
      .link {
        font-weight: 700;
        display: flex;
        gap: 10px;
        margin: 0 0 40px;
        img {
          width: 16px;
        }
        span {
          display: block;
          text-decoration: underline;
        }
      }
      .code {
        margin: 0 0 80px;
      }
      .question {
        font-size: 20px;
        line-height: 1.4;
        font-weight: 700;
        margin: 0 0 20px;
      }
      .video {
        aspect-ratio: 16/9;
        position: relative;
        padding-top: 56.25%;
        margin: 0 0 80px;
        iframe {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      .answer {
        margin: 0 0 80px;
        position: relative;
        p {
          margin: 0 0 20px;
          font-size: responsive(18, 21);
          line-height: 1.8;
        }
        a {
          text-decoration: underline;
          font-weight: 700;
        }
      }
      .accordion {
        margin: 0 0 80px;
        details {
          padding: responsive(16, 24) responsive(24, 56);
          background-color: #B6CCC8;
          border: 1px solid var(--secundary);
          border-top: none;
          cursor: pointer;
          transition: background-color .4s ease;
          &:hover {
            background-color: #fff;
          }
          &:first-of-type {
            border-top: 1px solid var(--secundary);
          }
          summary {
            font-size: 24px;
            font-weight: 900;
            line-height: 1.2;
            font-family: 'freight-display-pro', serif;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            gap: 20px;
            .title {
              max-width: 80%;
            }
          }
          p {
            margin: 0 0 20px;
          }
          &[open] {
            summary {
              margin: 0 0 24px;
              .icon {
                &::after {
                  opacity: 0;
                }
              }
            }
          }
        }
      }
      .image-banner {
        margin: 0 0 80px;
        img {
          display: block;
          width: auto;
          max-width: 100%;
          height: auto;
        }
        figcaption {
          font-size: 14px;
          color: #615C57;
          margin: 8px 0 0;
        }
      }
      blockquote {
        display: flex;
        gap: 12px;
        align-items: flex-start;
        margin: 0 0 80px;
        font-size: responsive(24, 42);
        font-weight: 900;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif;
        padding: 0 responsive(20, 100);
        img {
          width: responsive(40, 68);
        }
        &.highlight {
          margin: 0 responsive(20, 120) 80px;
          border-top: 1px solid var(--secundary);
          border-bottom: 1px solid var(--secundary);
          padding: 44px 0;
        }
        &.card {
          border: 1px solid var(--secundary);
          padding: responsive(20, 38) responsive(75, 150) responsive(20, 38) responsive(40, 60);
          margin: 0 0 80px;
          flex-direction: column;
          h3 {
            font-size: responsive(70, 140);
            line-height: .7;
          }
          p {
            font-weight: 600;
            &:first-of-type {
              margin: 40px 0 0;
            }
          }
        }
      }
      .dot {
        margin: 0 0 80px;
        width: 20px;
        height: 20px;
        background: #000;
        border-radius: 100%;
      }
    }
    .sidebar {
      grid-column: span 1;
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      ul {
        width: 100%;
        border: 1px solid var(--secundary);
        margin: 0 0 32px;
        li {
          padding: responsive(16, 18) responsive(16, 92) responsive(16, 18) responsive(16, 20);
          border-top: 1px solid var(--secundary);
          position: relative;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .4s ease;
          &:hover {
            background-color: #fff;
          } 
          h2 {
            font-size: 18px;
            font-weight: 800;
          }
          a {
            display: block;
            @extend .card-link;
          }
          span {
            display: block;
            opacity: .4;
            font-weight: 700;
          }
          p {
            line-height: 1.4;
          }
          &:first-of-type {
            padding: 14px 20px;
            background-color: #B6CCC8;
            border-top: none;
          }
        }
        &.list {
          border: none;
          margin: 0 0 60px;
          li {
            padding: 18px 0;
            border-top: none;
            border-bottom: 1px dashed var(--secundary);
            a {
              display: flex;
              gap: 52px;
              justify-content: space-between;
            }
            h2 {
              font-size: 32px;
              font-weight: 700;
              line-height: 1.2;
              font-family: 'freight-display-pro', serif;
            }
            &:first-of-type {
              padding: 0 0 18px;
              background-color: transparent;
              border-top: none;
              border-bottom: 1px solid var(--secundary);
            }
            &:last-of-type {
              border-bottom: 1px solid var(--secundary);
            }
          }
        }
      }
      .related {
        h2 {
          font-size: 32px;
          font-weight: 700;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
          margin: 0 0 18px;
        }
        article {
          padding: responsive(16, 24) responsive(16, 20);
          margin: 0 0 24px;
          border: 1px solid var(--secundary);
          position: relative;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .4s ease;
          &:hover {
            background-color: #fff;
          } 
          a {
            display: block;
            @extend .card-link;
          }
          figure {
            border-bottom: 1px solid var(--secundary);
            display: block;
            padding: 0 0 20px;
            margin: 0 0 20px;
            img {
              display: block;
              width: 100%;
              object-fit: cover;
            }
          }
          .tag {
            margin: 0 0 20px;
            position: relative;
            z-index: 1;
          }
          h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            font-family: 'freight-display-pro', serif;
            margin: 0 0 44px;
          }
          .author-container {
            img {
              width: 50px;
              height: auto;
            }
          }
        }
      }
      .support-card {
        margin: 0 0 40px;
        padding: 40px 24px;
        border: 1px solid var(--secundary);
        background-color: #B6CCC8;
        position: relative;
        cursor: pointer;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
          .btn {
            opacity: 1;
          }
        } 
        img {
          display: block;
          max-width: 100%;
          margin: 0 0 24px;
        }
        h2 {
          font-size: 32px;
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
          margin: 0 0 12px;
        }
        p {
          margin: 0 0 24px;
        }
        a {
          padding: 20px 60px;
          background-color: var(--secundary);
          color: #fff;
          width: 100%;
          @extend .card-link;
        }
      }
      .news-box {
        width: 100%;
        margin: 0 0 40px;
        h2 {
          display: block;
          font-size: 32px;
          font-weight: 900;
          line-height: 1.2;
          font-family: 'freight-display-pro', serif;
          margin: 0 0 24px;
        }
        form {
          border-bottom: 1px solid var(--secundary);
          padding: 0 0 12px;
          .label-name {
            display: none;
          }
          label {
            display: block;
          }
          p {
            position: relative;
            height: 50px;
          }
          input {
            display: block;
            width: 100%;
            height: 50px;
            &::placeholder {
              font-style: italic;
            }
            &[type="submit"] {
              text-indent: -9999999px;
              background-image: url($img + 'arrow-black.svg');
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;
              width: 28px;
              height: 28px;
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }
      @media screen and (max-width: $tablet) {
        grid-column: span 3;
      }
    }
  }

  &-footer {
    padding: 0 responsive(20, 180) 0;
    margin: responsive(40, 120) 0 0;
    h3 {
      font-size: responsive(16, 24);
      font-weight: 800;
      text-transform: uppercase;
      margin: 0 0 32px;
      grid-column: span 2;
      @media screen and (max-width: $tablet) {
        grid-column: span 3;
      }
    }
    .list {
      grid-column: span 2;
      gap: responsive(22, 44);
      display: flex;
      justify-content: space-between;
      padding: 0 0 56px;
      border-bottom: 1px solid var(--secundary);
      @media screen and (max-width: $tablet) {
        grid-column: span 3;
      }
      li {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: responsive(16, 34) responsive(16, 28);
        border: 1px solid var(--secundary);
        width: 100%;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        } 
        figure {
          position: relative;
          img {
            display: block;
            width: 100%;
            height: auto;
            border: 1px solid var(--secundary);
            object-fit: cover;
          }
          .tag {
            position: absolute;
            padding: 8px 10px;
            top: 16px;
            left: 16px;
            font-size: 10px;
            z-index: 1;
          }
        }
        h4 {
          font-size: 18px;
          font-weight: 700;
          margin: 16px 0 32px;
        }
        .info {
          gap: 10px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          a {
            @extend .card-link;
          }
        }
      }
      @media screen and (max-width: $mobile) {
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; 
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          min-width: 260px;
        }
      }
    }
    .authors {
      margin: 46px 0;
      .author {
        grid-column: span 2;
        margin: 32px 0 0;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        >img {
          min-width: responsive(42, 84);
          width: responsive(42, 84);
          height: responsive(42, 84);
          object-fit: cover;
          margin: 0 26px 0 0;
          border-radius: 100%;
        }
        .description {
          width: 80%;
          p {
            font-weight: 500;
          }
        }
        .placeholder {
          margin: 0 26px 0 0;
          width: responsive(42, 84);
          height: responsive(42, 84);
          border: responsive(1, 2) solid #000; 
          border-radius: 100%; 
          position: relative;
          >img {
            min-width: responsive(42, 84);
            width: responsive(42, 84);
            height: responsive(42, 84);
            object-fit: cover;
            border-radius: 100%;
            scale: 0.8;
            position: absolute;
            top: 2px;
            left: -1px;
          }
        }
        h3 {
          margin: 6px 0 12px;
        }
        p {
          margin: 0 0 20px;
        }
        .social-container {
          display: flex;
          align-items: center;
          h4 {
            font-weight: 700;
            margin: 0 12px 0 0;
          }
        }
        &:first-of-type {
          margin: 0;
        }
        @media screen and (max-width: $tablet) {
          grid-column: span 3;
        }
      }
    }
    .support-container {
      grid-column: span 2;
      @media screen and (max-width: $tablet) {
        grid-column: span 3;
      }
    }
  }
}