.page-towatch {
  .title {
    font-size: responsive(32, 48);
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 40px;
    font-family: 'freight-display-pro', serif;
    a {
      @extend .card-link;
    }
  }
  
  .thumb {
    a {
      display: block;
      height: 100%;
    }
  }
  .grid {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);
    article {
      border: 1px solid var(--secundary);
      .thumb {
        height: 220px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    @media screen and (max-width: $tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: $mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &-hero {
    margin: 0 0 80px;
    display: flex;
    gap: 48px;
    justify-content: space-between;
    position: relative;
    figure {
      width: 60%;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
      figcaption {
        font-size: 14px;
        margin: 12px 0 0;
        color: #615C57;
        font-style: italic;
      }
    }
    .content {
      width: 40%;
      p {
        line-height: 1.8;
      }
    }
    @media screen and (max-width: $tablet) {
      flex-direction: column;
      figure {
        width: 100%;
      }
      .content {
        width: 100%;
      }
    }
  }
  &-gallery {
    margin: 0 0 80px;
    .grid {
      article {
        position: relative;
      }
      .content {
        padding: 20px 12px;
        h3 {
          max-width: 230px;
          a {
            @extend .card-link;
          }
        }
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
        article {
          display: none;
        }
        article {
          &:first-of-type {
            display: block;
          }
        }
      }
    }
  }
  &-news {
    margin: 0 0 80px;
    .title {
      margin: 0 0 6px;
    }
    .subtitle {
      margin: 0 0 40px;
    }
    .grid {
      gap: 20px;
      grid-template-columns: repeat(4, 1fr);
      article {
        padding: 0 14px 18px;
        position: relative;
        h3 {
          font-weight: 600;
          margin: 14px 0 16px;
          a {
            @extend .card-link;
          }
        }
      }
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
        .thumb {
          display: none;
        }
      }
    }
  }
}