.page-category {
  h1 {
    font-family: 'freight-display-pro', serif;
    font-size: responsive(40, 86);
    font-weight: 900;
    line-height: 1.2;
    margin: 0 0 responsive(40, 64);
    text-align: center;
  }
  .container {
    margin: 0 0 70px;
  }
  &-search {
    form {
      position: relative;
      input {
        width: 100%;
        color: var(--secundary);
        background-color: #F0F5F4;
        border: 1px solid var(--secundary);
        padding: 20px 32px;
        &::-webkit-input-placeholder {
          font-style: italic;
          color: #8A999A;
        }
        &::-moz-placeholder {
          font-style: italic;
          color: #8A999A;
        }
        &:-ms-input-placeholder {
          font-style: italic;
          color: #8A999A;
        }
        &:-moz-placeholder {
          font-style: italic;
          color: #8A999A;
        }
      }
      button {
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        width: 34px;
      }
    }
  }
  &-highlight {
    display: flex;
    gap: 34px;
    .box {
      width: 100%;
      border: 1px solid var(--secundary);
      padding: responsive(16, 40) responsive(16, 32);
      display: flex;
      position: relative;
      .thumb {
        position: relative;
        width: 40%;
        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
        }
        .tag {
          white-space: nowrap;
          position: absolute;
          bottom: 16px;
          left: 50%;
          transform: translateX(-50%);
          border: 1px solid var(--secundary);
          background-color: #B6CCC8;
          color: var(--secundary);
          z-index: 1;
        }
      }
      .content {
        width: 60%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        border-left: 1px solid var(--secundary);
        padding-left: 36px;
        margin-left: 24px;
        .title {
          font-family: 'freight-display-pro', serif;
          font-size: responsive(32, 48);
          font-weight: 600;
          line-height: 1.1;
          margin: 0 0 20px;
        }
        .resume {
          margin: 0 0 30px;
        }
        .link-to-page {
          @extend .card-link;
        }
        .author-container {
          position: relative;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
      @media screen and (max-width: $mobile) {
        flex-direction: column;
        .thumb {
          width: 100%;
        }
        .content {
          border: none;
          width: 100%;
          padding-left: 0;
          margin: 20px 0 0;
        }
      }
    }
    .list {
      min-width: 330px;
      max-width: 350px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-title {
        padding: 0 0 12px;
        font-size: 32px;
        font-weight: 700;
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        border-bottom: 1px solid var(--secundary);
      }
      &-item {
        display: flex;
        gap: 50px;
        align-items: center;
        justify-content: space-between;
        padding: 16px 10px;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.4;
        height: 100%;
        border-bottom: 1px dashed var(--secundary);
      }
      @media screen and (max-width: $tablet_vertical) {
        max-width: 100%;
      }
    }
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }
  &-article {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    article {
      border: 1px solid var(--secundary);
      display: flex;
      flex-direction: column;
      position: relative;
      &:first-of-type {
        display: none;
      }
      .thumb {
        position: relative;
        min-width: 300px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .tag {
          position: absolute;
          bottom: 16px;
          left: 16px;
          border: 1px solid var(--secundary);
          background-color: #B6CCC8;
          color: var(--secundary);
          z-index: 1;
        }
      }
      .content {
        padding: responsive(16, 28) responsive(16, 24);
        height: 100%;
        display: flex;
        flex-direction: column;
        box-sizing: content-box;
        justify-content: space-between;
        >div {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          margin: 0 0 26px;
        }
        h2 {
          font-family: 'freight-display-pro', serif;
          font-size: responsive(24, 32);
          font-weight: 900;
          line-height: 1.1;
        }
        .link {
          align-self: flex-end;
          img {
            display: block;
          }
        }
        .link-to-page {
          @extend .card-link;
        }
        .author-container {
          margin: 0;
          position: relative;
          img {
            object-fit: cover;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
    @media screen and (max-width: $tablet) {
      display: flex;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; 
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &-news {
    &-interviews {
      display: grid;
      gap: 20px;
      grid-template-columns: repeat(4, 1fr);
      article {
        padding: 14px;
        border: 1px solid var(--secundary);
        position: relative;
        a {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        }
        img {
          display: block;
          width: 100%;
          height: 200px;
          object-fit: cover;
          &.aspas {
            display: none;
          }
        }
        h3 {
          line-height: 1.4;
          font-weight: 600;
          margin: 14px 0 16px;
        }
        time {
          font-size: 12px;
        }
        p {
          display: none;
          font-size: 12px;
        }
        .link-to-page {
          @extend .card-link;
        }
        &:first-of-type {
          padding: 40px 40px 15px 30px;
          border: 1px solid var(--secundary);
          grid-column: span 2;
          grid-row: span 2;
          a {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
          time {
            display: none;
          }
          img {
            width: 40px;
            margin: 0 0 16px;
            display: none;
            &.aspas {
              display: block;
              width: 50px;
              height: auto;
            }
          }
          h3 {
            margin: 0 0 46px;
            font-size: responsive(32, 64);
            font-weight: 700;
            line-height: 1.1;
            font-family: 'freight-display-pro', serif;
          }
          p {
            display: block;
            font-size: responsive(16, 18);
          }
          @media screen and (max-width: $tablet) {
            padding: 40px 40px 40px 30px;
          }
        }
      }
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
        article {
          grid-column: span 1;
          img {
            display: none;
          }
          &:first-of-type {
            grid-column: span 1;
          }
          &:nth-child(2) {
            img {
              display: block;
              &.aspas {
                display: none;
              }
            }
          }
        }
      }
    }
    .btn {
      display: flex;
      gap: 14px;
      border: none;
      margin: 64px auto 0;
      background-color: var(--secundary);
      padding: 12px 88px;
      .icon {
        &::before, &::after {
          background-color: var(--principal);
        }
      }
    }
  }
  &-webstories {
    &-list {
      position: relative;
      .list {
        .item {
          position: relative;
          border: 1px solid var(--secundary);
          min-width: 224px;
          max-width: 224px;
          width: 100%;
          height: 485px;
          a {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tag {
            position: absolute;
            top: 10px;
            right: 12px;
            color: var(--secundary);
            border: 1px solid var(--secundary);
            background-color: #B6CCC8;
          }
          .infos {
            background-color: var(--principal);
            color: var(--secundary);
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            padding: 10px 20px;
            width: 100%;
            border-top: 1px solid var(--secundary);
            min-height: 77px;
            font-weight: 600;
          }
        }
      }
      .btn {
        position: absolute;
        top: 55%;
        right: responsive(-20, -100);
        background-color: var(--secundary);
        color: #B6CCC8;
        border: none;
        padding: 20px 26px;
      }
      .list-nav {
        margin: 10px 0 0;
        width: 100%;
        display: flex;
        gap: 28px;
        align-items: center;
        justify-content: flex-end;
        &-arrow {
          background-color: #000;
          width: 64px;
          height: 64px;
          img {
            width: 50%;
          }
          transition: opacity .4s ease;
          &:hover {
            opacity: .6;
          }
        }
      }
      @media screen and (max-width: $tablet) {
        display: flex;
        flex-direction: column;
        .btn {
          position: unset;
          margin: 40px 0 0;
          order: 3;
        }
        .list-nav {
          justify-content: flex-start;
        }
      }
    }
  }
  &-carousel {
    margin: 0 0 44px;
    position: relative;
    .highlight {
      background-color: #F0F5F4;
      &-container {
        width: calc(100% - 1px);
        display: flex;
        gap: responsive(28, 56);
        border: 1px solid var(--secundary);
        padding: responsive(16, 54) responsive(16, 42);
      }
      .thumb {
        position: relative;
        width: 40%;
        height: 430px;
        img {
          display: block;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        .tag {
          left: 28px;
          bottom: 28px;
          position: absolute;
          color: var(--secundary);
          border: 1px solid var(--secundary);
          background-color: #B6CCC8;
        }
        @media screen and (max-width: $mobile) {
          width: 100%;
        }
      }
      .content {
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-size: responsive(32, 42);
          line-height: 1.2;
          font-weight: 700;
          margin: 0 0 responsive(12, 24);
        }
        @media screen and (max-width: $mobile) {
          width: 100%;
        }
      }
      .bottom {
        margin: 20px 0 0;
        display: flex;
        justify-content: space-between;
        .author-container {
          img {
            display: block;
            object-fit: cover;
            width: 40px;
            height: 40px;
          }
        }
        @media screen and (max-width: $mobile) {
          flex-direction: column;
        }
      }
      @media screen and (max-width: $mobile) {
        flex-direction: column;
      }
    }
    .carousel-nav {
      display: flex;
      gap: 28px;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      bottom: responsive(16, 54);
      right: responsive(16, 42);
      z-index: 3;
      &-arrow {
        background-color: #000;
        width: 64px;
        height: 64px;
        img {
          width: 50%;
        }
      }
      &-prev {
        background-color: #8A999A;
      }
      @media screen and (max-width: $mobile) {
        margin: 20px 0 0;
        justify-content: flex-start;
      }
    }
  }
}