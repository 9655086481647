.page-apoie {
  section {
    margin: 0 0 80px;
  }

  .title {
    font-size: responsive(32, 48);
    font-weight: 900;
    line-height: 1.1;
    font-family: 'freight-display-pro', serif;
  }

  &-banner {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &-about {
    max-width: 860px;
    .title {
      margin: 0 0 10px;
    }
    .subtitle {
      font-weight: 700;
      font-size: responsive(20, 30);
      margin: 0 0 20px;
    }
    .text {
      p {
        margin: 0 0 20px;
      }
      a {
        display: inline-block;
        background-color: var(--secundary);
        color: #fff;
        font-weight: 700;
        padding: 2px 4px;
      }
      ul {
        margin: 0 0 20px;
      }
      span {
        font-size: 14px;
        text-decoration: none !important;
      }
    }
  }

  &-support {
    .title {
      margin: 0 0 52px;
    }
    .cards {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 28px;
      @media screen and (max-width: $tablet_vertical) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
      li {
        padding: 40px 28px;
        background-color: #B6CCC8;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-size: 24px;
          font-weight: 700;
          margin: 0 0 20px;
        }
        .text {
          margin: 0 0 20px;
          a {
            font-weight: 700;
            text-decoration: underline;
          }
        }
        .qrcode {
          display: block;
          img {
            display: block;
            width: auto;
            margin: 0 auto;
          }
        }
        .price {
          font-size: 16px;
          font-weight: 900;
          margin: 0 0 20px;
          font-family: 'freight-display-pro', serif;
        }
        .btn {
          background-color: var(--secundary);
          color: #fff;
          border: none;
          padding: 16px 20px;
          width: 100%;
          justify-content: space-between;
        }
      }
    }
  }

  &-rewards {
    .title {
      margin: 0 0 32px;
    }
    .list {
      display: flex;
      gap: 24px;
      margin: 0 0 20px;
      li {
        img {
          display: block;
          width: auto;
          max-width: 100%;
          height: auto;
        }
      }
    }
    .legend {
      font-size: 12px;
      margin: 0 0 42px;
    }
    .text {
      max-width: 960px;
    }
  }

  &-graphic {
    .title {
      margin: 0 0 32px;
    }
    img {
      display: block;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  &-calltoaction {
    max-width: 960px;
    .title {
      margin: 0 0 40px;
    }
    img {
      display: block;
      width: auto;
      max-width: 100%;
      margin: 0 0 40px;
      height: auto;
    }
    .text {
      margin: 0 0 60px;
    }
    .btn-container {
      position: relative;
      p {
        line-height: 1.4;
        font-size: responsive(20, 24);
        font-weight: 700;
        padding: 28px responsive(32, 62);
        background: rgba(255, 255, 255, 0.39);
      }
      .btn {
        background-color: var(--secundary);
        color: #fff;
        border: none;
        padding: 16px 20px;
        width: 100%;
        justify-content: space-between;
        position: absolute;
        right: 28px;
        top: calc(100% - 14px);
        max-width: 260px;
        img {
          margin: 0;
        }
      }
    }
  }

  &-list {
    max-width: 960px;
    .content {
      padding: 48px 28px;
      background-color: #B6CCC8;
    }
    .title {
      margin: 0 0 52px;
    }
    .list {
      margin: 0 0 20px;
    }
    h5 {
      font-family: 'freight-display-pro', serif;
      font-weight: 700;
      font-style: italic;
      margin: 60px auto 0;
      max-width: 90%;
    }
  }
}