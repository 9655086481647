.page-republique {
  section {
    max-width: 984px;
    margin: 0 0 80px;
    .title {
      font-size: responsive(32, 48);
      font-weight: 900;
      line-height: 1.1;
      font-family: 'freight-display-pro', serif;
      margin: 0 0 32px;
    }
    .grid {
      display: grid;
      li {
        .content {
          padding: 24px;
          border: 1px solid var(--secundary);
          margin: 0 0 20px;
          p {
            text-transform: uppercase;
            font-size: 16px;
            margin: 0 0 14px;
          }
          h3 {
            font-size: responsive(24, 32);
            line-height: 1.2;
            font-weight: 800;
          }
        }
        a {
          display: block;
          text-align: center;
        }
        img {
          display: block;
          max-width: 100%;
          height: auto;
        }
        .btn {
          background-color: var(--secundary);
          color: #ffff;
          border: none;
          padding: 12px;
          width: 100%;
        }
      }
    }
  }
  &-clipping {
    .grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 32px 20px;
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  &-partners {
    .grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 12px 20px;
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}