// Newsletter
.newsletter {
  margin: 0 0 96px;
  position: relative;
  width: 80%;
  .container {
    width: calc(100% - 125px);
    padding: 28px 30px;
    border: 1px solid var(--secundary);
    background-color: #F0F5F4;
    position: relative;
    h2 {
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      font-size: 36px;
      font-weight: 700;
      margin: 0 0 12px;
      .link {
        @extend .card-link;
      }
    }
    p {
      max-width: calc(100% - 125px);
    }
  }
  .btn {
    position: absolute;
    right: 0px;
    top: 50%;
    color: #F0F5F4;
    transform: translateY(-50%);
    border: 1px solid var(--secundary);
    background-color: var(--secundary);
  }
  @media screen and (max-width: $tablet) {
    width: 100%;
  }
  @media screen and (max-width: $mobile) {
    width: 100%;
    .container {
      width: 100%;
      padding: 28px 30px calc(28px + 35px);
      p {
        max-width: 100%;
      }
    }
    .btn {
      right:auto;
      top: auto;
      left: 50%;
      bottom: -35px;
      transform: translateX(-50%);
      white-space: nowrap;
    }
  }
}

// Button
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  padding: 20px 12px 20px 32px;
  border: 1px solid var(--principal);
  color: var(--principal);
  width: fit-content;
  img {
    margin-left: 24px;
  }
  transition: opacity .4s ease;
  &:hover {
    opacity: .6;
  }
  &-black {
    background-color: #000;
  }
  &-padding {
    padding: 20px;
  }
}

// Dots
.dots {
  display: block;
  width: 28px;
  height: 48px;
  position: relative;
  cursor: pointer;
  &::after, &::before, .dot {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    background-color: #000;
    border-radius: 100%;
    left: 50%;
    translate: -50% 0;
  }
  .dot {
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }
  &::after {
    top: 0;
  }
  &::before {
    bottom: 0;
  }
}

// Share content
.share-content {
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  z-index: 1;
  .social {
    position: absolute;
    z-index: 2;
    top: 0;
    left: calc(100% + 20px);
    flex-direction: column;
    li {
      opacity: 0;
      visibility: hidden;
      transition: opacity .4s ease;
      a {
        width: 52px;
        height: 52px;
        background-color: #B6CCC8;
        .a2a_svg {
          svg {
            height: auto;
            path {
              fill: #000;
            }
          }
        }
      }
    }
    @for $i from 1 through 4 {
      li:nth-child(#{$i}) {
        transition-delay: calc(100ms * #{$i});
      }
    }
  }
  &.active {
    .social {
      li {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

// Social
.social {
  display: flex;
  align-items: center;
  gap: 8px;
  li {
    a {
      overflow: hidden;
      font-size: 11px;
      font-weight: 700;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      color: var(--principal);
      background-color: var(--secundary);
      transition: opacity .4s ease;
      &:hover {
        opacity: .6;
      }
      .a2a_svg {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent !important;
        svg {
          height: 20px;
        }
      }
      img {
        display: block;
        margin: 0 !important;
        width: auto !important;
        max-width: 100%;
        height: auto !important;
        border-radius: 0 !important;
      }
    }
  }
}

// Autor
.author-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  img {
    border-radius: 100%;
  }
  p {
    line-height: 1.4;
    margin: 0 0 0 12px;
  }
  a {
    display: inline-block;
  }
  >div {
    display: flex;
    align-items: center;
    margin: 12px 12px 0 0;
  }
}

// Date
.date {
  text-transform: uppercase;
  margin: 0 0 20px;
  display: block;
}

// Icon
.icon {
  display: block;
  position: relative;
  width: 25px;
  height: 25px;
  &::before, &::after {
    content: '';
    position: absolute;
    background-color: var(--secundary);
    width: 100%;
    height: 8px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &::after {
    height: 100%;
    width: 8px;
  }
}

// ScrollTop
.scrollTop {
  position: absolute;
  right: responsive(24, 100);
  bottom: calc(90px + 36px);
  background-color: #9BC1BA;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: responsive(42, 82);
  height: responsive(42, 82);
  cursor: pointer;
  transition: opacity .4s ease;
  &:hover {
    opacity: .6;
  }
  img {
    width: 50%;
  }
  @media screen and (max-width: $tablet) {
    position: unset;
  }
}

// Apoie container
.support-container {
  padding: responsive(16, 52) responsive(16, 62) 52px;
  position: relative;
  background-color: #F0F5F4;
  margin: 0 0 70px;
  h2 {
    line-height: 1.4;
    font-size: responsive(20, 26);
    font-weight: 800;
    margin: 0 0 20px;
  }
  p {
    margin: 0 0 10px;
  }
  .btn {
    display: flex;
    gap: 60px;
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    background-color: var(--secundary);
    color: #B6CCC8;
    border: none;
    padding: 20px 26px 20px 32px;
  }
}

// Tag
.tag {
  display: block;
  color: #fff;
  font-size: responsive(10, 14);
  font-weight: 700;
  padding: 8px 28px;
  background-color: var(--secundary);
  width: fit-content;
  text-transform: uppercase;
  transition: opacity .4s ease;
  cursor: pointer;
  &:hover {
    opacity: .8;
  }
}
