.page-newsletter {
  .content {
    .title {
      font-size: responsive(32, 48);
      font-weight: 900;
      line-height: 1.1;
      font-family: 'freight-display-pro', serif;
      margin: 0 0 32px;
      max-width: 960px;
    }
    .text {
      margin: 0 0 52px;
      max-width: 960px;
    }
    form {
      background-color: var(--secundary);
      color: #fff;
      padding: 10px;
      .label-name {
        display: none;
      }
      label {
        display: block;
      }
      p {
        position: relative;
        height: 50px;
      }
      input {
        height: 50px;
        display: block;
        width: 100%;
        padding: 12px 22px;
        border: 1px solid #B6CCC8;
        color: #B6CCC8;
        &::placeholder {
          color: #B6CCC8;
          font-style: italic;
        }
      }
      button, [type="submit"] {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 32px;
        width: 32px;
        height: 36px;
        text-indent: -999999px;
        background-image: url($img + 'arrow-right-blue.svg');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 0;
        border: none;
        // img {
        //   display: block;
        //   width: 100%;
        //   height: auto;
        // }
      }
    }
  }
}