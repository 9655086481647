$img: '../assets/';

$tablet: '1024px';
$tablet_vertical: '1000px';
$mobile: '700px';

// Colors
:root {
  --principal: #E1EAE9;
  --secundary: #000000;
}

//Responsive
@function responsive($value1, $value2, $width1: 375, $width2: 1920) {
  $x1: $width1;
  $x2: $width2;
  $y1: $value1;
  $y2: $value2;
  $a: ($y2 - $y1) / ($x2 - $x1);
  $b: ($x2 * $y1 - $x1 * $y2) / ($x2 - $x1);
  @return calc(#{$a * 100vw} + #{$b * 1px});
}

// Fonts
@mixin font-title {
  font-size: 36px;
  font-weight: 300;
  line-height: 1.2;
  font-family: 'freight-display-pro', serif;
}

// Spaces
@mixin padding($vertical: 0, $horizontal: 78) {
  padding: #{$vertical + 'px'} #{$horizontal + 'px'};
}