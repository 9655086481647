.home {
  --principal: #B6CCC8;
  .header {
    &-submenu {
      &-category {
        display: flex;
        @media screen and (max-width: $tablet) {
          display: none;
        }
      }
      &-share {
        display: none;
      }
    }
  }
}

.page-home {
  margin: responsive(20, 84) 0 0;
  .title {
    margin: 0 0 32px;
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    a {
      display: inline-block;
      transition: opacity .4s ease;
      padding: 0 0 2px;
      border-bottom: 2px solid #000;
      &:hover {
        opacity: .4;
      }
    }
  }

  &-hero {
    display: flex;
    justify-content: space-between;
    gap: 28px;
    padding: 0 responsive(20, 70);
    margin: 0 0 70px;
    &-highlight {
      padding: responsive(16, 32);
      border: 1px solid var(--secundary);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      cursor: pointer;
      background-color: transparent;
      transition: background-color .4s ease;
      &:hover {
        background-color: #fff;
      } 
      @media screen and (max-width: $tablet) {
        width: 100%;
      }
      figure {
        position: relative;
        img {
          display: block;
          width: 100%;
          min-height: 200px;
          object-fit: cover;
          max-height: 50vh;
          @media screen and (max-width: $tablet) {
            height: 30vh;
          }
        }
        figcaption {
          position: absolute;
          z-index: 2;
          top: 20px;
          right: 20px;
          cursor: pointer;
          transition: opacity .4s ease;
          &:hover {
            opacity: .6;
          }
        }
      }
      h2 {
        font-size: responsive(32, 48);
        font-weight: 700;
        line-height: 1.1;
        font-family: 'freight-display-pro', serif;
        margin: 38px 0 10px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        span {
          display: block;
          max-width: 930px;
          width: 90%;
        }
        a {
          display: block;
          img {
            width: 28px;
          }
          @extend .card-link;
        }
      }
      p {
        max-width: 930px;
        width: 90%;
        &.legend {
          text-transform: uppercase;
          margin: responsive(16, 40) 0 0;
          font-size: 12px;
        }
        @media screen and (max-width: $mobile) {
          display: none;
          &.legend {
            display: block;
          }
        }
      }
    }
    &-statistic {
      width: 30%;
      min-width: 30%;
      display: flex;
      gap: 32px;
      flex-direction: column;
      justify-content: space-between;
      @media screen and (max-width: $tablet) {
        width: 100%;
      }
      .happening {
        border: 1px solid var(--secundary);
        height: 100%;
        &-header {
          padding: 14px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          h2 {
            font-size: 18px;
            font-weight: 700;
            text-transform: uppercase;
          }
          time {
            font-size: 16px;
            text-transform: uppercase;
          }
          .link-date {
            display: flex;
            align-items: center;
            gap: 20px;
            a {
              display: flex;
              align-items: center;
            }
          }
        }
        .code {
          overflow: hidden;
          padding: 12px 20px;
          border-top: 1px solid var(--secundary);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
      .link {
        padding: 20px;
        border: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        } 
        h2 {
          font-size: 36px;
          font-weight: 700;
          line-height: 1;
          margin: 0 0 10px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }
        p {
          font-weight: 600;
        }
        a {
          @extend .card-link;
        }
      }
    }
    @media screen and (max-width: $tablet) {
      flex-direction: column;
    }
  }

  &-carousel {
    padding: 0 0 0 responsive(20, 70);
    margin: 0 0 70px;
    position: relative;
    @media screen and (max-width: $tablet) {
      padding: 0 responsive(20, 70);
    }
    .slider {
      li {
        padding: 0 28px 0 0;
        @media screen and (max-width: $tablet_vertical) {
          padding: 0 0 0 0;
        }
        .content {
          position: relative;
          display: flex !important;
          flex-direction: column;
          gap: 20px;
          padding: responsive(16, 32);
          background-color: #F0F5F4;
          flex: 0 0 auto; 
          width: calc(268px + 40px + 40px) !important;
          @media screen and (max-width: $tablet_vertical) {
            width: 100% !important;
          }
        }
        figure {
          display: block;
          width: 100%;
          img {
            display: block;
            width: 100%;
            height: 276px;
            object-fit: cover;
          }
        }
        h3 {
          font-weight: 700;
          a {
            @extend .card-link;
          }
        }
        div {
          width: 100%;
          .tag {
            display: none;
            margin: 0 0  24px;
            position: relative;
            z-index: 1;
          }
          p {
            margin: 14px 0 0;
            display: none;
          }
        }
      }
      .slick-current {
        li {
          .content {
            width: 60vw !important;
            gap: responsive(20, 50);
            flex-direction: row;
          }
          figure {
            width: 55%;
            height: 60vh;
            overflow: hidden;
            img {
              height: 100%;
            }
          }
          div {
            width: 45%;
            .tag, p {
              display: block;
            }
            h3 {
              font-size: responsive(32, 48);
              font-weight: 700;
              line-height: 1.1;
              font-family: 'freight-display-pro', serif;
            }
            p {
              margin: 20px 0 0;
            }
          } 
          @media screen and (max-width: $tablet) {
            .content {
              width: calc(268px + 40px + 40px) !important;
              flex-direction: column;
            }
            figure {
              width: 100%;
              height: auto;
              overflow: hidden;
              img {
                height: 276px
              }
            }
            div {
              width: 100%;
              .tag, p {
                display: none;
              }
            }
          }
          @media screen and (max-width: $tablet_vertical) {
            .content {
              width: 100% !important;
            }
          }
        }
      }
    }
    .slider-nav {
      width: 60vw;
      display: flex;
      gap: 28px;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      bottom: 40px;
      left: calc(64px + 28px + 16px + 70px);
      &-arrow {
        background-color: #000;
        width: 64px;
        height: 64px;
        img {
          width: 50%;
        }
        transition: opacity .4s ease;
        &:hover {
          opacity: .6;
        }
      }
      @media screen and (max-width: $tablet) {
        width: 100%;
        position: relative;
        left: 0;
        bottom: 0;
        margin: 0 0 24px;
        justify-content: flex-start;
      }
    }
    .carousel-content {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: $tablet) {
        flex-direction: column-reverse;
      }
    }
  }

  &-media {
    padding: 0 responsive(20, 70);
    margin: 0 0 70px;
    &-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 28px;
      li {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        padding: responsive(16, 24);
        border: 1px solid var(--secundary);
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        } 
        figure {
          position: relative;
          height: 300px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .tag {
          position: absolute;
          top: 10px;
          right: 10px;
          color: var(--secundary);
          border: 1px solid var(--secundary);
          background-color: var(--principal);
          z-index: 1;
        }
        div {
          height: 100%;
          margin: 15px 0 0;
          display: flex;
          gap: 20px;
          justify-content: space-between;
          h3 {
            font-size: responsive(24, 34);
            font-weight: 700;
            line-height: 1.1;
            font-family: 'freight-display-pro', serif;
          }
          a {
            align-self: flex-end;
            display: block;
            @extend .card-link;
          }
        }
      }
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(1, 1fr);
        li {
          figure {
            display: none;
          }  
          &:first-of-type {
            figure {
              display: block;
            } 
          }
        }
      }
    }
  }

  &-news {
    padding: 0 responsive(20, 70);
    margin: 0 0 70px;
    .columns {
      display: grid;
      gap: 34px;
      grid-template-columns: repeat(3, 1fr);
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-interviews {
      display: grid;
      gap: 20px;
      article {
        padding: 14px;
        border: 1px solid var(--secundary);
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        } 
        figure {
          width: 100%;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .content {
          .aspas {
            width: responsive(20, 26);
          }
          h3 {
            font-size: responsive(20, 26);
            font-family: 'freight-display-pro', serif;
            line-height: 1.1;
            font-weight: 700;
            margin: 0 0 16px;
            a {
              @extend .card-link;
            }
          }
          p {
            font-size: responsive(14, 16);
          }
        }
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
        article {
          img {
            display: none;  
          }
          &:first-of-type {
            img {
              display: block;
            }
          }
        }
      }
    }
    &-highlight {
      article {
        min-width: 440px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        background-color: #E1EAE9;
        border: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        } 
        img {
          width: 40px;
          margin: 0 0 16px;
        }
        h3 {
          margin: 0 0 46px;
          font-size: responsive(32, 48);
          font-weight: 700;
          line-height: 1.1;
          font-family: 'freight-display-pro', serif;
        }
        a {
          @extend .card-link;
        }
        @media screen and (max-width: $tablet) {
          padding: 40px 40px 40px 30px;
          min-width: 100%;
        }
      }
    }
    &-articles {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border: 1px solid var(--secundary);
      background-color: #E1EAE9;
      h2 {
        padding: 14px 24px;
        background-color: #000;
        color: #F0F5F4;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
      }
      article {
        display: flex;
        gap: 20px;
        align-items: center;
        padding: 12px 24px;
        height: 100%;
        border-top: 1px solid var(--secundary);
        position: relative;
        cursor: pointer;
        background-color: transparent;
        transition: background-color .4s ease;
        &:hover {
          background-color: #fff;
        } 
        h3 {
          font-size: 36px;
          font-weight: 900;
          line-height: 1;
        }
        a {
          @extend .card-link;
        }
        p {
          line-height: 1.4;
        }
      }
    }
  }

  &-moreread {
    padding: 0 responsive(20, 70);
    margin: 0 0 70px;
    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      ol {
        display: grid;
        gap: 28px;
        grid-template-columns: repeat(3, 1fr);
        grid-column: span 3;
        li {
          border: 1px solid var(--secundary);
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          padding: 14px responsive(16, 20);
          position: relative;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .4s ease;
          &:hover {
            background-color: #fff;
          } 
          .infos {
            display: flex;
            align-items: center;
            gap: 12px;
            width: 100%;
            @media screen and (max-width: $tablet) {
              align-items: flex-start;
              flex-direction: column;
            }
            @media screen and (max-width: $mobile) {
              flex-direction: row;
            }
            .avatar {
              display: block;
              border-radius: 100%;
              width: 86px;
              min-width: 86px;
              height: 86px;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            p {
              line-height: 1.4;
              b {
                font-size: responsive(12, 14);
              }
            }
          }
          .links {
            display: flex;
            gap: 24px;
            flex-direction: column;
            align-items: flex-end;
            .tag {
              font-size: 10px;
              padding: 8px 10px;
              z-index: 1;
            }
            .link {
              @extend .card-link;
            }
          }
          
          @media screen and (max-width: $tablet) {
            flex-direction: column;
            .links {
              width: 100%;
              flex-direction: row;
              align-items: flex-start;
              .tag {
                margin: 0;
              }
            }
          }
          @media screen and (max-width: $mobile) {
            flex-direction: row;
            .links {
              width: fit-content;
              flex-direction: column;
              align-items: flex-end;
            }
          }
        }
        @media screen and (max-width: $mobile) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }

  &-webstories {
    padding: 0 responsive(20, 70);
    margin: 0 0 70px;
    &-list {
      position: relative;
      padding: 0 responsive(50, 100) 0 0;
      .list {
        display: flex;
        gap: 28px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar; 
        &::-webkit-scrollbar {
          display: none;
        }
        li {
          position: relative;
          border: 1px solid var(--secundary);
          min-width: 224px;
          min-height: 485px;
          width: 100%;
          cursor: pointer;
          &:hover {
            .infos {
              background-color: #fff;
            }
          } 
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tag {
            position: absolute;
            top: 10px;
            right: 10px;
            color: var(--secundary);
            border: 1px solid var(--secundary);
            background-color: var(--principal);
            margin: 0 0 24px;
          }
          .infos {
            width: 100%;
            padding: responsive(14, 28) 14px;
            bottom: 0;
            position: absolute;
            background-color: var(--principal);
            border-top: 1px solid var(--secundary);
            display: flex;
            flex-direction: column;
            transition: background-color .4s ease;
            cursor: pointer;
            h3 {
              font-weight: 600;
            }
          }
        }
      }
      .btn {
        position: absolute;
        top: 55%;
        right: 0;
        background-color: var(--secundary);
        border: none;
        padding: 20px 26px;
        transition: opacity .4s ease;
        &:hover {
          opacity: .8;
        }
      }
    }
    @media screen and (max-width: $tablet) {
      padding: 0 0 0 responsive(20, 70);
      &-list {
        padding: 0;
        .btn {
          position: unset;
          top: 0;
          margin: 40px 0 0;
        }
      }
    }
  }

  &-projects {
    padding: 0 responsive(20, 70);
    margin: 0 0 70px;
    &-accordion {
      padding: responsive(16, 24) responsive(16, 40);
      border: 1px solid var(--secundary);
      border-top: none;
      background-color: transparent;
      transition: background-color .4s ease;
      &:hover {
        background-color: #fff;
      } 
      &:first-of-type {
        border-top: 1px solid var(--secundary);
      }
      summary {
        font-family: 'freight-display-pro', serif;
        font-size: 20px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: opacity .4s ease;
        cursor: pointer;
      }
      .item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: responsive(30, 60);
        margin: 30px 0 0;
        position: relative;
        &-image {
          position: relative;
          width: 60%;
          img {
            display: block;
            width: 100%;
            height: auto;
          }
          .btn {
            position: absolute;
            right: -90px;
            bottom: 40px;
            background-color: var(--secundary);
            border: none;
            padding: 20px 24px;
            img {
              width: auto;
            }
          }
        }
        &-text {
          margin: responsive(0, 60) 0 0;
          padding-right: 40px;
          width: 40%;
          h3 {
            font-size: responsive(32, 48);
            font-weight: 700;
            line-height: 1.1;
            font-family: 'freight-display-pro', serif;
            margin: 0 0 20px;
            .link {
              @extend .card-link;
            }
          }
        }
        @media screen and (max-width: $tablet) {
          flex-direction: column;
          &-image {
            width: 100%;
            .btn {
              position: unset;
              margin: 40px 0 0;
              img {
                width: auto;
              }
            }
          }
          &-text {
            width: 100%;
          }   
        }
      }
      &[open] {
        summary {
          font-size: 24px;
          .text {
            text-indent: -99999px;
            height: 0;
          }
          .icon {
            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  &-videos {
    padding: 0 responsive(20, 70);
    margin: 0 0 70px;
    &-container {
      position: relative;
      margin: 0 100px 0 0;
      &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 28px;
        article {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          border: 1px solid var(--secundary);
          position: relative;
          cursor: pointer;
          background-color: transparent;
          transition: background-color .4s ease;
          &:hover {
            background-color: #fff;
          } 
          .thumb {
            display: block;
            height: 100%;
          }
          img {
            display: block;
            width: 100%;
            object-fit: cover;
            height: 100%;
            min-height: 200px;
          }
          h3 {
            font-weight: 600;
          }
          a {
            @extend .card-link;
          }
          .content {
            display: flex;
            justify-content: space-between;
            padding: 15px 12px;
          }
          &:first-of-type {
            grid-column: span 2;
            grid-row: span 2;
            img {
              object-fit: cover;
              height: 100%;
            }
            .content {
              padding: responsive(20, 40) responsive(16, 40) responsive(16, 30);
            }
            @media screen and (max-width: $mobile) {
              grid-column: span 3;
              display: flex;
            }
          }
          @media screen and (max-width: $mobile) {
            display: none;
          }
        }
      }
      .btn {
        position: absolute;
        right: -100px;
        top: 60%;
        background-color: var(--secundary);
        border: none;
        padding: 20px 24px;
        transition: opacity .4s ease;
        &:hover {
          opacity: .8;
        }
      }
      @media screen and (max-width: $tablet) {
        margin: 0;
        .btn {
          position: unset;
          margin: 40px 0 0;
        }   
      }
    }
  }

  .newsletter-wrap {
    padding: 0 responsive(20, 70);
  }
}