.footer {
  --principal: #F0F5F4;
  padding: 80px responsive(20, 70) 68px;
  background-color: var(--secundary);
  display: flex;
  gap: 94px;
  justify-content: space-between;

  @media screen and (max-width: $tablet) {
    flex-direction: column;
  }

  &-social {
    p {
      color: var(--principal);
      font-weight: 600;
    }
    &-about {
      max-width: 230px;
      margin: 0 0 20px;
      img {
        display: block;
        max-width: 100%;
        width: auto;
        margin: 0 0 32px;
      }
    }
    &-logo {
      margin: 0 0 48px;
      img {
        display: block;
        max-width: 100%;
        width: auto;
      }
    }
    &-links {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 0 0 18px;
      @media screen and (max-width: $tablet) {
        align-items: flex-start;
        flex-direction: column;
      }
      p {
        font-weight: 700;
        white-space: nowrap;
        margin: 0 10px 0 0;
      }
      ul {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        li {
          a {
            font-size: 11px;
            font-weight: 700;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100%;
            background-color: var(--principal);
            transition: opacity .4s ease;
            &:hover {
              opacity: .6;
            }
          }
        }
      }
    }
    &-support {
      margin: 0 0 40px;
      p {
        font-weight: 700;
      }
      a {
        font-style: italic;
        text-decoration: underline;
        color: var(--principal);
        transition: opacity .4s ease;
        &:hover {
          opacity: .6;
        }
      }
    }
  }

  &-menu {
    color: var(--principal);
    
    &-header, &-links {
      display: grid;
      gap: 28px;
      grid-template-columns: repeat(4, 1fr);
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    &-links {
      position: relative;
      padding-top: 15px;
      h3 {
        margin-bottom: 30px;
        font-weight: 800;
        text-transform: uppercase;
        &:before {
          content: '';
          position: absolute;
          height: 1px;
          width: 100%;
          top: calc(34px + 20px);
          left: 0;
          background-color: var(--principal);
          display: none;
        }
        &:first-of-type {
          &:before {
            display: block;
          }
        }
        @media screen and (max-width: $mobile) {
          position: relative;
          &:before {
            display: block;
            top: auto;
            bottom: -15px;
          }
        }
      }
      a {
        display: block;
        font-weight: 500;
        margin: 0 0 10px;
        transition: opacity .4s ease;
        &:hover {
          opacity: .6;
        }
      }
    }
  }
}