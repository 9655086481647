.page-contact {
  display: flex;
  gap: responsive(60, 96);
  justify-content: space-between;

  &-content {
    max-width: 377px;
    width: 100%;
    .thumb {
      margin: 0 0 36px;
      img {
        width: 100%;
      }
    }
    .title {
      margin: 0 0 40px;
      font-family: 'freight-display-pro', serif;
      font-size: responsive(32, 48);
      font-weight: 900;
      line-height: 1.1;
    }
    .text {
      margin: 0 0 40px;
    }
  }

  &-form {
    width: 100%;
    border: 1px solid var(--secundary);
    padding: 32px 60px 100px;
    position: relative;
    
    form {
      p {
        font-weight: 700;
      }
      label {
        display: block;
      }
      span {
        display: block;
        margin: 10px 0 0;
      }
      input, textarea {
        padding: 12px 20px;
        border: 1px solid var(--secundary);
        background-color: #B6CCC8;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        font-family: 'adelle-sans', sans-serif;
        color: var(--secundary);
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          font-size: 16px;
          font-family: 'adelle-sans', sans-serif;
          color: var(--secundary);
          font-style: italic;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          font-size: 16px;
          font-family: 'adelle-sans', sans-serif;
          color: var(--secundary);
          font-style: italic;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          font-size: 16px;
          font-family: 'adelle-sans', sans-serif;
          color: var(--secundary);
          font-style: italic;
        }
        &:-moz-placeholder { /* Firefox 18- */
          font-size: 16px;
          font-family: 'adelle-sans', sans-serif;
          color: var(--secundary);
          font-style: italic;
        }
      }
      textarea {
        min-height: 168px;
      }
      .btn {
        width: fit-content;
        text-align: center;
        padding: 20px 70px;
        position: absolute;
        right: -50px;
        bottom: 40px;
        color: #F0F5F4;
        border: 1px solid var(--secundary);
        background-color: var(--secundary);
        background-image: url($img + 'arrow-beige.svg');
        background-repeat: no-repeat;
        background-position: center right 16px;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: $tablet_vertical) {
    flex-direction: column;
    &-content {
      max-width: 100%;
    }
    &-form {
      border: none;
      padding: 0;
      form  {
        .btn {
          width: 100%;
          margin: 30px 0 0;
          position: relative;
          right: 0;
          bottom: 0;
          padding: 20px 16px;
          img {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);

          }
        }
      }
    }
  }
}