.page-opendata {
  .title {
    text-align: center;
    font-size: responsive(36, 86);
    font-weight: 900;
    line-height: 1.1;
    font-family: 'freight-display-pro', serif;
    margin: 0 0 34px;
  }
  .subtitle {
    text-align: center;
    font-size: responsive(18, 24);
    margin: 0 0 90px;
    padding: 0 responsive(20, 80);
  }
  section {
    margin: 0 0 responsive(58, 116);
    h2 {
      font-size: responsive(20, 28);
      font-weight: 900;
      line-height: 1.2;
      font-family: 'freight-display-pro', serif;
      margin: 0 0 34px;
    }
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 34px 12px; 
      li {
        display: flex;
        flex-direction: column;
        .resume {
          font-size: responsive(16, 20);
          padding: 24px;
          border: 1px solid var(--secundary);
          margin: 0 0 14px;
          height: 100%;
          position: relative;
          a {
            @extend .card-link;
          }
        }
        .bases {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }
        .tag {
          padding: 8px 20px;
        }
      }
      @media screen and (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: $mobile) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}