// Authors
.page-authors {
  position: relative;
  padding: responsive(32, 90) 0 responsive(60, 90);
  .title {
    text-align: center;
    margin: 0 auto responsive(60, 90);
    font-size: responsive(36, 86);
    font-weight: 900;
    line-height: 1.1;
    font-family: 'freight-display-pro', serif;
  }
  &-carousel {
    position: relative;
    padding: 0 0 0 responsive(20, 180);
    margin: 0 0 responsive(60, 90);
    .slider {
      .slick-track {
        display: flex !important;
      }
      .slick-slide {
        height: inherit !important;
        >div {
          height: 100% !important;
        }
      }
      &-item {
        padding: 0 20px 0 0;
        height: 100% !important;        
        .content {
          height: 100% !important;
          padding: responsive(16, 32) responsive(16, 38);
          background-color: #F0F5F4;
          border: 1px solid var(--secundary);
          position: relative;
          img {
            display: block;
            width: 100%;
            height: auto;
            margin: 0 0 14px;
          }
          h3 {
            margin: 0 0 10px;
            font-size: 20px;
            font-weight: 900;
            line-height: 1.2;
            font-family: 'freight-display-pro', serif;
            a {
              @extend .card-link;
            }
          }
        }
      }
      &-nav {
        display: flex;
        gap: 28px;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        bottom: -42px;
        right: 200px;
        &-arrow {
          background-color: #000;
          width: 64px;
          height: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 50%;
          }
        }
        .slick-disabled {
          background-color: #8A999A;
        }
      }
    }
    @media screen and (max-width: $tablet_vertical) {
      .slider {
        &-nav {
          justify-content: flex-start;
          position: relative;
          right: auto;
          left: 0;
          bottom: auto;
          margin: 32px 0 0;
        }
      }
    }
  }
  &-grid {
    padding: 0 responsive(20, 180);
    margin: 0 0 responsive(60, 90);
    .grid {
      display: grid;
      gap: 24px;
      grid-template-columns: repeat(4, 1fr);
      &-item {
        padding: 10px 14px 20px;
        border: 1px solid var(--secundary);
        position: relative;
        img {
          display: block;
          width: 100%;
          height: auto;
          object-fit: cover;
          margin: 0 0 responsive(22, 52);
        }
        h3 {
          font-weight: 700;
          margin: 0 0 10px;
          a {
            @extend .card-link;
          }
        }
        @media screen and (max-width: $tablet_vertical) {
          grid-column: span 4;
          padding: 16px;
          img {
            display: none;
          }
        }
      }
    }
  }
  &-list {
    padding: 0 responsive(20, 180);
    margin: 0 0 responsive(80, 104);
    .list {
      summary {
        font-size: 32px;
        font-weight: 900;
        line-height: 1.2;
        font-family: 'freight-display-pro', serif;
        padding: 0 0 14px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid var(--secundary);
      }
      &[open] {
        summary {
          .icon {
            &::after {
              opacity: 0;
            }
          }
        }
      }
      &-item {
        padding: 24px 0;
        display: flex;
        gap: 12px;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px dashed var(--secundary);
        position: relative;
        &:last-of-type {
          border-bottom: 1px solid var(--secundary);
        }
        a {
          @extend .card-link;
        }
        .content {
          display: flex;
          gap: 12px;
          align-items: center;
          h3 {
            font-size: 18px;
            font-weight: 700;
          }
          img {
            display: block;
            width: 64px;
            height: 64px;
            object-fit: cover;
            border-radius: 100%;
            margin: 0 12px 0 0;
          }
          .avatar {
            width: 64px;
            height: 64px;
            border-radius: 100%;
            margin: 0 12px 0 0;
            img {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .newsletter-wrap {
    padding: 0 responsive(20, 180);
  }
}

// Author
.page-author {
  .title {
    font-family: 'freight-display-pro', serif;
    font-size: responsive(32, 48);
    font-weight: 900;
    line-height: 1.1;
    margin: 0 0 32px;
  }
  &-profile {
    display: flex;
    gap: responsive(24, 54);
    margin: 0 0 44px;
    padding: 0 0 44px;
    border-bottom: 1px solid var(--secundary);
    .thumb {
      width: 50%;
      border: 1px solid var(--secundary);
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      width: 80%;
      .container {
        display: flex;
        align-items: center;
        margin: 0 0 12px;
        .title {
          margin: 0 22px 0 0;
        }
      }
      .infos {
        font-size: responsive(14, 16);
        margin: 0 0 responsive(20, 32);
        p {
          margin: 0 0 4px;
        }
        .email {
          background-image: url($img + 'mail_black.svg');
          background-repeat: no-repeat;
          background-position: left center;
          padding: 0 0 0 22px;
        }
      }
      .text {
        line-height: 1.8;
      }

    }
    @media screen and (max-width: $tablet) {
      flex-direction: column;
      .thumb {
        width: 100%;
        img {
          aspect-ratio: 4/4;
        }
      }
      .content {
        width: 100%;
      }
    }
  }
  &-posts {
    margin: 0 0 responsive(42, 86);
    .highlight {
      display: flex;
      gap: responsive(28, 56);
      border: 1px solid var(--secundary);
      margin: 0 0 44px;
      padding: responsive(16, 54) responsive(16, 42);
      background-color: #F0F5F4;
      position: relative;
      .thumb {
        position: relative;
        .tag {
          left: 50%;
          transform: translateX(-50%);
          bottom: responsive(14, 28);
          position: absolute;
          border: 1px solid var(--secundary);
          background-color: #B6CCC8;
          color: var(--secundary);
          z-index: 1;
        }
      }
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h3 {
          font-size: responsive(32, 42);
          font-weight: 700;
          margin: 0 0 12px;
          a {
            @extend .card-link;
          }
        }
        >div {
          margin: 0 0 24px;
        }
        .author-container {
          position: relative;
          img {
            width: 40px;
            height: 40px;
          }
        }
      }
      @media screen and (max-width: $tablet) {
        flex-direction: column;
        .thumb {
          width: 100%;
          img {
            width: 100%;
          }
        }
        .content {
          width: 100%;
        }
      }
    }
    .grid {
      display: grid;
      gap: 14px;
      grid-template-columns: repeat(4, 1fr);
      margin: 0 0 responsive(28, 56);
      article {
        display: flex;
        flex-direction: column;
        padding: responsive(16, 20) 16px;
        border: 1px solid var(--secundary);
        position: relative;
        .thumb {
          img {
            width: 100%;
            height: 220px;
            object-fit: cover;
          }
          a {
            @extend .card-link;
          }
        }
        h3 {
          font-weight: 600;
          margin: 20px 0 40px;
        }
        .content {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .date {
          text-transform: none;
          font-size: 12px;
          margin: 0;
          span {
            text-transform: capitalize;
          }
        }
        @media screen and (max-width: $tablet) {
          grid-column: span 2;
        }
        @media screen and (max-width: $mobile) {
          grid-column: span 4;
          .thumb {
            display: none;
          }
          h3 {
            margin: 0 0 20px;
          }
        }
      }
    }
    .seemore {
      font-size: 18px;
      display: flex;
      align-items: center;
      margin: 0 auto;
      padding: 12px 80px;
      background-color: var(--secundary);
      color: var(--principal);
      img {
        margin-left: 12px;
      }
    }
  }
  &-writers {
    margin: 0 0 86px;
    .cards {
      display: grid;
      gap: responsive(20, 30) responsive(20, 42);
      grid-template-columns: repeat(3, 1fr);
      article {
        position: relative;
        padding: responsive(16, 24) responsive(16, 20);
        border: 1px solid var(--secundary);
        background-color: #F0F5F4;
        flex-wrap: nowrap;
        a {
          display: block;
          @extend .card-link;
        }
        figure {
          img {
            min-width: responsive(45, 90);
            min-height: responsive(45, 90);
            object-fit: cover;
          }
        }
        img {
          width: responsive(45, 90);
          height: responsive(45, 90);
          &.arrow {
            border-radius: 0;
            position: absolute;
            right: 16px;
            bottom: 16px;
            width: responsive(20, 28);
            height: responsive(20, 28);
            margin: 0;
          }
        }
        .avatar {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: responsive(45, 90);
          height: responsive(45, 90);
          border-radius: 100%;
          margin: 0 12px 0 0;
          img {
            margin: 0;
          }
        }
        @media screen and (max-width: $tablet) {
          img {
            &:first-of-type {
              display: none;
            }
          }
        }
        @media screen and (max-width: $mobile) {
          grid-column: span 3;
        }
      }
    }
  }
}